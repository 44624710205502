export const equalSplit = (left: number, right: number, parts: number): number[] => {
  const result = [];
  const delta = (right - left) / (parts - 1);
  while (left < right) {
    result.push(left);
    left += delta;
  }
  result.push(right);
  return result;
};
