import { Mixins, Component, Watch } from 'vue-property-decorator';

import { WithDataTable } from './WithDataTable';
import { WithFilters } from './WithFilters';
import { EventStatusLegend } from '@/ui/components/EventStatusLegend';
import { SportFilter } from '@/ui/components/SportFilter';
import { TableContainer } from '@/ui/components/TableContainer';
import { FeedStore } from '@/modules/feed';
import { SportFilterStore } from '@/modules/sportFilter/sportFilter.store';
import { MarketStore } from '@/modules/market/market.store';
import { OutcomeStore } from '@/modules/outcome';
import { ApprovalStore } from '@/modules/approval';
import { BulkActions } from '@/ui/components/BulkActions';
import FullEventName from '@/ui/components/CellRenderers/FullEventName.vue';
import EventTemplates from '@/ui/components/CellRenderers/EventTemplates.vue';
import EventActions from '@/ui/components/CellRenderers/EventActions.vue';
import EventMarkets from '@/ui/components/CellRenderers/EventMarkets.vue';
import EditMarkets from '@/ui/components/CellRenderers/EditMarkets.vue';
import ViewButtonWithOdds from '@/ui/components/CellRenderers/ViewButtonWithOdds.vue';
import EventCode from '@/ui/components/CellRenderers/EventCode.vue';
import ChangeStateSelect from '@/ui/pages/event-managment/components/ChangeStateSelect.vue';
import ChangeTemplatesModal from '@/ui/pages/event-managment/components/ChangeTemplatesModal.vue';
import { CommonStore } from '@/modules/common';
import { EventStore } from '@/modules/event/event.store';
import { SportStore } from '@/modules/sport';
import { eventService, FactoryEvent } from '@/modules/event';

@Component({
  components: {
    EventStatusLegend,
    SportFilter,
    TableContainer,
    FullEventName,
    EventTemplates,
    EventActions,
    EventMarkets,
    EditMarkets,
    BulkActions,
    ChangeStateSelect,
    ChangeTemplatesModal,
    ViewButtonWithOdds,
    EventCode,
  },
})
export class WithEvents extends Mixins(WithDataTable, WithFilters) {
  openEventId = '';
  isFeedOnline = false;

  get modalVisible() {
    return CommonStore.modalVisible;
  }

  get events() {
    return EventStore.eventsArray;
  }

  get selectedEvents() {
    return EventStore.selectedEvents;
  }

  get hasSelectedEvent() {
    return EventStore.hasActiveEvent;
  }

  get canFetchData() {
    return !!SportFilterStore.sports;
  }

  get activeEvent() {
    return EventStore.active;
  }

  get activeEventId() {
    return EventStore.activeId;
  }

  get availableCompetitions() {
    return EventStore.availableCompetitions;
  }

  get bulkActions() {
    const actions = [];
    const canChangeState = EventStore.availableStates.length > 0;
    const canManageTemplates = EventStore.selectedSportId;

    if (canManageTemplates) {
      actions.push({
        label: this.$t('event.manageTemplates'),
        action: () => this.toggleModal('templates'),
      });
    }
    if (canChangeState) {
      actions.push({
        label: '',
        action: () => this.toggleModal('state'),
      });
    }
    return actions;
  }

  mounted() {
    ApprovalStore.getTemplates();
    MarketStore.getTemplates();
    MarketStore.getGoesThrough();
    OutcomeStore.getTemplates();
    SportStore.fetchSports();
    FeedStore.init();
    this.setSource();
    this.fetchData();
  }

  destroyed() {
    EventStore.resetStore();
    FeedStore.disconect();
    this.clearSource();
  }

  setSource() {} // eslint-disable-line

  clearSource() {
    EventStore.setRequestSource('other');
  }

  @Watch('activeEventId')
  onActiveEventChange() {
    EventStore.clearInputParams();
  }

  clearSelectedEvents() {
    EventStore.clearSelectedEvents();
    this.gridApi?.deselectAll();
  }

  get toggleModal() {
    return (modal: any) => CommonStore.toggleModal(modal);
  }

  onRowSelect({ event, selected }: { event: FactoryEvent; selected: boolean }) {
    const eventKey = eventService.formatEventId(event.intKey);
    const eventFromStore = EventStore.events[eventKey];
    EventStore.selectRow({ event: eventFromStore, selected });
  }

  toastEventUpdated() {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('event.updated') as string,
    });
  }

  onEventStateChange(state: string) {
    const selectedNodes = this.gridApi?.getSelectedNodes();
    if (state === 'CREATED') {
      this.gridApi?.applyTransaction({ remove: selectedNodes });
      return;
    }
    EventStore.toggleFilterTriggerer();
    this.clearSelectedEvents();
  }

  onOddsGenerated() {
    // Wait for modal animation to finish
    setTimeout(() => {
      FeedStore.removeOddsFromStore(MarketStore.allOddsKeys);
    }, 2000);
  }
}
