
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import to from 'await-to-js';

import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import { mapForSelect } from '@/lib';
import { ApprovalStore } from '@/modules/approval';
import { OutcomeStore } from '@/modules/outcome';
import { MarketStore } from '@/modules/market/market.store';
import { EventStore, FactoryEvent } from '@/modules/event/';
import { isPlayer, PlayerStore } from '@/modules/player';

@Component({ name: 'EventTemplatesForm' })
export default class EventTemplatesForm extends Vue {
  @Prop({ required: true, type: Object })
  event!: FactoryEvent;

  isSaving = false;
  form!: WrappedFormUtils;

  get dualTemplates() {
    return [
      {
        eventObjectKey: 'prematchOutcomeTemplateId',
        key: 'prematch_outcome_template',
        label: this.$t('outcome.outcome'),
        options: this.outcomesTemplates,
      },
      {
        eventObjectKey: 'liveOutcomeTemplateId',
        key: 'live_outcome_template',
        label: this.$t('outcome.liveTemplate'),
        options: this.outcomesLiveTemplates,
      },
      {
        eventObjectKey: 'prematchMarketTemplateId',
        key: 'prematch_market_template',
        label: this.$t('market.market'),
        options: this.marketPrematchTemplates,
      },
      {
        eventObjectKey: 'liveMarketTemplateId',
        key: 'live_market_template',
        label: this.$t('market.liveTemplate'),
        options: this.marketLiveTemplates,
      },
      {
        eventObjectKey: 'prematchApprovalTemplateId',
        key: 'prematch_approval_template_id',
        label: this.$t('approval.approval'),
        options: this.approvalTemplates,
      },
      {
        eventObjectKey: 'liveApprovalTemplateId',
        key: 'live_approval_template_id',
        label: this.$t('approval.liveTemplate'),
        options: this.approvalLiveTemplates,
      },
    ];
  }

  get playerTemplates() {
    return [
      {
        eventObjectKey: 'approvalTemplateId',
        key: 'player_approval_template_id',
        label: this.$t('approval.approval'),
        options: this.approvalTemplates,
      },
      {
        eventObjectKey: 'marketTemplateId',
        key: 'player_market_template_id',
        label: this.$t('player.player'),
        options: this.playerMarketTemplates,
      },
    ];
  }

  get templates() {
    return isPlayer(this.event) ? this.playerTemplates : this.dualTemplates;
  }

  get sportId() {
    return (this.event as any).sport?.id || this.event.sportId;
  }

  get approvalTemplates() {
    return mapForSelect(ApprovalStore.sportPrematchTemplates(this.sportId));
  }

  get approvalLiveTemplates() {
    return mapForSelect(ApprovalStore.sportLiveTemplates(this.sportId));
  }

  get outcomesTemplates() {
    return mapForSelect(OutcomeStore.sportPrematchTemplates(this.sportId));
  }

  get outcomesLiveTemplates() {
    return mapForSelect(OutcomeStore.sportLiveTemplates(this.sportId));
  }

  get marketLiveTemplates() {
    return mapForSelect(MarketStore.sportTemplates(this.sportId, 'LIVE'));
  }

  get marketPrematchTemplates() {
    return mapForSelect(MarketStore.sportTemplates(this.sportId, 'PREMATCH'));
  }

  get playerMarketTemplates() {
    return mapForSelect(MarketStore.sportTemplates(this.sportId, 'PLAYER'));
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  async onSubmit(event: any) {
    event.preventDefault();
    const [formError] = await to(this.form.validateFields() as any);
    if (formError) return;
    this.isSaving = true;
    const formData = this.form.getFieldsValue() as any;
    const req = isPlayer(this.event)
      ? PlayerStore.bulkChangeTemplates
      : EventStore.bulkChangeTemplates;
    const [err] = await to(req({ formData, event: this.event, templatesType: 'ALL' }));
    this.isSaving = false;
    if (err) return;
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('event.templatesSaved') as string,
    });
  }

  getInitialValue(key: keyof FactoryEvent) {
    return this.event[key];
  }
}
