import { approvalRepo } from './approval.repo';
import { ApprovalTemplate, ApprovalTemplatePrematch } from './approval.types';

class ApprovalService {
  getApprovalTemplates(selectedSports?: number[]) {
    const params: any = {};
    if (selectedSports) {
      params['sport_id__in'] = selectedSports.join(',');
    }
    return approvalRepo.getApprovalTemplates(params);
  }

  saveTemplate(template: ApprovalTemplate) {
    const api = template.id ? approvalRepo.updateTemplate : approvalRepo.createTemplate;

    return api(template);
  }

  deleteTemplate(templateId: number) {
    return approvalRepo.deleteTemplate(templateId);
  }

  buildPrematchTemplatePayload(template: ApprovalTemplatePrematch) {
    const tempObj = {};
    Object.assign(tempObj, template.betting_amount_above_group.rule);
    template.betting_amount_above_group.rule = tempObj;
    if (template.betting_amount_above.rule.player === undefined) {
      template.betting_amount_above.rule.player = null;
    }
    if (template.betting_odd_above.odd === undefined) {
      template.betting_odd_above.odd = null;
    }
    return template;
  }
}

export const approvalService = new ApprovalService();
