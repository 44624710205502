import FeedApi from './feed.api';
import { EventHandlers, FeedOdd, OddDirection, OddsMap } from './feed.types';

// m - market, o - outcome, l - limit (nemamo trenutno nigde settovan), v - prematch_real_odd

class FeedService {
  api = new FeedApi();

  init(messageHandlers: EventHandlers) {
    this.api.connect(messageHandlers);
  }

  disconect() {
    this.api.disconnect();
  }

  mapOdds(array: FeedOdd[]): OddsMap {
    return array.reduce((obj: any, item: FeedOdd) => {
      obj[item.i_k] = item;
      return obj;
    }, {});
  }

  getHighlightState(currentOdd: any, newOdd: any) {
    return newOdd > currentOdd ? OddDirection.UP : OddDirection.DOWN;
  }
}

export const feedService = new FeedService();
