import Vue from 'vue';
import store from '@/store';
import to from 'await-to-js';
import cloneDeep from 'lodash/cloneDeep';
import { Module, VuexModule, Action, getModule, Mutation } from 'vuex-module-decorators';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';

import { playerService } from './player.service';
import {
  EditPlayersPayload,
  NewPlayer,
  Player,
  PlayerTemplateMessage,
  OnPlayerMarketWs,
  LimitOddPayload,
} from './player.types';
import { Odd } from '@/modules/market/market.types';
import { EditPlayerOddsPayload, GeneratePlayerMarketOdds, PlayerEvent, PlayerEventsMap } from '.';
import { BASKETBALL_TOTAL_POINTS_MARKET_ID } from './player.constants';
import { EventRequestSource, eventService, EventState, BaseMarketMap } from '../event';
import { MarketStore, marketService } from '../market';
import { CodeMessageData } from '../feed';
import { objectKeysToCamelCase } from '@/lib/objectKeysToCamelCase';

const initialState = () => ({
  players: [],
  playerCount: 0,
  events: {},
  totalEvents: 0,
  activeId: null,
  lastUpdatedEvent: '',
  selectedEvents: [],
  isGeneratingOdds: false,
  tempGeneratedOdds: {},
  tempInputParams: {},
  requestSource: 'other' as EventRequestSource,
  activeEventMaxBet: 0,
});

const arrayToObject = (array: any[]): object => {
  return array.reduce((obj: any, item: any) => {
    const key = playerService.formatEventId(item.intKey);
    item.baseMarkets = eventService.pluckBaseMarkets({
      baseMarketsMap: item.baseMarketsMap,
      isMarketSuspendedMap: item.marketsMap,
    });
    item.baseMarketsMap = undefined;
    obj[key] = item;
    return obj;
  }, {});
};

@Module({ dynamic: true, store, name: 'player', namespaced: true })
class Players extends VuexModule {
  players: Player[] = initialState().players;
  playerCount: number = initialState().playerCount;
  events: PlayerEventsMap = initialState().events;
  totalEvents: number = initialState().totalEvents;
  activeId: number | null = initialState().activeId;
  lastUpdatedEvent: string = initialState().lastUpdatedEvent;
  selectedEvents: PlayerEvent[] = initialState().selectedEvents;
  isGeneratingOdds: any = initialState().isGeneratingOdds;
  tempGeneratedOdds: any = initialState().tempGeneratedOdds;
  tempInputParams: any = initialState().tempInputParams;
  requestSource: EventRequestSource = initialState().requestSource;
  activeEventMaxBet: number = initialState().activeEventMaxBet;

  isLoading = false;

  get hasActiveEvent() {
    return this.activeId !== null;
  }

  get sortedPlayers() {
    return orderBy(this.players, player => player.name.toLowerCase());
  }

  get active() {
    if (!this.activeId) return null;
    return this.events[`e_${this.activeId}`];
  }

  get eventsArray() {
    return Object.values(this.events);
  }

  get availableStates() {
    const a = playerService.bulkStateTansition<PlayerEvent>(this.selectedEvents);
    if (a) {
      return [...(a.next || []), ...(a.prev || [])].map((a: any) => ({
        label: a.label,
        value: a.value,
      }));
    }
    return [];
  }

  get selectedEventsIds() {
    return this.selectedEvents.map(e => e.intKey);
  }

  get selectedSportId() {
    //@ts-ignore
    return eventService.getSportIdFromSelectedEvents(this.selectedEvents);
  }

  get isEventEditDisabled() {
    return eventService.isEventEditDisabled(this.active?.state, this.requestSource);
  }

  get canBulkSuspend() {
    return () => {
      let canSuspend = false;
      if (this.selectedEvents.length) {
        canSuspend = playerService.canBulkSuspend(this.selectedEvents);
      }
      return canSuspend;
    };
  }

  get nextBulkSuspendState() {
    return () => {
      if (!this.canBulkSuspend()) return '';
      return this.selectedEvents[0].isSuspended ? 'unsuspend' : 'suspend';
    };
  }

  // Player Config Mutations

  @Mutation
  setPlayers(players: Player[]) {
    this.players = players;
    this.playerCount = players.length;
  }

  // Player Events Mutations

  @Mutation
  setEvents(results: PlayerEvent[]) {
    const sortedEvents = playerService.sortEvents(results);
    this.events = { ...arrayToObject(sortedEvents) };
  }

  @Mutation
  setPlayerEventCode(event: { e_c: number; i_k: number }) {
    if (!this.events[eventService.formatEventId(event.i_k)]) return;
    this.events[eventService.formatEventId(event.i_k)].landbaseCode = event.e_c;
    this.lastUpdatedEvent = `${event.i_k}_${Date.now()}`;
  }

  @Mutation
  clearEvents() {
    this.events = {};
  }

  @Mutation
  addToSelectedEvents(event: PlayerEvent) {
    this.selectedEvents.push(cloneDeep(event));
  }

  @Mutation
  removeSelectedEvent(event: PlayerEvent) {
    this.selectedEvents = this.selectedEvents.filter((e: any) => e.intKey !== event.intKey);
  }

  @Mutation
  clearSelectedEvents() {
    this.selectedEvents = [];
  }

  @Mutation
  updateEvent({ intKey, params }: { intKey: number | string; params: Partial<PlayerEvent> }) {
    const event = this.events[`e_${intKey}`];
    if (!event) return;
    Vue.set(this.events, `e_${intKey}`, { ...event, ...params });
    this.lastUpdatedEvent = `${intKey}_${Date.now()}`;
  }

  @Mutation
  updatePrematchMaxAmount(maxAmount: number) {
    this.activeEventMaxBet = maxAmount;
  }

  @Mutation
  private updateBaseMarketSuspend({ eventId, oddId, value }: any) {
    const event = this.events[eventId];
    if (!event) return;
    event.baseMarketsMap.forEach((market: any) => {
      market.odds.forEach((odd: any) => {
        if (odd.id === oddId) {
          odd.is_suspended = value;
        }
      });
    });
  }

  @Mutation
  private updateBaseMarket({ intKey, marketId, params }: any) {
    const event = this.events[eventService.formatEventId(intKey)];
    if (!event) return;
    event.baseMarkets.forEach((market: any) => {
      if (market.id === marketId) {
        Object.keys(params).forEach((key: any) => {
          Vue.set(market, key, params[key]);
        });
      }
    });
  }

  @Mutation
  public updateBaseMarketOdd({ eventId, oddId, value, limit }: any) {
    const event = this.events[playerService.formatEventId(eventId)];
    if (!event) return;
    event.baseMarkets.forEach((market: any) => {
      market.odds.forEach((odd: any) => {
        if (odd.id === oddId) {
          odd.prod_odd = value;
          odd.prematch_prod_odd = value;
          if (limit) {
            odd.limit = limit;
          }
        }
      });
    });
    this.lastUpdatedEvent = `${eventId}_${Date.now()}`;
  }

  @Mutation
  setActiveEvent(id: number) {
    this.activeId = id;
  }

  @Mutation
  setIsLoading(nextValue: boolean) {
    this.isLoading = nextValue;
  }

  @Mutation
  deselectEvent() {
    this.activeId = null;
  }

  @Mutation
  resetStore() {
    const initial = initialState() as any;
    Object.keys(initial).forEach((key: any) => {
      this[key as keyof this] = initial[key];
    });
  }

  @Mutation
  public setGeneratedOdds(generatedOdds: any) {
    this.tempGeneratedOdds = playerService.mapGeneratedOddsForOddComponent(generatedOdds);
  }

  @Mutation
  private setInputParams(inputParams: any) {
    this.tempInputParams = inputParams;
  }

  @Mutation
  public clearInputParams() {
    this.tempInputParams = initialState().tempInputParams;
  }

  @Mutation
  public setRequestSource(source: EventRequestSource) {
    this.requestSource = source;
  }

  @Mutation
  public updatePlayer({ id, payload }: { id: string; payload: Partial<Player> }) {
    this.players.forEach((player, index) => {
      if (player.id === id) {
        const updatedPlayer = {
          ...player,
          ...payload,
        };
        // @ts-ignore
        Vue.set(this.players, index, updatedPlayer);
      }
    });
  }

  @Mutation
  replaceBaseMarketsOnWs(wsData: OnPlayerMarketWs) {
    const intKey = playerService.formatEventId(wsData.e_i_k);
    const newBaseOdds = playerService.mapPlayerNewOdds(wsData.base_odds);
    const allOdds = playerService.mapPlayerNewOdds(wsData.odds);

    let baseMarkets = [];
    let allMarkets = [];

    baseMarkets = newBaseOdds.reduce((validMarkets: any, odd: any) => {
      if (!validMarkets.length) {
        // suspendedMarkets should be added( BE/FE )on every ws where markets is updated
        return [eventService.createBaseMarket({ odd, suspendedMarkets: [] })];
      }
      const createdMarket = validMarkets.find((m: any) => odd.marketId === m.id);
      if (createdMarket) {
        createdMarket.odds.push(eventService.parseBaseOdd(odd));
        return validMarkets;
      } else {
        return [...validMarkets, eventService.createBaseMarket({ odd, suspendedMarkets: [] })];
      }
    }, []);

    allMarkets = allOdds.reduce((validMarkets: any, odd: any) => {
      if (!validMarkets.length) {
        // suspendedMarkets should be added( BE/FE )on every ws where markets is updated
        return [eventService.createBaseMarket({ odd, suspendedMarkets: [] })];
      }
      const createdMarket = validMarkets.find((m: any) => odd.marketId === m.id);
      if (createdMarket) {
        createdMarket.odds.push(eventService.parseBaseOdd(odd));
        return validMarkets;
      } else {
        return [...validMarkets, eventService.createBaseMarket({ odd, suspendedMarkets: [] })];
      }
    }, []);

    const event = this.events[intKey];
    event.baseMarkets = baseMarkets;
    MarketStore.setMarkets(allMarkets);
    const odds = marketService.getOddsMap(allMarkets.odds as any, 'player_prod_odd');
    MarketStore.setOdds(odds);
  }

  @Mutation
  updatePlayerMarkets({ markets, intKey }: { markets: BaseMarketMap[]; intKey: string }) {
    const newMarket = markets[0];
    const event = this.events[intKey];
    if (!event) {
      return;
    }
    event.canPublish = true;
    const newOdd = markets[0].odds[0];
    if (!event.baseMarkets.length) {
      event.baseMarkets = markets;
      return;
    }
    const marketForEdit = event.baseMarkets.find(market => market.id === newMarket.id);
    if (!marketForEdit) {
      event.baseMarkets.push(newMarket);
      return;
    }
    const hasOdd = marketForEdit.odds.find((odd: Odd) => odd.int_key === newOdd.int_key);
    if (!hasOdd) {
      marketForEdit.odds.push(newOdd);
      return;
    } else {
      const newValues = Object.keys(newOdd);
      newValues.forEach(value => {
        // @ts-ignore-next-line
        Vue.set(hasOdd, value, newOdd[value]);
      });
    }
  }

  @Action
  onEventsValidated(message: any) {
    const events = Object.values(message);
    events.forEach((event: any) => {
      this.setPlayerEventCode(event);
    });
  }

  // Player Config Actions

  @Action
  createNewBaseMarket(wsData: OnPlayerMarketWs) {
    const intKey = playerService.formatEventId(wsData.e_i_k);
    const newOdds = playerService.mapPlayerNewOdds(wsData.odds);

    let markets = [];
    markets = newOdds.reduce((validMarkets: any, odd: any) => {
      if (!validMarkets.length) {
        // suspendedMarkets should be added( BE/FE )on every ws where markets is updated
        return [eventService.createBaseMarket({ odd, suspendedMarkets: [] })];
      }
      const createdMarket = validMarkets.find((m: any) => odd.marketId === m.id);
      if (createdMarket) {
        createdMarket.odds.push(eventService.parseBaseOdd(odd));
        return validMarkets;
      } else {
        return [...validMarkets, eventService.createBaseMarket({ odd, suspendedMarkets: [] })];
      }
    }, []);
    this.updatePlayerMarkets({
      markets,
      intKey,
    });
  }

  @Action
  async getCompetitorPlayers(competitorId: number) {
    const [err, res] = await to(playerService.getCompetitorPlayers(competitorId));
    if (err) return Promise.reject(err);
    this.setPlayers(res as Player[]);
    return Promise.resolve();
  }

  @Action
  async savePlayers(payload: NewPlayer[]) {
    const [err] = await to(playerService.savePlayers(payload));
    if (err) return Promise.reject(err);
    return Promise.resolve();
  }

  @Action
  async editPlayers(payload: EditPlayersPayload[]) {
    const [err] = await to(playerService.editPlayers(payload));
    if (err) return Promise.reject(err);
    payload.forEach(singlePlayer => {
      this.updatePlayer({ id: singlePlayer.id, payload: { name: singlePlayer.name } });
    });
    return Promise.resolve();
  }

  // Player Events Actions

  @Action
  async getEventsPrematch() {
    const result = await playerService.getPrematchPlayers();
    this.setEvents(result as any);
  }

  @Action
  async getEvents() {
    const result = await playerService.getEvents();
    this.setEvents(result as any);
  }

  @Action
  selectRow({ event, selected }: { event: PlayerEvent; selected: boolean }) {
    if (selected) {
      this.addToSelectedEvents(event);
    } else {
      this.removeSelectedEvent(event);
    }
  }

  @Action
  async updateTemplate({ intKey, value, key }: { intKey: number; key: any; value: number }) {
    const event = this.events[`e_${intKey}`];
    if (!event) return;
    const [err] = await to(
      playerService.updateEventTemplates(event, key, value, this.requestSource)
    );
    if (!err) {
      this.updateEvent({ intKey, params: { [key]: value } });
    }
  }

  @Action
  async changeBulkState(state: EventState) {
    const events = this.selectedEvents.map((e: any) => e.intKey);
    const [err] = await to(playerService.changeState(events, state));
    if (err) return Promise.reject(err);
    this.selectedEvents.forEach((event: any) => {
      this.updateEvent({ intKey: event.intKey, params: { state } });
    });
    return Promise.resolve();
  }

  @Action
  updateEventsTemplates({ selectedEvents, templates }: any) {
    selectedEvents.forEach((event: PlayerEvent) => {
      this.updateEvent({ intKey: event.intKey, params: templates });
    });
  }

  @Action
  async bulkChangeTemplates(templates: any) {
    const payload = {
      event_players: this.selectedEventsIds.length ? this.selectedEventsIds : [this.activeId],
      template: templates.formData,
      source: this.requestSource,
    };
    const [err] = await to(playerService.bulkChangeTemplates(payload));
    if (err) return Promise.reject();
    this.updateEventsTemplates({ selectedEvents: this.selectedEvents, templates });
    return Promise.resolve();
  }

  @Action
  async updateNote({ intKey, note }: { intKey: number; note: string }) {
    const [err] = await to(playerService.updateNote(intKey, note));
    if (err) return Promise.reject(err);
    return Promise.resolve();
  }

  @Action
  onUpdateOperatorNote(wsData: { e_i_k: number; n: string }) {
    this.updateEvent({
      intKey: wsData.e_i_k,
      params: {
        operatorNotesList: [
          //@ts-ignore-next-line
          ...this.events[`e_${wsData.e_i_k}`].operatorNotesList,
          { note: wsData.n, userId: 0, paramsList: [], createdAt: '' },
        ],
      },
    });
  }

  @Action
  onUpdateNote(wsData: any) {
    this.updateEvent({ intKey: wsData.e_i_k, params: { note: wsData.n } });
  }

  @Action
  async addOperatorNote(note: string) {
    if (!this.active) return;
    const [err] = await to(
      playerService.addOperatorNote(this.active.intKey, note, this.requestSource)
    );
    if (err) return Promise.reject(err);
  }

  @Action
  playerTemplatesChanged(event: PlayerTemplateMessage) {
    const eventToCamelCase = objectKeysToCamelCase(event);
    eventToCamelCase.eventPlayers.forEach((intKey: number) => {
      this.updateEvent({
        intKey,
        params: {
          approvalTemplateId: eventToCamelCase.approvalTemplateId,
          marketTemplateId: eventToCamelCase.marketTemplateId,
        },
      });
    });
  }

  @Action
  async suspendSelected() {
    const event = this.selectedEvents[0] as PlayerEvent;
    const nextValue = !event.isSuspended;
    const eventIds = this.selectedEvents.map(event => event.intKey);
    const [err] = await to(playerService.suspendEvent(eventIds, nextValue));
    if (err) return Promise.reject(err);
    eventIds.forEach(intKey => {
      this.updateEvent({ intKey, params: { isSuspended: nextValue } });
    });
    this.clearSelectedEvents();
    return Promise.resolve();
  }

  @Mutation
  scheduleForValidation() {
    const eventAccessor = `e_${this.activeId}`;
    this.events[eventAccessor].canValidate = true;
    this.events[eventAccessor].canPublish = true;
    const event = this.selectedEvents.find((e: any) => e.intKey === this.activeId);
    if (!event) return;
    //@ts-ignore
    event.canValidate = true;
    event.canPublish = true;
    this.selectedEvents = [...this.selectedEvents]; //hack to invoke rerender
  }

  @Action
  async createOdd(payload: EditPlayerOddsPayload) {
    if (!this.activeId) return Promise.reject();
    const [err, res] = await to(playerService.createOdd(this.activeId, payload));
    if (err) return Promise.reject(err);
    this.scheduleForValidation(); // used only to hack rerender for change-state-select and enable VALIDATE state on creating a new odd
    return Promise.resolve(res);
  }

  @Action
  async updateOdd({ oddId, oddInt, value, limit }: LimitOddPayload) {
    const [err] = await to(playerService.updateOdd(oddId, limit, value, this.requestSource));
    if (err) return Promise.reject(err);
    this.updateBaseMarketOdd({ eventId: this.activeId, oddId, value, limit });
    MarketStore.updateOdd({
      id: oddInt,
      params: { manual_changed: true, prematch_prod_odd: value, limit: limit },
    });
    this.scheduleForValidation(); // used only to hack rerender for change-state-select and enable VALIDATE state on creating a new odd
  }

  @Action
  async generateOddsByInputParams(payload: GeneratePlayerMarketOdds) {
    if (!this.activeId) return Promise.reject();
    const payloadWithMarketId = {
      ...payload,
      market_id: Number(BASKETBALL_TOTAL_POINTS_MARKET_ID),
    };
    this.setInputParams(payload);
    const [err, res] = await to(
      playerService.generateOddsByInputParams(this.activeId, payloadWithMarketId)
    );
    if (err) return Promise.reject(err);
    return Promise.resolve(res);
  }

  @Action
  async confirmGeneratedOdds(payload: any) {
    if (!this.activeId) return Promise.reject();
    const [err, res] = await to(playerService.confirmGeneratedOdds(this.activeId, payload));
    if (err) return Promise.reject(err);
    return Promise.resolve(res);
  }

  @Action
  async suspendEvent(intKey: number) {
    const event = this.events[`e_${intKey}`];
    const nextValue = !event.isSuspended;
    const [err] = await to(playerService.suspendEvent([intKey], nextValue));
    if (err) return Promise.reject(err);
    this.updateEvent({ intKey, params: { isSuspended: nextValue } });
    return Promise.resolve();
  }

  @Action
  async suspendMarket(payload: {
    intKey: number;
    marketId: number;
    nextValue: boolean;
    suspendType: string;
  }) {
    const { intKey, marketId, nextValue, suspendType } = payload;
    const [err] = await to(playerService.suspendMarket(intKey, marketId, nextValue, suspendType));
    if (err) return Promise.reject(err);
    this.updateBaseMarket({ intKey, marketId, params: { is_suspended: nextValue } });
  }

  @Action
  async suspendOdd(payload: {
    intKey: number;
    oddId: string;
    nextValue: boolean;
    suspendType: string;
  }) {
    const { intKey, oddId, nextValue, suspendType } = payload;
    const [err] = await to(playerService.suspendOdd(oddId, nextValue, intKey, suspendType));
    if (err) return Promise.reject(err);
    this.updateBaseMarketSuspend({ intKey, oddId, value: nextValue });
  }

  @Action
  async setOddMaxBet({ oddId, value }: { oddId: number; value: number }) {
    const [err] = await to(playerService.setOddPrematchMaxBet(oddId, value));
    if (err) return Promise.reject(err);
    MarketStore.updateOdd({
      id: oddId,
      params: { prematch_max_amount: value },
    });
    return Promise.resolve();
  }

  @Action
  async setMarketMaxBet(payload: { intKey: number; marketId: number; value: number }) {
    const { intKey, marketId, value } = payload;
    const [err] = await to(playerService.setMarketPrematchMaxBet(intKey, marketId, value));
    if (err) return Promise.reject(err);
    MarketStore.updateMarket({ id: marketId, params: { prematch_max_amount: value } });
    return Promise.resolve();
  }

  @Action
  async setPrematchMaxAmount(payload: { newValue: number; intKey: number }) {
    const { intKey, newValue } = payload;
    const [err] = await to(
      playerService.setPrematchMaxAmount({ eventId: intKey, value: newValue })
    );
    if (err) return Promise.reject(err);
    this.updateEvent({ intKey, params: { prematchMaxAmount: newValue } });
    this.updatePrematchMaxAmount(newValue);
    Promise.resolve();
  }

  @Action
  async getPlayerInputParams() {
    this.setIsLoading(true);
    if (!this.activeId) return;
    const [err, res] = await to<any>(playerService.getPlayerInputParams(this.activeId));
    if (err) {
      this.setIsLoading(false);
      return Promise.reject(err);
    }
    if (res?.prematch_max_amount) {
      this.updatePrematchMaxAmount(res?.prematch_max_amount);
      this.updateEvent({
        intKey: this.activeId,
        params: { prematchMaxAmount: res.prematch_max_amount },
      });
    }
    if (!isEmpty(res.input_parameters)) {
      const paramsToUpdate = objectKeysToCamelCase(res);
      this.updateEvent({
        intKey: this.activeId,
        params: { ...paramsToUpdate },
      });
    }

    this.setIsLoading(false);
    return Promise.resolve();
  }

  // WS feed handlers

  @Action
  onEventCodeChange(data: CodeMessageData) {
    //@ts-ignore
    this.updateEvent({ id: data.e_id, params: { landbase_code: data.c } });
  }
}

export const PlayerStore = getModule(Players);
