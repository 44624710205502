
import { Mixins, Component } from 'vue-property-decorator';

import { WithEvents } from '@/ui/mixins/WithEvents';
import { EventStore } from '@/modules/event/event.store';
import { FilterConfig } from '@/modules/filter/filter.types';
import EventSetup from '@/ui/pages/event-managment/components/EventSetup.vue';
import CompareOddsModal from '@/ui/pages/event-managment/components/CompareOddsModal.vue';
import { EVENT_MNG_STATES, eventService, FactoryEvent } from '@/modules/event/';
import { HAS_BETRADAR_IDS_SELECT_OPTIONS } from '@/modules/filter/filter.constants';
import { WithGrpcEvents } from '@/ui/mixins/WithGrpcEvents';

@Component({
  components: {
    EventSetup,
    CompareOddsModal,
  },
})
export default class LiveNonBookedPage extends Mixins(WithEvents, WithGrpcEvents) {
  filterConfig: FilterConfig = [
    { label: this.$t('event.name'), type: 'input-filter', key: 'name' },
    {
      label: this.$t('event.state'),
      type: 'event-state-filter',
      key: 'state',
      props: { visibleStates: EVENT_MNG_STATES },
    },
    {
      label: this.$t('event.hasBetRadar'),
      type: 'select-filter',
      key: 'has_betradar_id',
      props: { selectOptions: HAS_BETRADAR_IDS_SELECT_OPTIONS },
    },
    {
      label: this.$t('event.hasPlayers'),
      type: 'checkbox-filter',
      key: 'has_players',
    },
    {
      label: this.$t('common.start'),
      type: 'range-filter',
      key: ['startDate', 'endDate'],
      props: { showTime: true },
    },
  ];

  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: this.$t('event.name'),
          field: 'name',
          width: 300,
          cellRendererFramework: 'FullEventName',
          cellRendererParams: {
            cellType: 'EVENT-MANAGEMENT',
            hasPlayers: 'hasPlayers',
          },
        },
        {
          headerName: this.$t('event.code'),
          width: 100,
          cellRendererFramework: 'EventCode',
        },
        {
          headerName: this.$t('common.template'),
          width: 236,
          cellRendererFramework: 'EventTemplates',
        },
        {
          width: 180,
          cellRendererFramework: 'EventActions',
        },
        {
          cellRendererFramework: 'EventMarkets',
          flex: 1,
        },
      ],
      rowData: this.tableData,
      cacheBlockSize: 50,
      rowBuffer: 50,
    };
  }

  get gridOptions() {
    return {
      rowHeight: 92,
      getRowClass: ({ data }: { data: FactoryEvent }) => {
        const event = EventStore.events[eventService.formatEventId(data.intKey)];
        if (!event) return;

        return eventService.getEventClass(event);
      },
    };
  }

  async fetchData() {
    await EventStore.getLiveNonBooked();
  }

  setSource() {
    EventStore.setRequestSource('EVENT-MNG');
  }

  clearSelectedEvents() {
    EventStore.clearSelectedEvents();
    this.gridApi?.deselectAll();
  }
}
