import { api } from '@/lib/api';

import { ApprovalTemplate } from '.';

const TEMPLATE_ROUTE = 'slip/approval/template/';

const getApprovalTemplates = (params?: any) => {
  return api.get(TEMPLATE_ROUTE, { params }) as Promise<ApprovalTemplate[]>;
};

const createTemplate = (approval: ApprovalTemplate) => api.post(TEMPLATE_ROUTE, approval);

const updateTemplate = (template: ApprovalTemplate) => {
  const url = `${TEMPLATE_ROUTE}${template.id}/`;
  return api.put(url, template);
};

const deleteTemplate = (templateId: number) => {
  const url = `${TEMPLATE_ROUTE}${templateId}/`;
  return api.delete(url);
};

export const approvalRepo = {
  getApprovalTemplates,
  createTemplate,
  updateTemplate,
  deleteTemplate,
};
