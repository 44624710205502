// import { replaceBadJsonString } from '@/lib/parseLiveResults';

export class EventPublishedSettlement {
  away: string;
  betStop: boolean;
  // betradarId: string;
  competitionId: number;
  competitionName: string;
  currentPhase?: {
    fieldsMap: any;
  };
  currentResult?: {
    fieldsMap: any;
  };

  settlementHistory: any;
  checkbox1530: boolean;
  home: string;
  intKey: number;
  // isSuspended: boolean;
  // landbaseCode: number;
  locationId: number;
  liveStatus: string; // if empty string event is not booked for live PREMATCH ONLY
  note: string; // event mng note
  settlementNote: string;
  periodResult?: {
    fieldsMap: any;
  };
  sportId: number;
  start: string;
  stage: 'live' | 'prematch' | 'FINISHED';
  settled15: boolean;
  settled30: boolean;
  settledM1: boolean;
  isVerified: boolean;
  state: string;
  locationName: string;
  ctrlProvider: string;
  // ctrlProviders: string[];
  liveProvider: string;
  // liveProviders: string[];

  currentPeriod: string;
  assignee: number; // when 0 its unassigned -- id of the assignee
  currentPhaseJson: any;
  currentResultJson: any;
  periodResultJson: any;
  isSettleDisabled: boolean;
  // check all of this commented fields with Kris
  // isManualSettlement: boolean;
  // eventIntKey: number;
  id: string;
  isLiveBooking: boolean;
  // prematchRemoved: boolean;
  // isSettleEnabled: boolean;
  // sentToSettlement: boolean;
  // checkboxM1: boolean;
  lastPlayingPeriod?: string;
  eventName?: string;
  liveOffer: boolean;
  playerName?: string;
  name: string;
  landbaseCode: number;
  constructor(event: any) {
    this.away = event.a;
    this.home = event.h;
    this.currentPeriod = event.c_p;
    this.betStop = event.bs;
    this.eventName = event.n;
    this.name = event.n;
    this.competitionId = event.c_i;
    this.competitionName = event.c_n;
    this.locationId = event.l_i;
    this.sportId = event.s_i; // mising
    this.start = event.sta;
    this.stage = event.stg;
    this.intKey = event.i_k;
    this.id = event.i_k;
    // this should be dynamic
    this.state = 'PUBLISHED';
    this.liveStatus = event.l_s;
    this.locationName = event.l_n;
    this.settled15 = event.s_15;
    this.settled30 = event.s_30;
    this.checkbox1530 = event.c_1530;
    this.settledM1 = event.s_m1;
    // note needs to be done
    this.settlementNote = '';
    this.note = '';
    this.assignee = event.u || 0;
    this.isSettleDisabled = true;
    this.isVerified = false;
    this.isLiveBooking = !!event.l_s?.length || false;
    this.liveOffer = event.lo;
    this.currentPhaseJson = event.c_p; //replaceBadJsonString(event.c_p);
    this.currentResultJson = event.c_r; //replaceBadJsonString(event.c_r);
    this.liveProvider = event.lp;
    this.ctrlProvider = '';
    this.landbaseCode = event.e_c;
  }
}
