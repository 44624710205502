
import Vue from 'vue';

import { Prop, Component } from 'vue-property-decorator';

@Component
export default class PageName extends Vue {
  @Prop({ type: String, required: true })
  label!: string;
}
