
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { Editable } from '@/ui/components/Editable';
import { FeedStore, OddsMap } from '@/modules/feed';
import { OddValue } from '@/ui/components/OddValue';
import { MarketStore, Odd } from '@/modules/market';

@Component({ components: { Editable, OddValue } })
export default class TableOdd extends Vue {
  @Prop({ required: true, type: Array })
  odds!: any[];

  @Prop({ required: false, type: Boolean })
  hideOtherOdds!: boolean;

  @Prop({ required: false, type: Boolean })
  isMarketSuspended!: boolean;

  @Prop({ required: false, default: 'PREMATCH' })
  eventType!: 'PLAYER' | 'LIVE' | 'PREMATCH';

  get prodOddKey(): keyof typeof FeedStore {
    switch (this.eventType) {
      case 'PLAYER':
        return 'playerOdds';
      case 'PREMATCH':
        return 'odds';
      case 'LIVE':
        return 'liveOdds';
      default:
        return 'odds';
    }
  }

  get oddWarning() {
    return (odd: any) => FeedStore.liveWarningOdds[odd.int_key];
  }

  get prodOddValue() {
    return (odd: any) => {
      return this.eventType === 'LIVE' ? odd.live_prod_odd : odd.prematch_prod_odd || odd.prod_odd;
    };
  }

  get realOddValue() {
    return (odd: any) => {
      return this.eventType === 'LIVE' ? odd.live_real_odd : odd.prematch_real_odd;
    };
  }

  get oddsFromFeed() {
    return FeedStore[this.prodOddKey] as OddsMap;
  }

  get disabledByProvider() {
    return (currentOdd: any) => {
      const odd = this.oddsFromFeed[currentOdd.int_key];
      if (odd) {
        return odd.isdbp;
      }
      return false;
    };
  }

  get isManualChanged() {
    return (o: Odd) => {
      const odd = MarketStore.odds?.[o.int_key] || o;
      const oddsFromFeed = FeedStore[this.prodOddKey] as OddsMap;
      if (oddsFromFeed[o.int_key] && oddsFromFeed[o.int_key].m_c) return true;
      return odd.manual_changed;
    };
  }

  get oddLimit() {
    return (o: Odd) => {
      const oddFromFeed = FeedStore.odds[o.int_key];
      if (oddFromFeed) {
        return oddFromFeed.l;
      }
      return o.limit;
    };
  }

  onClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
