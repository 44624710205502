import Vue from 'vue';
import { Watch, Component } from 'vue-property-decorator';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

import { BetRadarEvent } from '@/modules/betRadarEvent/betRadarEvent.types';
import { FilterStore } from '@/modules/filter/filter.store';
import { SportFilterStore } from '@/modules/sportFilter/sportFilter.store';
import { EventStore, FactoryEvent, FINAL_PERIODS } from '@/modules/event';
import { AuthStore } from '@/modules/auth';
import { SettlementEvent } from '@/modules/settlement/settlement.types';
import { SettlementStore } from '@/modules/settlement/settlement.store';
import { DATE } from '@/constants';

@Component
export class WithGrpcEvents extends Vue {
  events!: any[];
  tableEvents = [];
  initLoad = true;

  get selectedCompetitions() {
    return SportFilterStore.selectedCompetitions;
  }

  get activeFilters() {
    return FilterStore.activeFilters;
  }

  get isInfinite() {
    return false;
  }

  get tableData() {
    return this.tableEvents;
  }

  get lastUpdatedEvent() {
    return EventStore.lastUpdatedEvent;
  }

  get requestSource() {
    return EventStore.requestSource;
  }

  get lastUpdatedSettlementEvent() {
    return SettlementStore.lastUpdatedSettlementEvent;
  }

  get eventPublishedInSettlement() {
    return SettlementStore.eventPublished;
  }

  get filterTriggerer() {
    return EventStore.filterTriggerer;
  }

  get userId() {
    return AuthStore.userId;
  }

  get liveEventAdded() {
    return EventStore.liveEventAdded;
  }

  get eventEnded() {
    return EventStore.eventEnded;
  }

  get eventToRemoveFromPrematch() {
    return EventStore.eventToRemoveFromPrematch;
  }

  @Watch('filterTriggerer')
  onStatusChange(shouldTrigger: boolean) {
    if (shouldTrigger) {
      EventStore.toggleFilterTriggerer();
      this.filterEvents();
    }
  }

  @Watch('events')
  onEvents() {
    if (this.initLoad && this.events.length) {
      this.filterEvents();
      this.initLoad = false;
    }
  }

  competitionName(obj: any, key: string, val: string) {
    if (!val) return true;
    return obj[key].toLowerCase().includes(val.toLowerCase());
  }

  filterEvents() {
    // @ts-ignore
    this.gridApi?.showLoadingOverlay();
    this.$worker
      .run(
        (
          events: any,
          selectedCompetitions: any,
          activeFilters: any,
          userId: any,
          requestSource: string
        ) => {
          function isInStateCreated(state: string) {
            if (requestSource === 'EVENT-MNG' && state === 'CREATED') return false;
            return true;
          }

          function isPrematchRemoved(prematchRemoved: boolean | undefined) {
            if (requestSource === 'PREMATCH' && prematchRemoved) return false;
            if (requestSource === 'EVENT-MNG' && prematchRemoved) return false;
            return true;
          }

          function isSentToSettlement(sentToSettlement: boolean) {
            if (requestSource === 'LIVE' && sentToSettlement) return false;
            return true;
          }

          // for settlement page
          function getAssignee(
            filterStatus: 'assigned' | 'assignedToMe' | 'unAssigned' | undefined,
            e: SettlementEvent
          ) {
            switch (filterStatus) {
              case 'assigned':
                return !!e.assignee;
              case 'assignedToMe':
                return e.assignee === userId;
              case 'unAssigned':
                return !e.assignee;
              default:
                return true;
            }
          }

          function like(obj: any, key: string, val: string) {
            if (!val) return true;
            if (val === 'NOT_CONFIRMED') return !obj['competitionConfirmed'];
            if (val === 'ADD_TO_OFFER')
              return obj['state'] === 'CREATED' && obj['competitionConfirmed'] === true;
            return obj[key].toLowerCase().includes(val.toLowerCase());
          }

          function isLiveStageMatch(obj: any, key: string, val: string) {
            if (!val) return true;

            if (val.toLowerCase() === 'live,break') {
              return (
                val.toLowerCase().includes(obj[key].toLowerCase()) && obj.state === 'PUBLISHED'
              );
            }

            if (val === 'HIDDEN') {
              return obj.state === 'HIDDEN';
            }

            if (val === 'D') {
              return obj.liveStatus === 'D';
            }
            if (val === 'ENDED') {
              return FINAL_PERIODS.includes(obj[key]);
            }

            return obj[key].toLowerCase().includes(val.toLowerCase());
          }

          function isBetween(date: any, start: any, end: any): any {
            // let endOfTheDay: any;
            if (!start) {
              return true;
            }
            if (!end) {
              // return this logic if filter on frontend is required for now is disabled
              // const dateStart = new Date(start);
              // endOfTheDay = new Date(
              //   // @ts-ignore
              //   new Date(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate() + 1) - 1
              // );
              return true;
            }
            const dateCheck = new Date(date).getTime();
            const dateFrom = new Date(start).getTime();
            const dateTo = new Date(end).getTime(); // || endOfTheDay.getTime();

            if (dateCheck > dateFrom && dateCheck < dateTo) {
              return true;
            }
            return false;
          }

          function isSettled(obj: any, val?: boolean) {
            if (typeof val === 'undefined') {
              return true;
            }
            if (!val) {
              return true;
            }
            const isCompleted = obj.state === 'COMPLETED';

            return obj.hasUnsettled && isCompleted;
          }

          function showBetradarEvents(event: any, hasBetradarId: 'true' | 'false') {
            if (hasBetradarId === 'true') return event.prematchProvider === 'betradar';
            return event.prematchProvider !== 'betradar';
          }

          function filterByProvider(liveProvider: any, providerToFilter: string) {
            if (!providerToFilter) return true;
            return liveProvider === providerToFilter;
          }

          const cmp = (x: any, y: any) => {
            return x > y ? 1 : x < y ? -1 : 0;
          };

          if (!events) return [];

          const filteredEvents: any[] = events.filter(
            (e: FactoryEvent | BetRadarEvent | SettlementEvent) => {
              const filterByCompetition = selectedCompetitions.length;
              const canFilterBySelectedCompetitions = filterByCompetition && e.competitionId;
              const competitionMatch = canFilterBySelectedCompetitions
                ? selectedCompetitions.includes(e.competitionId)
                : true;

              const nameMatch = like(e, 'name', activeFilters.name);
              const competitionNameMatch = like(
                e,
                'competitionName',
                activeFilters.competitionName
              );
              const statusMatch = like(e, 'state', activeFilters.state);
              // @ts-ignore
              const playersMatch = !activeFilters.has_players ? true : e.hasPlayers;
              const liveStageMatch = isLiveStageMatch(e, 'liveStatus', activeFilters.live_status);

              // if event is in state CREATED, it needs to be visible only in create offer page
              const inStateCreated = isInStateCreated(e.state);
              const prematchRemoved = isPrematchRemoved(e.prematchRemoved);
              const sentToSettlement = isSentToSettlement(e.sentToSettlement);
              const liveProvider = filterByProvider(e.liveProvider, activeFilters.live_provider);

              // @ts-ignore
              const assignee = getAssignee(activeFilters.is_assigned, e);

              // EM and Prematch
              const hasBetradarId = activeFilters.has_betradar_id
                ? showBetradarEvents(e, activeFilters.has_betradar_id)
                : true;

              const startEndDateMatch = isBetween(
                e.start,
                activeFilters.startDate,
                activeFilters.endDate
              );

              const isSettledMatch = isSettled(e, activeFilters.hasUnsettled);

              return (
                inStateCreated &&
                isSettledMatch &&
                competitionMatch &&
                nameMatch &&
                competitionNameMatch &&
                startEndDateMatch &&
                statusMatch &&
                playersMatch &&
                liveStageMatch &&
                hasBetradarId &&
                prematchRemoved &&
                sentToSettlement &&
                liveProvider &&
                assignee
              );
            }
          );

          filteredEvents.sort((a: any, b: any) => a.competitionId - b.competitionId);
          filteredEvents.sort((a, b) => {
            return cmp(
              [cmp(a.start, b.start), cmp(a.home, b.home)],
              [cmp(b.start, a.start), cmp(b.home, a.home)]
            );
          });
          filteredEvents.sort((a: any, b: any) => a.sportId - b.sportId);

          return filteredEvents;
        },
        [
          this.events,
          this.selectedCompetitions,
          this.activeFilters,
          this.userId,
          this.requestSource,
        ]
      )
      .then((result: any) => {
        if (result) {
          this.tableEvents = result;
        }

        // @ts-ignore
        this.gridApi?.hideOverlay();
      });
  }

  @Watch('activeFilters')
  @Watch('selectedCompetitions')
  onFiltersChange(newValue: any, oldValue: any) {
    if (isEqual(newValue, oldValue) && !!newValue) return;
    this.filterEvents();
  }

  @Watch('liveEventAdded')
  handleLiveEvent(data: string) {
    const [intKey] = data.split('_');
    const foundEvent = EventStore.events[`e_${intKey}`];
    const sportSelected =
      this.selectedCompetitions.includes(foundEvent.competitionId) ||
      !this.selectedCompetitions.length;
    // @ts-ignore
    const sameState = FilterStore.activeFilters.live_status.includes(foundEvent.liveStatus);
    const competitionName = this.competitionName(
      foundEvent,
      'name',
      FilterStore.activeFilters.name
    );
    if (
      FilterStore.activeFilters.live_status == 'NOT_STARTED' &&
      // @ts-ignore
      foundEvent.liveStatus.includes('LIVE')
    ) {
      // @ts-ignore
      this.gridApi?.applyTransaction({ remove: [{ intKey: intKey }] });
    }

    if (sportSelected && sameState && competitionName) {
      // @ts-ignore
      this.gridApi?.applyTransaction({ add: [foundEvent] });
    }
  }

  @Watch('eventEnded')
  handleEventEnded(data: string) {
    const [intKey] = data.split('_');
    // @ts-ignore
    this.gridApi?.forEachNode(node => {
      if (node.data.intKey == intKey && FilterStore.activeFilters.live_status == 'LIVE,BREAK') {
        // @ts-ignore
        this.gridApi?.applyTransaction({ remove: [{ intKey: intKey }] });
      }
    });
  }

  @Watch('eventPublishedInSettlement')
  handlePublishedEvent(data: any) {
    const [intKey] = data.split('_');
    const foundEvent = SettlementStore.settlementEvents[`e_${intKey}`];
    const sportSelected =
      this.selectedCompetitions.includes(foundEvent.competitionId) ||
      !this.selectedCompetitions.length;
    let isAssigned = true;
    let hasUnsettled = true;
    const selectedDayFilter = moment(FilterStore.activeFilters.startDate).format(DATE);
    const eventDay = moment(foundEvent.start).format(DATE);
    const isSameDay = moment(selectedDayFilter).isSame(eventDay, 'day');
    const competitionName = this.competitionName(
      foundEvent,
      'name',
      FilterStore.activeFilters.name
    );
    if ('is_assigned' in FilterStore.activeFilters) isAssigned = false;
    if ('hasUnsettled' in FilterStore.activeFilters) hasUnsettled = false;

    if (sportSelected && competitionName && isSameDay && isAssigned && hasUnsettled) {
      // @ts-ignore
      this.gridApi?.applyTransaction({ add: [foundEvent] });
    }
  }

  @Watch('lastUpdatedSettlementEvent')
  redrawSettlementRows(data: string) {
    const [intKey] = data.split('_');
    const foundEvent = SettlementStore.settlementEvents[`e_${intKey}`];
    if (foundEvent) {
      // @ts-ignore
      this.gridApi?.forEachNode(node => {
        if (node.data.intKey == intKey) {
          node.setData(foundEvent);
          // @ts-ignore
          this.gridApi?.redrawRows({ rowNodes: [node] });
        }
      });
    }
  }

  @Watch('lastUpdatedEvent')
  redrawRows(data: string) {
    const [intKey] = data.split('_');
    const foundEvent = EventStore.events[`e_${intKey}`];
    if (foundEvent) {
      // @ts-ignore
      this.gridApi?.forEachNode(node => {
        if (node.data.intKey == intKey) {
          node.setData(foundEvent);
          // @ts-ignore
          this.gridApi?.redrawRows({ rowNodes: [node] });
        }
      });
    }
  }

  @Watch('eventToRemoveFromPrematch')
  handleEventPrematchRemoved(data: string) {
    // @ts-ignore
    if (!this.gridOptions) return;
    // @ts-ignore
    if (this.gridOptions.requestSource && this.gridOptions.requestSource === 'SETTLEMENT') return;
    const [, intKey] = data.split('_');
    // @ts-ignore
    this.gridApi?.forEachNode(node => {
      if (node.data.intKey == intKey) {
        // @ts-ignore
        this.gridApi?.applyTransaction({ remove: [{ intKey: intKey }] });
      }
    });
  }
}
