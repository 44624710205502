
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class MaxBetAction extends Vue {
  value!: number | null;
  isTouched = false;
  isSaving = false;

  @Prop()
  defaultValue!: number | null;

  @Prop()
  mode!: 'day' | 'night';

  @Prop({ required: true })
  change!: (value: number | null) => void;

  onChange(value: number) {
    this.value = value;
    this.setIsTouched();
  }

  setIsTouched() {
    if (this.defaultValue != this.value) {
      this.isTouched = true;
    } else {
      this.isTouched = false;
    }
  }

  async save() {
    if (this.defaultValue != this.value) {
      this.isSaving = true;
      await this.change(this.value);
      this.isSaving = false;
      this.isTouched = false;
    }
  }

  async reset() {
    this.isSaving = true;
    await this.change(null);
    this.isSaving = false;
    this.isTouched = false;
  }
}
