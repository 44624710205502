export enum readyState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}

export const wsHeartBeat = function(ws: any, connect: any) {
  const pingMessage = '__PING__';
  const pingInterval = 1000;
  const pingTimeout = 2000;
  let messageAccepted = true;

  const isClosed = () => {
    return ws.readyState === readyState.CLOSING || ws.readyState === readyState.CLOSED;
  };

  const close = () => {
    console.debug('WS: Checking closing socket condition on heartbeat ...');
    const canClose = ws.readyState === readyState.OPEN;
    if (!canClose) return;
    console.debug('WS: Closing socket on heartbeat ...');
    ws.close();
  };

  const pingTimer = setInterval(() => {
    if (isClosed()) {
      clearInterval(pingTimer);
      return;
    }
    try {
      ws.send(pingMessage);
    } catch (_) {
      // do nothing
    }
  }, pingInterval);

  const timeoutTimer = setInterval(() => {
    if (ws.readyState !== readyState.OPEN) return;
    if (!messageAccepted) {
      close();
      reconect(); //eslint-disable-line
    } else {
      messageAccepted = false;
    }
  }, pingTimeout);

  const clearTimeouts = () => {
    clearTimeout(pingTimer);
    clearInterval(timeoutTimer);
  };

  const reconect = () => {
    clearTimeouts();
    console.debug('WS: Reconnecting...');
    setTimeout(connect, 2000);
  };

  ws.addEventListener('open', () => {
    messageAccepted = true;
  });

  ws.addEventListener('close', () => {
    clearTimeouts();
  });

  ws.addEventListener('message', () => {
    messageAccepted = true;
  });
};
