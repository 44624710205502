
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { SportFilterStore } from '@/modules/sportFilter/sportFilter.store';
import { FilterStore } from '@/modules/filter/filter.store';
import ChangeStateSelect from '@/ui/pages/event-managment/components/ChangeStateSelect.vue';
import { EventStore, EventState } from '@/modules/event';
import to from 'await-to-js';
import { PlayerStore } from '@/modules/player';
import { AntepostStore } from '@/modules/antepost';
import isEqual from 'lodash/isEqual';

interface Action {
  label: string;
  icon: string;
  action: () => void;
}

@Component({ components: { ChangeStateSelect } })
export default class BulkActions extends Vue {
  @Prop({ required: true, type: String })
  label!: string;

  @Prop({ required: true, type: Function })
  onStateChange!: () => void;

  @Prop({ required: true, type: Number })
  count!: number;

  @Prop({ required: true, type: Array as () => Action[] })
  actions!: Action[];

  @Prop({ required: true, type: Function })
  clearSelected!: () => void;

  @Prop({ required: false, type: Array })
  excludeStates!: EventState[];

  @Prop({ default: 'DUAL', type: String })
  eventType!: 'DUAL' | 'PLAYER' | 'ANTEPOST';

  @Prop({ default: 'other' })
  requestSource!: string;

  get storeSource() {
    switch (this.eventType) {
      case 'DUAL':
        return EventStore;
      case 'PLAYER':
        return PlayerStore;
      case 'ANTEPOST':
        return AntepostStore;
      default:
        return EventStore;
    }
  }

  get canChangeState() {
    return this.storeSource.availableStates.length > 0;
  }

  get hasSideContent() {
    return this.$slots['side-content'];
  }

  get canBulkSuspend() {
    return this.storeSource.canBulkSuspend();
  }

  get isEventManagementAntepost() {
    return this.$route.path.includes('event-managment') && this.eventType === 'ANTEPOST';
  }

  get nextBulkSuspendState() {
    if (this.requestSource === 'EVENT-MNG') return false;
    const isSuspendAvailable = this.storeSource.nextBulkSuspendState();
    if (!isSuspendAvailable) return false;
    return this.$t(`event.${this.storeSource.nextBulkSuspendState()}`);
  }

  get selectedSports() {
    return SportFilterStore.selectedSports;
  }

  get activeFilters() {
    return FilterStore.activeFilters;
  }

  async bulkSuspend() {
    const [err] = await to(this.storeSource.suspendSelected());
    if (err) return;
    this.onStateChange();
  }

  @Watch('selectedSports')
  @Watch('activeFilters')
  onFiltersChange(newValue: any, oldValue: any) {
    if (isEqual(newValue, oldValue) && !!newValue) return;
    this.clearSelected();
  }
}
