import { EventHandlers } from './feed.types';
import { wsHeartBeat } from '@/lib/wsHeartBeat';

class BroadcastApi {
  private socket!: WebSocket;
  private handlers!: EventHandlers;

  connect(handlers?: EventHandlers) {
    const url = process.env.VUE_APP_WS_BROADCAST_FEED;
    this.socket = new WebSocket(url);
    this.heartBeat();
    this.setHandlers(handlers);

    this.socket.addEventListener('open', function() {
      const event = new Event('feed2_open');
      document.dispatchEvent(event);
      console.debug('Connection with feed2 established');
    });

    this.socket.addEventListener('close', function() {
      const event = new Event('feed2_close');
      document.dispatchEvent(event);
      console.debug('Connection with feed2 closed');
    });
  }

  setHandlers(handlers?: EventHandlers) {
    if (!handlers && !this.handlers) return;
    if (handlers) {
      this.handlers = handlers;
    }

    this.socket.addEventListener('message', ({ data }: any) => {
      try {
        const { type, message } = JSON.parse(data) as { type: keyof EventHandlers; message: any };
        this.handlers[type] && this.handlers[type](message);
      } catch (_) {
        // do nothing
      }
    });
  }

  heartBeat() {
    wsHeartBeat(this.socket, () => this.connect());
  }

  disconnect() {
    this.socket.close();
  }
}

export default BroadcastApi;
