
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

import { ApprovalStore } from '@/modules/approval';
import { OutcomeStore } from '@/modules/outcome';
import { MarketStore } from '@/modules/market/market.store';
import { EventStore } from '@/modules/event/event.store';
import { mapForSelect } from '@/lib';
import { FactoryEvent, isLive } from '@/modules/event';
import { isPlayer, PlayerStore } from '@/modules/player';
import to from 'await-to-js';

@Component
export default class EventTemplates extends Vue {
  @Prop({ required: true, type: Object })
  event!: FactoryEvent;

  @Prop({ default: 'PREMATCH', type: String })
  eventType!: 'PREMATCH' | 'LIVE' | 'PLAYER';

  get isLive() {
    return isLive(this.event);
  }

  get dualTemplates() {
    return [
      {
        eventKey: this.isLive ? 'liveOutcomeTemplateId' : 'prematchOutcomeTemplateId',
        key: this.isLive ? 'live_outcome_template_id' : 'prematch_outcome_template_id',
        label: this.$t('outcome.template'),
        options: this.outcomesTemplates,
      },
      {
        eventKey: this.isLive ? 'liveMarketTemplateId' : 'prematchMarketTemplateId',
        key: this.isLive ? 'live_market_template_id' : 'prematch_market_template_id',
        label: this.$t('market.template'),
        options: this.marketTemplates,
      },
      {
        eventKey: this.isLive ? 'liveApprovalTemplateId' : 'prematchApprovalTemplateId',
        key: this.isLive ? 'live_approval_template_id' : 'prematch_approval_templat_id',
        label: this.$t('approval.template'),
        options: this.approvalTemplates,
      },
    ];
  }

  get playerTemplates() {
    return [
      {
        eventKey: 'approvalTemplateId',
        key: 'player_approval_template_id',
        label: this.$t('approval.template'),
        options: this.approvalTemplates,
      },
      {
        eventKey: 'marketTemplateId',
        key: 'player_market_template_id',
        label: this.$t('market.template'),
        options: this.marketTemplates,
      },
    ];
  }

  get store() {
    switch (this.eventType) {
      case 'PLAYER':
        return PlayerStore;
      default:
        return EventStore;
    }
  }

  get templates() {
    switch (this.eventType) {
      case 'PLAYER':
        return this.playerTemplates;
      default:
        return this.dualTemplates;
    }
  }

  get selectConfig() {
    return {
      size: 'small',
    };
  }

  get sportId() {
    if (this.event) {
      return this.event.sportId || (this.event as any).sport.id;
    }
    return 0;
  }

  get approvalTemplates() {
    if (this.isLive) {
      return mapForSelect(ApprovalStore.sportLiveTemplates(this.sportId));
    }
    return mapForSelect(ApprovalStore.sportPrematchTemplates(this.sportId));
  }

  get outcomesTemplates() {
    if (this.isLive) {
      return mapForSelect(OutcomeStore.sportLiveTemplates(this.sportId));
    }
    return mapForSelect(OutcomeStore.sportPrematchTemplates(this.sportId));
  }

  get marketTemplates() {
    return mapForSelect(MarketStore.sportTemplates(this.sportId, this.eventType));
  }

  get isEditDisabled() {
    return this.store.isEventEditDisabled;
  }

  getDefaultValue(template: any) {
    const key = template.eventKey as keyof FactoryEvent;
    return this.event[key];
  }

  async updatePlayerTemplate(value: any, templateKey: string) {
    if (!isPlayer(this.event)) return;
    const [err] = await to(
      PlayerStore.updateTemplate({ intKey: this.event.intKey, value: value, key: templateKey })
    );
    if (err) return;
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('event.templatesSaved') as string,
    });
  }

  async updateDualTemplate(value: any, templateKey: string) {
    if (this.eventType === 'PLAYER') return;
    const [err] = await to(
      EventStore.updateTemplate({ intKey: this.event.intKey, value: value, key: templateKey })
    );
    if (err) return;
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('event.templatesSaved') as string,
    });
  }

  onSelect(value: any, templateKey: string) {
    this.$confirm({
      title: this.$t('common.areYouSure'),
      content: this.$t('common.doYouWantToChangeTemplate'),
      onOk: () => {
        const handler =
          this.eventType === 'PLAYER' ? this.updatePlayerTemplate : this.updateDualTemplate;
        handler(value, templateKey);
      },
    });
  }
}
