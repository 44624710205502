import { Module, VuexModule, Action, getModule, Mutation } from 'vuex-module-decorators';
import Vue from 'vue';
import to from 'await-to-js';

import store from '@/store';
import { eventService } from '../event';
import { settlementService } from '../settlement/settlement.service';

interface EventTimeMap {
  [accessor: string]: string;
}

interface EventResultMap {
  [accessor: string]: { home: number; away: number };
}

interface TempChanges {
  home: number;
  away: number;
  minute: number;
  period: string;
}

interface EventTempChangesMap {
  [accessor: string]: TempChanges;
}

interface SetEventTime {
  eventId: number;
  time: number;
}

interface SetEventPeriod {
  eventId: number;
  period: string;
}

interface SetEventResult {
  eventId: number;
  home: number;
  away: number;
}

interface SetResultPayload {
  eventId: number;
  home: number;
  away: number;
  isFootball?: boolean;
  time?: string | number | null;
  modalPeriod?: string | null;
  period?: string;
}

interface SetEventTempChanges {
  eventId: number;
  newData: Partial<TempChanges>;
}

const initialState = () => ({
  stopwatchMap: {},
  periodMap: {},
  resultsMap: {},
  tempChangesMap: {},
});

@Module({ dynamic: true, store, name: 'stopwatch', namespaced: true })
class SettlementStopwatch extends VuexModule {
  stopwatchMap: EventTimeMap = initialState().stopwatchMap;
  periodMap: EventTimeMap = initialState().periodMap;
  resultsMap: EventResultMap = initialState().resultsMap;
  tempChangesMap: EventTempChangesMap = initialState().tempChangesMap;

  @Mutation
  initStore() {
    const store = localStorage.getItem('stopwatchStore');
    if (!store) return;
    const storeJson = JSON.parse(store);
    this.stopwatchMap = storeJson.stopwatchMap;
    this.periodMap = storeJson.periodMap;
    this.resultsMap = storeJson.resultsMap;
    this.tempChangesMap = storeJson.tempChangesMap;
  }

  @Action
  saveToLocalStorage() {
    const storeObj = {
      stopwatchMap: this.stopwatchMap,
      periodMap: this.periodMap,
      resultsMap: this.resultsMap,
      tempChangesMap: this.tempChangesMap,
    };
    localStorage.setItem('stopwatchStore', JSON.stringify(storeObj));
  }

  get getEventTime() {
    return (eventId: number) => this.stopwatchMap[eventService.formatEventId(eventId)];
  }

  get getEventPeriod() {
    return (eventId: number) => this.periodMap[eventService.formatEventId(eventId)];
  }

  get getEventResult() {
    return (eventId: number) => this.resultsMap[eventService.formatEventId(eventId)];
  }

  get getEventTempChanges() {
    return (eventId: number) => this.tempChangesMap[eventService.formatEventId(eventId)];
  }

  @Mutation
  clearEventTempChanges(eventId: number) {
    const accessor = eventService.formatEventId(eventId);
    Vue.delete(this.tempChangesMap, accessor);
  }

  @Mutation
  setEventTime({ eventId, time }: SetEventTime) {
    const accessor = eventService.formatEventId(eventId);
    Vue.set(this.stopwatchMap, accessor, time);
  }

  @Mutation
  setEventPeriod({ eventId, period }: SetEventPeriod) {
    const accessor = eventService.formatEventId(eventId);
    Vue.set(this.periodMap, accessor, period);
  }

  @Mutation
  setEventResult({ eventId, home, away }: SetEventResult) {
    const accessor = eventService.formatEventId(eventId);
    typeof away === 'number' &&
      Vue.set(this.resultsMap, accessor, { ...this.resultsMap[accessor], away });
    typeof home === 'number' &&
      Vue.set(this.resultsMap, accessor, { ...this.resultsMap[accessor], home });
  }

  @Mutation
  setEventTempChanges({ eventId, newData }: SetEventTempChanges) {
    const accessor = eventService.formatEventId(eventId);
    Vue.set(this.tempChangesMap, accessor, { ...this.tempChangesMap[accessor], ...newData });
  }

  @Mutation
  resetStore() {
    const initial = initialState() as any;
    Object.keys(initial).forEach((key: any) => {
      this[key as keyof this] = initial[key];
    });
  }

  @Mutation
  resetEventInStore(intKey: number | string) {
    const accessor = eventService.formatEventId(intKey);
    Vue.delete(this.periodMap, accessor);
    Vue.delete(this.resultsMap, accessor);
    Vue.delete(this.stopwatchMap, accessor);
    Vue.delete(this.tempChangesMap, accessor);
  }

  @Mutation
  resetResultMapEvent(intKey: number | string) {
    const accessor = eventService.formatEventId(intKey);
    Vue.delete(this.resultsMap, accessor);
  }

  @Mutation
  removeResultFromLocalStorage(intKey: string | number) {
    const accessor = eventService.formatEventId(intKey);
    const store = localStorage.getItem('stopwatchStore');
    if (!store) return;
    const storeJson = JSON.parse(store);
    if (!storeJson.resultsMap) return;
    delete storeJson.resultsMap[accessor];
    localStorage.setItem('stopwatchStore', JSON.stringify(storeJson));
  }

  @Action
  async setTime({ eventId, time }: SetEventTime) {
    this.setEventTime({ eventId, time });
  }

  @Action
  async setPeriod({ eventId, period }: SetEventPeriod) {
    this.setEventPeriod({ eventId, period });
  }

  @Action
  saveTempChanges({ eventId, isFootball }: { eventId: number; isFootball: boolean }) {
    const accessor = eventService.formatEventId(eventId);
    const tempChanges = this.tempChangesMap[accessor];
    if (!tempChanges) return Promise.reject();
    tempChanges.minute && this.setEventTime({ eventId, time: tempChanges.minute });
    tempChanges.period && this.setEventPeriod({ eventId, period: tempChanges.period });
    this.setEventResult({ eventId, home: tempChanges.home, away: tempChanges.away });
    Vue.delete(this.tempChangesMap, accessor);
    const confirmedResults = this.resultsMap[accessor];
    if (confirmedResults) {
      this.setResult({
        eventId,
        home: confirmedResults.home,
        away: confirmedResults.away,
        isFootball: isFootball,
      });
    }
    this.saveToLocalStorage();
    return Promise.resolve();
  }

  @Action
  async setResult({ eventId, home, away, isFootball, time }: SetResultPayload) {
    const accessor = eventService.formatEventId(eventId);
    const minute = Number(this.stopwatchMap[accessor]) - 1;
    const period = this.periodMap[accessor];
    const payload = {
      time: isFootball ? `${minute}:59` : undefined,
      period: period,
      result: {
        home: home || 0,
        away: away || 0,
      },
    };
    if (time) payload.time = time as any;
    const [err, res] = await to(settlementService.setResult(eventId, payload));
    if (err) return Promise.reject(err);
    return Promise.resolve(res);
  }

  @Action
  async setResultFromMatchData({
    eventId,
    home,
    away,
    isFootball,
    time,
    modalPeriod,
  }: SetResultPayload) {
    const accessor = eventService.formatEventId(eventId);
    const minute = this.stopwatchMap[accessor];
    const payload = {
      time: isFootball ? `${minute}:00` : undefined,
      period: modalPeriod,
      result: {
        home: home || 0,
        away: away || 0,
      },
    };
    if (time) payload.time = time as any;
    const [err, res] = await to(settlementService.setResult(eventId, payload));
    if (err) return Promise.reject(err);
    return Promise.resolve(res);
  }

  @Action
  async setResultPeriodResult({ eventId, home, away, time, period }: SetResultPayload) {
    const payload = {
      time: time ? `${time}:00` : undefined,
      period: period || undefined,
      result: {
        home: home || 0,
        away: away || 0,
      },
    };
    const [err, res] = await to(settlementService.setPeriodResult(eventId, payload));
    if (err) return Promise.reject(err);
    return Promise.resolve(res);
  }
}

export const SettlementStopwatchStore = getModule(SettlementStopwatch);
