
import Component from 'vue-class-component';

import { TableOdd } from '@/ui/components/TableOdd';
import BaseEventCell from './BaseEventCell';
import { LiveEvent, PrematchEvent } from '@/modules/event';

@Component({
  components: { TableOdd },
})
export default class EventMarkets extends BaseEventCell<PrematchEvent | LiveEvent> {
  get isManualChanged() {
    return this.event?.manualChanged;
  }

  get sortedBaseMarkets() {
    if (!this.event?.basePrematchMarkets.length) return [];
    // TODO : change interface
    // @ts-ignore
    return this.event.basePrematchMarkets.sort((m1, m2) => m1.id - m2.id);
  }
}
