import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import to from 'await-to-js';
import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';

import store from '@/store';
import { antepostService } from './antepost.service';
import { objectKeysToCamelCase } from '@/lib/objectKeysToCamelCase';

import {
  AntepostMarket,
  AntepostMarketEditPayload,
  AntepostFormData,
  Antepost as IAntepost,
  NewOperatorNotePayload,
  AntepostBet,
  AntepostEditPayload,
  AntepostBetFormData,
  AntepostBetState,
  AntepostState,
  AntepostBetUpdateMessage,
  AntepostMarketUpdateMessage,
  MoveToEventMngPayload,
  AntepostRemovePayload,
  EventRequestSource,
} from './antepost.types';
import { AntepostUpdateMessage } from '.';
import isEmpty from 'lodash/isEmpty';

// TODO: Remove this comment
const initialState = () => ({
  markets: [],
  selectedMarket: -1,
  marketForEdit: null,
  antepostForEdit: null,
  selectedAntepost: 0,
  betForEdit: null,
  anteposts: {},
  bets: {},
  selectedAnteposts: [],
  lastTableChange: 0,
  requestSource: 'other' as EventRequestSource,
});
@Module({ dynamic: true, store, name: 'antepost', namespaced: true })
class Antepost extends VuexModule {
  markets: AntepostMarket[] = initialState().markets;
  selectedMarket = initialState().selectedMarket;
  marketForEdit: AntepostMarket | null = initialState().marketForEdit;
  antepostForEdit: IAntepost | null = initialState().antepostForEdit;
  selectedAntepost = initialState().selectedAntepost;
  selectedAnteposts: IAntepost[] = initialState().selectedAnteposts;
  betForEdit: AntepostBet | null = initialState().betForEdit;
  requestSource: EventRequestSource = initialState().requestSource;
  lastTableChange = 0;
  antepostsFetched = false;

  anteposts = initialState().anteposts;
  bets = initialState().bets;

  get selectedMarketAnteposts() {
    // @ts-ignore
    return this.anteposts[this.selectedMarket] || [];
  }

  get selectedAntepostBets() {
    // @ts-ignore
    if (!this.bets[this.selectedAntepost]) return [];
    // @ts-ignore
    return antepostService.sortAntepostBetsByCode(this.bets[this.selectedAntepost]);
  }

  get availableStates() {
    const a = antepostService.bulkStateTansition(this.selectedAnteposts);
    if (a) {
      return [...(a.next || []), ...(a.prev || [])].map((a: any) => ({
        label: a.label,
        value: a.value,
      }));
    }
    return [];
  }

  get canBulkSuspend() {
    return () => {
      let canSuspend = false;
      if (this.selectedAnteposts.length) {
        canSuspend = antepostService.canBulkSuspend(this.selectedAnteposts);
      }
      return canSuspend;
    };
  }

  get nextBulkSuspendState() {
    return () => {
      if (!this.canBulkSuspend()) return '';
      return this.selectedAnteposts[0].isSuspended ? 'unsuspend' : 'suspend';
    };
  }

  get active() {
    if (this.selectedAntepost) {
      return this.selectedMarketAnteposts.find((antepost: IAntepost) => {
        return antepost.intKey === this.selectedAntepost;
      });
    }
    return null;
  }

  @Mutation
  setTableUpdate() {
    this.lastTableChange = Date.now();
  }

  @Mutation
  resetStore() {
    const initial = initialState() as any;
    Object.keys(initial).forEach(key => {
      this[key as keyof this] = initial[key];
    });
  }

  @Mutation
  setBetForEdit(bet: AntepostBet | null) {
    this.betForEdit = bet;
  }

  @Mutation
  clearSelectedAnteposts() {
    this.selectedAnteposts = [];
  }

  @Mutation
  setMarkets(antepostMarketsList: AntepostMarket[]) {
    this.markets = antepostMarketsList;
  }

  @Mutation
  setRequestSource(source: EventRequestSource) {
    this.requestSource = source;
  }

  @Mutation
  private setAnteposts(anteposts: IAntepost[]) {
    anteposts.forEach((antepost: any) => {
      const marketId = antepost.marketId;
      Vue.set(
        this.anteposts,
        marketId,
        anteposts.filter(antepost => antepost.marketId === marketId)
      );
    });
  }

  @Mutation
  setAntepostBets(anteposts: IAntepost[]) {
    anteposts.forEach((antepost: any) => {
      const intKey = antepost.intKey;
      const mappedBets: AntepostBet[] = [];
      antepost.allBetsMap.forEach((bet: AntepostBet[]) => {
        const betWithDividedOdd = bet[1];
        betWithDividedOdd.oddValue /= 100;
        mappedBets.push(betWithDividedOdd);
      });
      // @ts-ignore-next-line
      this.bets[intKey] = mappedBets;
    });
  }

  @Mutation
  private setAntepostBetsFromConfig({ antepostId, bets }: any) {
    Vue.set(this.bets, antepostId, bets);
  }

  @Mutation
  private pushMarketToBeggining(market: AntepostMarket) {
    this.markets.unshift(market);
  }

  @Mutation
  private updateMarketInStore({ id, params }: { id: number; params: Partial<AntepostMarket> }) {
    this.markets?.forEach((market, index) => {
      if (market.id === id) {
        const updatedMarket = {
          ...market,
          ...params,
        };
        updatedMarket.shortCode = updatedMarket.short_code as string;
        // @ts-ignore
        Vue.set(this.markets, index, updatedMarket);
      }
    });
  }

  @Mutation
  selectMarket(marketId: number) {
    this.selectedMarket = marketId;
    this.selectedAnteposts = [];
    this.selectedAntepost = 0;
  }

  @Mutation
  selectAntepost(antepostId: number) {
    this.selectedAntepost = antepostId;
  }

  @Mutation
  setMarketForEdit(market: AntepostMarket | null) {
    this.marketForEdit = market;
  }

  @Mutation
  setAntepostForEdit(antepost: IAntepost | null) {
    this.antepostForEdit = antepost;
  }

  @Mutation
  private removeMarketFromStore(marketId: number) {
    const index = this.markets.findIndex(m => m.id === marketId);
    if (index === -1) return;
    this.markets.splice(index, 1);
  }

  @Mutation
  setActiveEvent(id: number) {
    this.selectedAntepost = id;
  }

  @Mutation
  updateAntepostInStore({
    marketId,
    antepostId,
    params,
  }: {
    marketId: number;
    antepostId: number;
    params: Partial<IAntepost>;
  }) {
    // @ts-ignore
    const anteposts = this.anteposts[marketId] || [];
    anteposts.forEach((antepost: IAntepost) => {
      if (antepost.intKey === antepostId) {
        const keys = Object.keys(params);
        keys.forEach(key => {
          // @ts-ignore
          antepost[key] = params[key];
        });
      }
    });
  }

  @Mutation
  updateBetInStore({ betId, params }: { betId: number; params: Partial<AntepostBet> }) {
    //@ts-ignore
    const selectedAntepostBets = this.bets[this.selectedAntepost] || [];
    selectedAntepostBets.forEach((bet: AntepostBet) => {
      if (bet.intKey === betId) {
        const keys = Object.keys(params);
        keys.forEach(key => {
          // @ts-ignore
          bet[key] = params[key];
        });
      }
    });
  }

  @Mutation
  private removeBetFromStore({ antepostId, id }: { id: number; antepostId: number }) {
    //@ts-ignore
    const bets = this.bets[antepostId] || [];
    const result = bets.filter((bet: AntepostBet) => bet.intKey !== id);
    Vue.set(this.bets, antepostId, result);
  }

  @Mutation
  private removeAntepostFromStore({ id, marketId }: { id: number; marketId: number }) {
    // @ts-ignore
    const anteposts = this.anteposts[marketId] || [];
    const result = anteposts.filter((antepost: IAntepost) => antepost.intKey !== id);
    Vue.set(this.anteposts, marketId, result);
  }

  @Mutation
  addToSelectedEvents(event: IAntepost) {
    this.selectedAnteposts.push(cloneDeep(event));
  }

  @Mutation
  removeSelectedEvent(event: IAntepost) {
    this.selectedAnteposts = this.selectedAnteposts.filter((e: any) => e.intKey !== event.intKey);
  }

  @Mutation
  setMarketSuspended({ market_id, is_suspended }: { market_id: number; is_suspended: boolean }) {
    const index = this.markets.findIndex(market => market.id === market_id);
    if (index >= 0) this.markets[index].isSuspended = is_suspended;
  }

  @Mutation
  updateAntepostOperatorNote(wsData: { e_i_k: number; n: string }) {
    let antepost: IAntepost;
    if (isEmpty(this.anteposts)) return;
    for (const key in this.anteposts) {
      // @ts-expect-error
      antepost = this.anteposts[key].find(
        (antepost: IAntepost) => antepost.intKey === wsData.e_i_k
      );
      if (antepost) {
        antepost.operatorNotesList = [
          ...antepost.operatorNotesList,
          { note: wsData.n, operatorName: '', userId: 0, createdAt: '' },
        ];
      }
    }
  }

  @Mutation
  setAntepostsFetched(value: boolean) {
    this.antepostsFetched = value;
  }

  @Action
  async getMarkets() {
    const result = await antepostService.getMarkets();
    // @ts-ignore-next-line
    this.setMarkets(result.antepostMarketsList);
    // @ts-ignore-next-line
    result.antepostMarketsList.forEach(market => {
      if (market.isSuspended) {
        this.onSuspendAntepostMarket({ market_id: market.id });
      }
    });
  }

  @Action
  async saveMarket(payload: AntepostMarketEditPayload): Promise<any> {
    const [err] = await to<any>(antepostService.saveMarket(payload));
    if (err) return Promise.reject(err);
    this.getMarkets();
  }

  @Action
  async updateMarket({
    marketId,
    payload,
  }: {
    marketId: number;
    payload: Partial<AntepostMarketEditPayload>;
  }) {
    const [err] = await to(antepostService.updateMarket(marketId, payload));
    if (err) return Promise.reject(err);
    this.updateMarketInStore({ id: marketId, params: payload });
  }

  @Action
  async deleteMarket(marketId: number) {
    const [err] = await to(antepostService.deleteMarket(marketId));
    if (err) return Promise.reject(err);
    this.removeMarketFromStore(marketId);
  }

  @Action
  async getAnteposts() {
    if (!this.selectedMarket || this.selectedMarket === -1) return;
    const [err, markets] = await to<any>(antepostService.getAnteposts(this.selectedMarket));
    if (err) return Promise.reject(err);
    this.setAnteposts(markets);
  }

  @Action
  async getAntepostOffer(isPrematch = false) {
    this.setAntepostsFetched(false);
    const api = isPrematch
      ? antepostService.getPrematchAnteposts
      : antepostService.getAntepostOffer;
    const anteposts = (await api()) as IAntepost[];
    this.setAnteposts(anteposts);
    this.setAntepostBets(anteposts);
    this.setAntepostsFetched(true);
  }

  @Action
  async createAntepost(formData: AntepostFormData) {
    const payload = { ...formData, antepost_market_id: this.selectedMarket };
    const [err] = await to(antepostService.createAntepost(payload));
    if (err) return Promise.reject(err);
    this.getAnteposts();
  }

  @Action
  async updateAntepost({ id, payload }: { id: number; payload: Partial<AntepostEditPayload> }) {
    const [err] = await to(antepostService.updateAntepost(id, payload));
    if (err) return Promise.reject(err);
    this.updateAntepostInStore({ marketId: this.selectedMarket, antepostId: id, params: payload });
  }

  @Action
  async deleteAntepost(id: number) {
    const [err] = await to(antepostService.deleteAntepost(id));
    if (err) return Promise.reject(err);
    this.removeAntepostFromStore({ id, marketId: this.selectedMarket });
  }

  @Action
  onUpdateOperatorNote(wsData: { e_i_k: number; n: string }) {
    this.updateAntepostOperatorNote(wsData);
  }

  @Action
  async addOperaterNote(payload: NewOperatorNotePayload) {
    if (!this.antepostForEdit && !this.active) return;
    const [err] = await to(antepostService.addOperatorNote(payload));
    if (err) return Promise.reject(err);
  }

  @Action
  async createBet(formData: AntepostBetFormData) {
    const payload = { ...formData, antepost_id: this.selectedAntepost };
    const [err] = await to(antepostService.createAntepostBet(payload));
    if (err) return Promise.reject(err);
    this.getAntepostBets();
  }

  @Action
  async updateAntepostBet({
    id,
    bet,
  }: {
    id: number;
    bet: AntepostBetFormData | Partial<AntepostBet>;
  }) {
    const [err] = await to(antepostService.updateAntepostBet(id, bet));
    if (err) return Promise.reject(err);
    this.updateBetInStore({
      betId: id,
      params: objectKeysToCamelCase(bet) as Partial<AntepostBet>,
    });
  }

  @Action
  async getAntepostBets() {
    if (!this.selectedAntepost) return;
    const [err, data] = await to(antepostService.getAntepostBets([this.selectedAntepost]));
    if (err) return Promise.reject(err);
    this.setAntepostBetsFromConfig({ bets: data, antepostId: this.selectedAntepost });
  }

  @Action
  async deleteBet(id: number) {
    const [err] = await to(antepostService.deleteBet(id));
    if (err) return Promise.reject(err);
    this.removeBetFromStore({ id, antepostId: this.selectedAntepost });
    this.setTableUpdate();
  }

  @Action
  async moveAntepostToEventMng(payload: MoveToEventMngPayload) {
    const [err] = await to(antepostService.moveAntepostToEventMng(payload));
    if (err) return Promise.reject(err);
    this.getAnteposts();
  }

  @Action
  selectRow({ event, selected }: { event: IAntepost; selected: boolean }) {
    if (selected) {
      this.addToSelectedEvents(event);
    } else {
      this.removeSelectedEvent(event);
    }
  }

  @Action
  async suspendSelected() {
    const event = this.selectedAnteposts[0] as IAntepost;
    const nextValue = !event.isSuspended;
    const eventIds = this.selectedAnteposts.map(event => event.intKey);
    const [err] = await to(antepostService.suspendAntepost(eventIds, nextValue));
    if (err) return Promise.reject(err);
    eventIds.forEach(eventId => {
      this.updateAntepostInStore({
        marketId: this.selectedMarket,
        antepostId: eventId,
        params: { isSuspended: nextValue },
      });
    });
    this.clearSelectedAnteposts();
    return Promise.resolve();
  }

  @Action
  async changeBulkState(state: AntepostState) {
    const events = this.selectedAnteposts.map((e: any) => e.intKey);
    const [err] = await to(antepostService.changeState(events, state));
    if (err) return Promise.reject(err);
    this.selectedAnteposts.forEach((event: IAntepost) => {
      this.updateAntepostInStore({
        marketId: this.selectedMarket,
        antepostId: event.intKey,
        params: { state: state },
      });
    });
    return Promise.resolve();
  }

  @Action
  async suspendBet(payload: { bet: AntepostBet; value: boolean }) {
    const { bet, value } = payload;
    const [err] = await to(antepostService.suspendBet({ id: bet.intKey, value }));
    if (err) return Promise.reject(err);
    this.updateBetInStore({ betId: bet.intKey, params: { isSuspended: value } });
  }

  @Action
  async changeBetState({ betId, nextState }: { betId: number; nextState: AntepostBetState }) {
    const [err] = await to(antepostService.changeBetState([betId], nextState));
    if (err) return Promise.reject(err);
    this.updateBetInStore({ betId, params: { state: nextState } });
  }

  @Action
  async removeAntepostFromOffer(antepostId: number) {
    const [err] = await to(antepostService.removeAntepostFromOffer(antepostId));
    if (err) return Promise.reject();
  }

  @Action
  onAntepostBetUpdate(payload: AntepostBetUpdateMessage) {
    this.updateBetInStore({
      betId: payload.id,
      params: {
        code: payload.c,
        isSuspended: payload.i_s,
        name: payload.na,
        oddValue: payload.o_v,
      },
    });
  }

  @Action
  onAntepostRemoved({ a_i_k: id, m_id: marketId }: AntepostRemovePayload) {
    this.removeAntepostFromStore({ id, marketId });
  }

  @Action
  onAntepostSuspended(payload: { antepost_id: number }[]) {
    if (this.selectedMarket === -1) return;
    payload.forEach(data => {
      this.updateAntepostInStore({
        marketId: this.selectedMarket,
        antepostId: data.antepost_id,
        params: { isSuspended: true },
      });
    });
    this.setTableUpdate();
  }

  @Action
  onAntepostUnsuspended(payload: { antepost_id: number }[]) {
    if (this.selectedMarket === -1) return;
    payload.forEach(data => {
      this.updateAntepostInStore({
        marketId: this.selectedMarket,
        antepostId: data.antepost_id,
        params: { isSuspended: false },
      });
    });
    this.setTableUpdate();
  }

  @Action
  onAntepostUpdate(payload: AntepostUpdateMessage) {
    this.updateAntepostInStore({
      marketId: payload.m_i,
      antepostId: payload.i_k,
      params: {
        name: payload.na,
        note: payload.n,
        state: payload.st,
        start: payload.s,
        isSuspended: payload.i_s,
        code: payload.c,
      },
    });
    this.setTableUpdate();
  }

  @Action
  onAntepostMarketUpdate(payload: AntepostMarketUpdateMessage) {
    this.updateMarketInStore({
      id: parseInt(payload.id),
      params: {
        name: payload.na,
      },
    });
  }
  @Action
  async suspendAntepostMarket(payload: { antepost_id: number; is_suspended: boolean }) {
    const [err] = await to(
      antepostService.suspendAntepostMarket(payload.antepost_id, payload.is_suspended)
    );
    if (err) return Promise.reject();
    this.setMarketSuspended({ market_id: payload.antepost_id, is_suspended: payload.is_suspended });
    return Promise.resolve();
  }

  @Action
  onUnsuspendAntepostMarket({ market_id }: { market_id: number }) {
    // @ts-ignore
    if (!this.anteposts[market_id]) return;
    // @ts-ignore
    this.anteposts[market_id].forEach(antepost => {
      this.updateAntepostInStore({
        marketId: market_id,
        antepostId: antepost.intKey,
        params: { isSuspended: false },
      });
    });
    this.setTableUpdate();
  }

  @Action
  onSuspendAntepostMarket({ market_id }: { market_id: number }) {
    // @ts-ignore
    if (!this.anteposts[market_id]) return;
    // @ts-ignore
    this.anteposts[market_id].forEach(antepost => {
      this.updateAntepostInStore({
        marketId: market_id,
        antepostId: antepost.intKey,
        params: { isSuspended: true },
      });
    });
    this.setTableUpdate();
  }

  @Action
  async setAntepostMaxBet(payload: { newValue: number; intKey: number }) {
    const [err] = await to(antepostService.setAntepostMaxBet(payload));
    if (err) return Promise.reject();
    return Promise.resolve();
  }
}

export const AntepostStore = getModule(Antepost);
