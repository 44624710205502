var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.container},[_c('tape',{attrs:{"label":_vm.$t('event.operaterNote')}}),_c('div',{ref:"notes",class:_vm.$style.notes,attrs:{"id":"notesContainer"}},_vm._l((_vm.sortedNotes),function(note){return _c('a-comment',{key:note.note,attrs:{"author":note.author,"datetime":note.created_at,"content":note.note}})}),1),_c('div',{class:_vm.$style.newNote},[_c('a-input',{attrs:{"value":_vm.newOperatorNote,"placeholder":_vm.$t('event.noteMessage')},on:{"change":function (ref) {
	var target = ref.target;

	return (_vm.newOperatorNote = target.value);
},"pressEnter":_vm.addOperatorNote}}),_c('a-button',{attrs:{"loading":_vm.isLoading,"type":"primary"},on:{"click":_vm.addOperatorNote}},[_vm._v(_vm._s(_vm.$t('event.addNote')))])],1),(!_vm.isLive)?_c('tape',{attrs:{"label":_vm.$t('event.gameNote')}}):_vm._e(),(!_vm.isLive)?_c('div',{class:_vm.$style.gameNote},[_c('a-textarea',{class:_vm.$style.gameNoteText,attrs:{"value":_vm.eventNote,"placeholder":_vm.$t('event.gameNote'),"auto-size":{ minRows: 2, maxRows: 5 }},on:{"change":function (ref) {
	var target = ref.target;

	return (_vm.newGameNote = target.value);
}}}),_c('div',[_c('input-confirm',{attrs:{"position":"leftBottom","title":_vm.$t('approval.default')},on:{"confirm":_vm.saveGameNote},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('a-button',{attrs:{"loading":_vm.isLoading,"type":"primary"}},[_vm._v(_vm._s(_vm.$t('event.saveGameNote')))])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }