
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import to from 'await-to-js';

import { MarketStore } from '@/modules/market/market.store';
import { EventStore } from '@/modules/event/event.store';
import { eventService } from '@/modules/event/event.service';
import MarketsWithOdds from './MarketsWithOdds.vue';
import { InputParams } from '@/ui/components/InputParams';
import { InputParamsMarket } from '@/ui/components/InputParamsMarket';
import { isPlayer, playerService, PlayerStore } from '@/modules/player';
import { objToArr } from '@/lib/objToArr';
import { InputParamsTennis } from '@/ui/components/InputParamsTennis';
import { SportCode, SportStore } from '@/modules/sport';
import { outcomeService } from '@/modules/outcome/outcome.service';
import { GoesThroughMarket } from '@/modules/market/market.types';
import isEmpty from 'lodash/isEmpty';

const modalBodyStyle = {
  height: 'calc(100vh - 125px)',
  overflow: 'overlay',
};

@Component({
  components: { MarketsWithOdds, InputParams, InputParamsMarket, InputParamsTennis },
})
export default class CompareOddsModal extends Vue {
  bodyStyle = modalBodyStyle;
  oddsChanged = false;
  isSaving = false;

  @Prop({ type: Boolean })
  visible!: boolean;

  @Prop({ type: Function })
  close!: () => void;

  @Prop({ type: Function })
  afterSave!: () => void;

  @Prop({ default: 'PREMATCH', type: String })
  eventType!: 'PREMATCH' | 'PLAYER';

  get store() {
    switch (this.eventType) {
      case 'PREMATCH':
        return EventStore;
      case 'PLAYER':
        return PlayerStore;
      default:
        return EventStore;
    }
  }

  get selectedEvent() {
    return this.store.active;
  }

  get goesThroughMarket() {
    return MarketStore.goesThroughMarket;
  }

  get tempOddValues() {
    return MarketStore.tempOddValues;
  }

  get sport() {
    if (!this.selectedEvent) return null;
    return SportStore.getSportById(this.selectedEvent.sportId);
  }

  get isTennis() {
    if (!this.sport) return false;
    return this.sport?.code === SportCode.TENNIS;
  }

  get selectedId() {
    if (!this.selectedEvent) return '';
    return this.selectedEvent.intKey;
  }

  get markets() {
    const markets = MarketStore.markets ?? [];
    return markets;
  }

  get noMarkets() {
    return MarketStore.hasMarkets === false;
  }

  get isLoading() {
    return MarketStore.isLoading;
  }

  get generatedOdds() {
    return this.store.tempGeneratedOdds;
  }

  get generatedMarkets() {
    return this.eventType === 'PLAYER' ? [this.store.tempGeneratedOdds] : this.eventMarkets;
  }

  get eventMarkets() {
    if (
      MarketStore.isGoesThroughEnabled(
        // @ts-ignore
        this.selectedEvent.prematchMarketTemplateId,
        this.selectedEvent?.sportId as number
      )
    ) {
      const marketId = Number(Object.keys(this.goesThroughMarket)[0]);
      const goesThroughMarket: GoesThroughMarket = {};
      this.markets.forEach(market => {
        if (market.id === marketId) {
          goesThroughMarket[market.id] = {
            odds: market.odds,
            name: this.goesThroughMarket[marketId].name,
          };
        }
      });
      if (!isEmpty(goesThroughMarket) && !goesThroughMarket[marketId].odds.length) {
        return objToArr({
          ...this.store.tempGeneratedOdds,
        });
      }
      if (!isEmpty(goesThroughMarket) && goesThroughMarket[marketId].odds.length) {
        goesThroughMarket[marketId].odds.forEach(odd => {
          MarketStore.setTempOddValue({
            id: odd.outcome_id,
            value: ((odd.prematch_prod_odd as number) / 100) as number,
          });
        });
        return objToArr({ ...this.store.tempGeneratedOdds, ...goesThroughMarket });
      }
    }
    return objToArr(this.store.tempGeneratedOdds);
  }

  get confirmedOdds() {
    if (this.eventType === 'PLAYER') return playerService.getOddsValues(this.generatedOdds);
    const confirmedOdds = eventService.getOddValues(this.generatedOdds);
    const customOdds = outcomeService.prepareOddsRequest(this.tempOddValues);
    return { ...confirmedOdds, ...customOdds };
  }

  errorMessage(description = this.$t('common.somethingWentWrong')) {
    this.$notification.warning({
      message: this.$t('common.error') as string,
      description: description as string,
    });
  }

  successMessage() {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('inputParams.oddChangesAccepted') as string,
    });
  }

  afterOddsConfirm() {
    this.oddsChanged = true;
    this.successMessage();
    this.updateStoreBaseMarketOdds();
    this.close();
    this.afterSave && this.afterSave();
  }

  async onSaveDual() {
    if (!this.selectedEvent || isPlayer(this.selectedEvent)) return;
    this.isSaving = true;
    const inputParams = {
      ...EventStore.activeEventInputParams,
      ...EventStore.tempInputParams.input_parameters,
    };

    const payload = {
      input_parameters: inputParams,
      odds: this.confirmedOdds,
      model: EventStore.defaultSportModel,
    };
    const [err] = await to(eventService.confirmOddsChange(this.selectedEvent.intKey, payload));
    this.isSaving = false;
    if (err) return this.errorMessage();
    this.afterOddsConfirm();
  }

  async onSaveTennis() {
    if (!this.selectedEvent || isPlayer(this.selectedEvent)) return;
    this.isSaving = true;
    const inputParams = {
      ...EventStore.activeEventInputParams,
      ...EventStore.tempInputParams,
    };
    const validPayload = eventService.getTennisInputParamsForConfirmOdds(
      inputParams,
      this.confirmedOdds
    );
    const [err] = await to(eventService.confirmOddsChange(this.selectedEvent.intKey, validPayload));
    this.isSaving = false;
    if (err) return this.errorMessage();
    this.afterOddsConfirm();
  }

  async onSavePlayer() {
    if (!this.selectedEvent || !isPlayer(this.selectedEvent)) return;
    this.isSaving = true;
    const inputParams = {
      ...this.selectedEvent.inputParameters,
      ...PlayerStore.tempInputParams,
    };
    const [err] = await to(
      PlayerStore.confirmGeneratedOdds({
        input_parameters: inputParams,
        odds: this.confirmedOdds,
      })
    );
    this.isSaving = false;
    if (err) return this.errorMessage();
    this.afterOddsConfirm();
  }

  async onSave() {
    if (this.isTennis) return this.onSaveTennis();
    const handler = isPlayer(this.selectedEvent) ? this.onSavePlayer : this.onSaveDual;
    handler();
  }

  handleClose() {
    this.store.clearInputParams();
    this.close();
  }

  updateStoreBaseMarketOdds() {
    const oddEntires = Object.entries(this.confirmedOdds);
    const req = isPlayer(this.selectedEvent)
      ? PlayerStore.updateBaseMarketOdd
      : EventStore.updateBaseMarketOdd;
    oddEntires.forEach(([key, value]: Array<any>): void => {
      req({
        oddId: Number(key),
        value: value,
        eventId: this.selectedId,
      });
    });
  }

  afterModalClose() {
    if (!this.oddsChanged) return;
    if (!this.selectedEvent) return;
    MarketStore.resetMarkets();
    if (isPlayer(this.selectedEvent)) {
      MarketStore.getPlayerEventMarkets(this.selectedEvent.intKey);
      return;
    }
    MarketStore.getMarkets(this.selectedEvent.intKey);
  }

  onEsc() {
    // param is needed e: any
    return;
  }
}
