import { EventStage, EventState } from '../event';

export enum FeedMessageType {
  ANTEPOST_MARKET_UPDATED = 'antepost_market_update',
  ANTEPOST_REMOVED = 'antepost_removed',
  ANTEPOST_SUSPENDED = 'antepost_suspended',
  ANTEPOST_UNSUSPENED = 'antepost_unsuspended',
  ANTEPOST_UPDATED = 'antepost_update',
  ANTEPSOT_BET_UPDATE = 'antepost_bet_update',
  CODE_CHANGED = 'code_changed',
  EVEN_UNSUSPENDED_PREMATCH = 'event_unsuspended_prematch',
  EVENT_END = 'event_end',
  EVENT_HIDDEN = 'event_hidden',
  EVENT_PLAYER_CODE_CHANGED = 'event_player_code_changed',
  EVENT_PLAYER_HIDDEN = 'event_player_hidden',
  EVENT_PUBLISHED = 'event_published',
  EVENT_START_DATE_UPDATED = 'event_start_date_updated',
  EVENT_SUSPENDED_LIVE = 'event_suspended_live',
  EVENT_SUSPENDED_PREMATCH = 'event_suspended_prematch',
  EVENT_UNSUSPENDED_LIVE = 'event_unsuspended_live',
  LIVE_ODDS = 'live_odds',
  LIVE_OFF = 'live_off',
  LIVE_ON = 'live_on',
  MARKET_EVENT_SUSPENDED = 'market_event_suspended',
  MARKET_EVENT_UNSUSPENDED = 'market_event_unsuspended',
  // MARKET_TEMPLATE_CHANGED = 'market_template_changed', // Deprecated, using NUM_OF_TEMPALTE_ODDS_CHANGED
  NUM_OF_TEMPLATE_ODD_CHANGE = 'num_of_template_odds_changed',
  ODDS = 'odds',
  ODDS_WARNIGN = 'odds_warning',
  PLAYER_ODDS = 'player_odds',
  EVENTS_VALIDATED = 'events_validated',
  PLAYER_EVENTS_VALIDATED = 'player_events_validated',
  EVENTS_PUBLISHED = 'events_published',
  PREMATCH_OFF = 'prematch_off',
  PREMATCH_ON = 'prematch_on',
  PREMATCH_PROD_ODD = 'prod_odd',
  PROD_ODD_CHANGED = 'num_of_prod_odds_changed',
  RESULT = 'result',
  SETTLEMENT_NOT_STARTED = 'settlement_not_started',
  UPDATE_EGAL_ODDS = 'update_egal_odds',
  ANTEPOST_MARKET_SUSPENDED = 'antepost_market_suspended',
  ANTEPOST_MARKET_UNSUSPENDED = 'antepost_market_unsuspended',
  EVENT_BET_STOP_lIVE = 'event_bet_stop_live',
  EVENT_BET_START_LIVE = 'event_bet_start_live',
  EVENT_PLAYER_TEMPLATES_CHANGED = 'event_player_tempates_changed',
  D_OPERATOR_NOTE = 'D_operator_note',
  D_NOTE = 'D_note',
  D_SETTLEMENT_NOTE = 'D_settlement_note',
  P_OPERATOR_NOTE = 'P_operator_note',
  P_NOTE = 'P_note',
  A_OPERATOR_NOTE = 'A_operator_note',
  MILLION_TICKETS_COUNT = 'million_tickets_count',
  SENT_TO_SETTLEMENT = 'sent_to_settlement',
  ASSIGNEED = 'assigned',
  WITHDRAWAL_RESERVATION = 'withdrawal_reservation',
  WITHDRAWAL_RESERVATION_UPDATED = 'withdrawal_reservation_updated',
  PREMATCH_REMOVED = 'prematch_removed',
  CHECKBOX_1530 = 'checkbox_1530',
  CHECKBOX_M1 = 'checkbox_m1',
  NEW_SLIP = 'new_slip',
  SLIP_STATUS_UPDATE = 'slip_status_update',
  EVENT_TECH_UPDATED = 'event_tech_updated',
  HAS_GOES_THROUGH = 'has_goes_through',
  EVENTS_COMPETITORS_UPDATED = 'events_competitors_updated',
  EVENTS_COMPETITITON_UPDATE = 'events_competition_updated',
  EVENTS_SETTLEMENT_UPDATED = 'events_settlement_updated',
  EVENT_LIVE_PROVIDERS_UPDATED = 'event_live_providers_updated',
}

export type EventHandlers = {
  [k in FeedMessageType]: (message: any) => void;
};

export interface EventMaxAmountWebMessage {
  e_i: string;
  l_m_a_w: number | null; // live_max_amount_web
}

export interface EventMaxAmountScMessage {
  e_i: string;
  l_m_a_s: number | null; // live_max_amount_sc
}

export interface EventMaxAmountPrematchMessage {
  e_i: string;
  p_m_a: number | null; // prematch_max_amount
}

export interface MarketMaxAmountWebMessage {
  m_i: number;
  l_m_a_w: number | null;
}

export interface MarketMaxAmountScMessage {
  m_i: number;
  l_m_a_s: number | null; // live_max_amount_sc
}

export interface MarketMaxAmountPrematchMessage {
  m_i: number;
  p_m_a: number | null; // prematch_max_amount
}

export interface OddMaxAmountWebMessage {
  e_i: string;
  o_i_k: number;
  l_m_a_w: number | null;
}

export interface OddMaxAmountScMessage {
  e_i: string;
  o_i_k: number;
  l_m_a_s: number | null; // live_max_amount_sc
}

export interface OddMaxAmountPrematchMessage {
  e_i: string;
  o_i_k: number;
  p_m_a: number | null; // prematch_max_amount
}

export interface FeedOdd {
  i_k: number; //int_key
  i_s: boolean; //is_suspended
  m: number; // marketid
  o: number; // outcomeid
  l: number | null; // limit
  p_v: string | number | null; // prod_value
  r_v: string | number | null; // real value
  m_c: boolean; //manual changed
  isdbp: boolean; // is odd disabled by provider
  isdbt: boolean;
  mn: string;
  on: string;
}

export interface FeedLiveOdd {
  i_k: number;
  i_s: boolean;
  o: number;
  m: number;
  lim: number | null;
  l: number | null;
  v: number | 'NaN';
  p_v: number;
  r_v: number;
  isdbp: boolean;
  isdbt: boolean;
  on: string;
  mn: string;
}

export interface OddMessageData<T> {
  code: null | number;
  e_id: string;
  e_i_k: number;
  odds: T[];
  base_odds: T[];
}

interface PeriodScore {
  period_away_score: number;
  period_home_score: number;
  period_name: string;
}

export interface CodeMessageData {
  i_k: number;
  c: number | undefined;
}

export interface ResultMessageData {
  e_id: string;
  code: number | null;
  time: string;
  r: {
    c: boolean; // is confirmed result
    d_r: {
      [key: string]: { period_home_score: number; period_away_score: number; period_name: string };
    }; // period of interest
    c_r: { [key: string]: number[] };
  };
}

export interface DualEventSuspendedMessage {
  e_i_k: number;
  e_l_s?: string;
  s?: string;
}

export interface EventSuspendedMessage {
  event_id: number;
}

export enum OddDirection {
  DOWN = 'down',
  UP = 'up',
}

export interface NumberOfProdOddMessage {
  e_id: number;
  o_m: number;
  o_p: number;
}

export interface NumOfTemplateOddMessage {
  e_id: string;
  n_o: number;
}

export interface MarketTemplateChangedMessage {
  e_id: number;
  n_o: number; // number of odds
  t_i: number; // market template ID
}

export interface EventPublishedMessage {
  i: string;
}

export interface EgalOddWs {
  i: string;
  i_k: number;
  i_s: boolean;
  l_m_a_s: number;
  l_m_a_w: number;
  l_p_o: string;
  l_r_o: string;
  lim: string;
  m_c: string;
  o_i: string;
  o_n: string;
}

export interface EgalOddsUpdateMessage {
  event_int_key: number;
  market_id: number;
  odds: EgalOddWs[];
}

export interface LiveOddsWarningMessage {
  i_k: number;
  l: string;
  lim: string;
  p: number; //prematch odd value
}

export interface ProdOddMessage {
  o_i_k: number;
  p_v: number;
  m_c: boolean;
}

export interface ValidatedDualEvent {
  i_k: number;
  n: string;
  c_i: number;
  c_n: string;
  l_i: number;
  s_i: number;
  stg: EventStage;
  sta: EventState;
  e_c: number;
}

export interface DualEventsValidatedMessage {
  [eventIntKey: number]: ValidatedDualEvent;
}

interface DualEventPublishedOdd {
  i_k: number;
  o_i: number;
  o_v: string;
  i_s: boolean;
}

export interface DualEventsPublishedMessage {
  n: string;
  c_i: number;
  c_n: string;
  l_i: number;
  s_i: number;
  sta: string;
  stg: string;
  e_c: number;
  o: DualEventPublishedOdd[];
}
export interface EventStartDateUpdated {
  i_k: number;
  sta: string;
}

export interface EventEndMessage {
  e_i_k: number;
  e_l_s: string;
}

export type OddsMap = { [key: number]: FeedOdd };
export type ResultsMap = { [key: string]: ResultMessageData };
export type WarningOddsMap = { [key: number]: LiveOddsWarningMessage };
