import { Module, VuexModule, Action, getModule, Mutation } from 'vuex-module-decorators';
import to from 'await-to-js';

import store from '@/store';
import { platformService } from './platform.service';

@Module({ dynamic: true, store, name: 'platform', namespaced: true })
class Platform extends VuexModule {
  isLiveSuspended: null | boolean = null;
  isPrematchSuspended: null | boolean = null;

  @Mutation
  setLiveSuspendStatus(value: boolean) {
    this.isLiveSuspended = value;
  }

  @Mutation
  setPrematchSuspendStatus(value: boolean) {
    this.isPrematchSuspended = value;
  }

  @Mutation
  onLiveOn() {
    this.isLiveSuspended = false;
  }

  @Mutation
  onLiveOff() {
    this.isLiveSuspended = true;
  }

  @Mutation
  onPrematchOn() {
    this.isPrematchSuspended = false;
  }

  @Mutation
  onPrematchOff() {
    this.isPrematchSuspended = true;
  }

  @Action
  async getIsLiveSuspended() {
    const [err, res] = await to(platformService.getPlatformStatus());
    if (err || !res) return Promise.reject();
    this.setLiveSuspendStatus(res.live_is_off);
  }

  @Action
  async getIsPrematchSuspended() {
    const [err, res] = await to(platformService.getPlatformStatus());
    if (err || !res) return Promise.reject();
    this.setPrematchSuspendStatus(res.prematch_is_off);
  }

  @Action
  async toggleSuspendState() {
    const [err] = await to(platformService.toggleSuspendState(!this.isLiveSuspended));
    if (err) return Promise.reject();
  }

  @Action
  async togglePrematchOffer() {
    const [err] = await to(platformService.togglePrematchOffer(!this.isPrematchSuspended));
    if (err) return Promise.reject();
  }
}

export const PlatformStore = getModule(Platform);
