var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.onSubmit}},[_c('a-row',{attrs:{"gutter":24}},_vm._l((_vm.templates),function(template){return _c('a-col',{key:template.key,attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":template.label}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            template.key,
            {
              initialValue: _vm.getInitialValue(template.eventObjectKey),
              rules: [
                {
                  required: true,
                  message: _vm.$t('common.requiredValidationError'),
                } ],
            } ]),expression:"[\n            template.key,\n            {\n              initialValue: getInitialValue(template.eventObjectKey),\n              rules: [\n                {\n                  required: true,\n                  message: $t('common.requiredValidationError'),\n                },\n              ],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"options":template.options},nativeOn:{"click":function($event){return (function (e) { return e.stopPropagation(); }).apply(null, arguments)}}})],1)],1)}),1),_c('a-row',{attrs:{"type":"flex","justify":"end"}},[_c('a-button',{attrs:{"loading":_vm.isSaving,"disabled":_vm.isSaving,"html-type":"submit","type":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }