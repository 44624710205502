
import Component from 'vue-class-component';

import BaseEventCell from './BaseEventCell';

@Component
export default class EventActions extends BaseEventCell {
  get oddAutoChange() {
    return this.event?.tech === 'A' ? this.$t('event.auto') : this.$t('event.manual');
  }

  get eventPrematchProvider() {
    return this.event?.prematchProvider;
  }

  get eventLiveProvider() {
    return this.event?.liveProvider;
  }

  get actions() {
    return [
      {
        key: 'automatic_odds_change',
        label: this.$t('event.tech'),
        defaultValue: this.oddAutoChange,
      },
      {
        key: 'prematch',
        label: this.$t('common.prematch'),
        defaultValue: this.eventPrematchProvider || this.$t('common.notAvailable'),
        class: this.eventPrematchProvider && 'betradar-select', // in future make getter func for different select color
      },
      {
        key: 'live',
        label: this.$t('common.live'),
        defaultValue: this.eventLiveProvider || this.$t('common.notAvailable'),
        class: this.eventLiveProvider && 'betradar-select',
      },
    ];
  }
}
