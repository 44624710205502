
import Vue from 'vue';
import { Prop, Component, Watch } from 'vue-property-decorator';
import Inputmask from 'inputmask/dist/inputmask';

@Component
export default class Editable extends Vue {
  $el!: any;
  showPopConfirm = false;
  tempValue = '';
  realValue = '';

  @Prop({ required: true })
  content!: string;

  @Prop({ default: 99 })
  limitMax!: number;

  @Prop({ default: 0 })
  limitMin!: number;

  @Prop({ default: 2 })
  floatDigits!: number;

  @Prop({ default: false })
  allowMinus!: boolean;

  @Prop({ default: 'odd.doYouWantToChange' })
  title!: string;

  @Prop({ default: false })
  preventOneAndLess!: boolean;

  mounted() {
    this.$el.innerText = this.content;
    Inputmask('numeric', {
      radixPoint: '.',
      placeholder: '0',
      autoGroup: !0,
      digits: this.floatDigits,
      digitsOptional: !1,
      max: this.limitMax,
      min: this.limitMin,
      allowPlus: false,
      allowMinus: this.allowMinus,
      rightAlign: false,
    }).mask(this.$el);
  }

  @Watch('content')
  onWatch(value: any) {
    this.$el.inputmask.setValue(value);
    this.$el.innerText = value;
    this.realValue = value;
  }

  destroyed() {
    this.$el.inputmask.remove();
  }

  onOk() {
    this.showPopConfirm = false;
    if (Number(this.tempValue) < 1 && this.preventOneAndLess) {
      this.tempValue = '1';
    }
    this.realValue = this.tempValue;
    this.$emit('change', this.tempValue);
  }

  onCancel() {
    this.showPopConfirm = false;
    this.$el.inputmask.setValue(this.realValue || this.content);
    this.tempValue = '';
  }

  onWarning() {
    this.$notification.warning({
      message: this.$t('common.warning') as string,
      description: this.$t('common.lessThenOne') as string,
    });
  }

  onKeyPress(event: KeyboardEvent) {
    const input = event.target as HTMLElement;
    const isEnter = event.key === 'Enter';

    if (isEnter) {
      event.preventDefault();
      event.stopPropagation();
      this.confirmChange(input.innerText);
      return;
    }
  }

  confirmChange(tempValue: string) {
    this.tempValue = tempValue;
    if (this.tempValue === '0.00') return;
    if (this.realValue == this.tempValue) return;
    this.showPopConfirm = true;
  }

  onBlur(event: KeyboardEvent) {
    const input = event.target as HTMLElement;
    if (Number(input.innerText) < 1 && this.preventOneAndLess) {
      this.onWarning();
    }
    if (this.showPopConfirm) return;
    this.onCancel();
  }
}
