
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class LiveMaxBetAction extends Vue {
  form!: WrappedFormUtils;
  isSavingSc = false;
  isSavingWeb = false;

  @Prop()
  mode!: 'day' | 'night';

  @Prop({ required: true })
  webMaxBet!: number;

  @Prop({ required: true })
  scMaxBet!: number;

  @Prop({ required: true })
  onScMaxBet!: any;

  @Prop({ required: true })
  onWebMaxBet!: any;

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  getValue(key: string) {
    return this.form.getFieldValue(key);
  }

  get isEdited() {
    return this.webMaxBet || this.scMaxBet;
  }

  async onWebClick() {
    const value = this.getValue('webMaxBet');
    this.isSavingWeb = true;
    await this.onWebMaxBet(value);
    this.form = this.$form.createForm(this);
    this.isSavingWeb = false;
  }

  async onScClick() {
    const value = this.getValue('scMaxBet');
    this.isSavingSc = true;
    await this.onScMaxBet(value);
    this.form = this.$form.createForm(this);
    this.isSavingSc = false;
  }
}
