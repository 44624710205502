
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import to from 'await-to-js';

import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import SelectedEvents from './SelectedEvents.vue';
import { mapForSelect } from '@/lib';
import { ApprovalStore } from '@/modules/approval';
import { OutcomeStore } from '@/modules/outcome';
import { MarketStore } from '@/modules/market/market.store';
import { EventStore } from '@/modules/event/event.store';
import { ChangeTemplateRequestType, eventService } from '@/modules/event';
import { PlayerEvent, PlayerStore } from '@/modules/player';
import { FactoryEvent } from '@/modules/event/event.types';

@Component({ name: 'ChangeTemplatesModal', components: { SelectedEvents } })
export default class ChangeTemplatesModal extends Vue {
  @Prop({ required: true, type: Boolean })
  visible!: boolean;

  @Prop({ required: false, default: 'ALL', type: String })
  templatesType!: ChangeTemplateRequestType;

  @Prop({ required: true })
  close!: () => void;

  isSaving = false;
  previousId = 0;
  form!: WrappedFormUtils;

  get liveTemplates() {
    return [
      {
        eventObjectKey: 'liveOutcomeTemplateId',
        key: 'live_outcome_template_id',
        label: this.$t('outcome.liveTemplate'),
        options: this.outcomesLiveTemplates,
      },
      {
        eventObjectKey: 'liveMarketTemplateId',
        key: 'live_market_template_id',
        label: this.$t('market.liveTemplate'),
        options: this.marketLiveTemplates,
      },
      {
        eventObjectKey: 'liveApprovalTemplateId',
        key: 'live_approval_template_id',
        label: this.$t('approval.liveTemplate'),
        options: this.approvalLiveTemplates,
      },
    ];
  }

  get prematchTemplates() {
    return [
      {
        eventObjectKey: 'prematchOutcomeTemplateId',
        key: 'prematch_outcome_template_id',
        label: this.$t('outcome.outcome'),
        options: this.outcomesTemplates,
      },
      {
        eventObjectKey: 'prematchMarketTemplateId',
        key: 'prematch_market_template_id',
        label: this.$t('market.market'),
        options: this.marketPrematchTemplates,
      },
      {
        eventObjectKey: 'prematchApprovalTemplateId',
        key: 'prematch_approval_template_id',
        label: this.$t('approval.approval'),
        options: this.approvalTemplates,
      },
    ];
  }

  get playerTemplates() {
    return [
      {
        eventObjectKey: 'marketTemplateId',
        key: 'player_market_template_id',
        label: this.$t('player.player'),
        options: this.playerMarketTemplates,
      },
      {
        eventObjectKey: 'approvalTemplateId',
        key: 'player_approval_template_id',
        label: this.$t('approval.approval'),
        options: this.approvalTemplates,
      },
    ];
  }

  get allTemplates() {
    return eventService.mapTemplateInputs(this.liveTemplates, this.prematchTemplates);
  }

  get templates() {
    switch (this.templatesType) {
      case 'LIVE':
        return this.liveTemplates;
      case 'PREMATCH':
        return this.prematchTemplates;
      case 'PLAYER':
        return this.playerTemplates;
      default:
        return this.allTemplates;
      // extend later on with antepost
    }
  }

  get store() {
    switch (this.templatesType) {
      case 'LIVE':
        return EventStore;
      case 'PREMATCH':
        return EventStore;
      case 'PLAYER':
        return PlayerStore;
      default:
        return EventStore;
    }
  }

  get isSingleSelection() {
    return this.store.selectedEvents.length === 1;
  }

  get selectedEvent() {
    if (this.isSingleSelection) return this.store.selectedEvents[0];
    return null;
  }

  get sportId() {
    return this.store.selectedSportId ?? 0;
  }

  get approvalTemplates() {
    return mapForSelect(ApprovalStore.sportPrematchTemplates(this.sportId));
  }

  get approvalLiveTemplates() {
    return mapForSelect(ApprovalStore.sportLiveTemplates(this.sportId));
  }

  get outcomesTemplates() {
    return mapForSelect(OutcomeStore.sportPrematchTemplates(this.sportId));
  }

  get outcomesLiveTemplates() {
    return mapForSelect(OutcomeStore.sportLiveTemplates(this.sportId));
  }

  get marketLiveTemplates() {
    return mapForSelect(MarketStore.sportTemplates(this.sportId, 'LIVE'));
  }

  get marketPrematchTemplates() {
    return mapForSelect(MarketStore.sportTemplates(this.sportId, 'PREMATCH'));
  }

  get playerMarketTemplates() {
    return mapForSelect(MarketStore.sportTemplates(this.sportId, 'PLAYER'));
  }

  get inputSpan() {
    return ['LIVE', 'PREMATCH', 'PLAYER'].includes(this.templatesType) ? 24 : 12;
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  getInitialValue(templateValueKey: string) {
    if (!this.isSingleSelection) return '';
    return (this.selectedEvent as any)[templateValueKey];
  }

  getInitialCommonValue(templateValueKey: string) {
    let firstId = 0;
    const allEqual = (arr: FactoryEvent[] | PlayerEvent[]) =>
      arr.some((event: FactoryEvent | PlayerEvent, index: number) => {
        if (index === 0) {
          firstId = (event as any)[templateValueKey];
        }
        return (event as any)[templateValueKey] !== firstId;
      });
    if (allEqual(this.store.selectedEvents)) return '';
    return firstId;
  }

  async handleOk() {
    const [formError] = await to(this.form.validateFields() as any);
    if (formError) return;
    this.isSaving = true;
    const formData = this.form.getFieldsValue() as any;
    const [err] = await to(
      this.store.bulkChangeTemplates({ formData, templatesType: this.templatesType })
    );
    this.isSaving = false;
    if (err) {
      this.$notification.error({
        message: this.$t('common.error') as string,
        description: this.$t('common.somethingWentWrong') as string,
      });
      return;
    }
    this.$emit('change');
    this.close();
    this.form.resetFields();
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('event.templatesSaved') as string,
    });
  }
}
