import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import to from 'await-to-js';
import Vue from 'vue';

import store from '@/store';
import { outcomeService } from './outcome.service';
import { NewTemplateAttributes, OutcomeTemplate } from './outcome.types';

@Module({ dynamic: true, store, name: 'outcome', namespaced: true })
class Outcome extends VuexModule {
  templates: OutcomeTemplate[] = [];
  marketOutcomes: any = {};

  get sportLiveTemplates() {
    return (sportId: number) => {
      return this.templates.filter(t => t.sport === sportId && t.template_type === 'LIVE');
    };
  }

  get sportPrematchTemplates() {
    return (sportId: number) => {
      return this.templates.filter(t => t.sport === sportId && t.template_type === 'PREMATCH');
    };
  }

  get singleMarketOutcomes() {
    return (marketId: number) => this.marketOutcomes[marketId] || [];
  }

  @Mutation
  private setTemplates(templates?: any[]) {
    this.templates = templates ?? [];
  }

  @Mutation
  private removeTemplate(id: number) {
    this.templates = this.templates.filter((t: any) => t.id !== id);
  }

  @Mutation
  private addTemplate(template: OutcomeTemplate) {
    this.templates.push(template);
  }

  @Mutation
  newTemplate(attributes: NewTemplateAttributes) {
    const newTemplate = outcomeService.newOutcomeTemplate(attributes);
    const isSameTemplate = this.templates.some(template => template.id === newTemplate.id);
    if (isSameTemplate) return;
    this.templates.push(newTemplate);
  }

  @Mutation
  setMarketOutcomes({ marketId, res }: any) {
    const outcomes = res.reduce((acc: any, market: any) => {
      return [...acc, ...market.outcomes];
    }, []);
    Vue.set(this.marketOutcomes, marketId, outcomes);
  }

  @Action
  async getTemplates() {
    const [err, res] = await to<any>(outcomeService.getTemplates());
    if (!err) {
      this.setTemplates(res.results);
    }
  }

  @Action
  async getMarketOutcomes(marketId: number) {
    const [err, res] = await to<any>(outcomeService.getMarketOutcomes(marketId));
    if (err) return Promise.reject(err);
    this.setMarketOutcomes({ marketId, res });
  }

  @Action
  async saveTemplate(template: OutcomeTemplate) {
    const [error] = await to(outcomeService.saveTemplate(template));
    if (!error) {
      this.getTemplates();
      return Promise.resolve();
    }
    return Promise.reject(error);
  }

  @Action
  async deleteTemplate(id: number) {
    const [error] = await to(outcomeService.deleteTemplate(id));
    if (!error) {
      this.removeTemplate(id);
      return Promise.resolve();
    }
    return Promise.reject(error);
  }

  @Action
  cloneTemplate(id: number) {
    const template = this.templates.find(t => t.id === id);
    if (!template) return;
    const newTemplate = {
      ...template,
      id: -1,
      name: `${template.name} Copy`,
      is_default: false,
    } as OutcomeTemplate;
    this.addTemplate(newTemplate);
  }

  @Action
  removeUnsavedTemplates() {
    this.removeTemplate(-1);
  }
}

export const OutcomeStore = getModule(Outcome);
