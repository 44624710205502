
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

import randomizeString from '@/lib/randomString';

@Component
export default class InputConfirm extends Vue {
  @Prop({ type: String, required: true })
  title!: string;

  @Prop()
  position!: string;

  confirmationString = '';
  isPopoverVisible = false;
  confirmationInputValue = '';

  get isConfirmDisabled(): boolean {
    return this.confirmationInputValue !== this.confirmationString;
  }

  onClick() {
    this.confirmationString = randomizeString(5);
    this.isPopoverVisible = true;
    this.confirmationInputValue = '';
  }

  onOk() {
    this.$emit('confirm');
    this.isPopoverVisible = false;
  }

  onCancel() {
    this.isPopoverVisible = false;
    this.confirmationInputValue = '';
  }

  onConfirmationInputChange(event: any) {
    this.confirmationInputValue = event.target.value;
  }
}
