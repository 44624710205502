
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICellRendererParams } from '@ag-grid-community/core';

import { EventStore } from '@/modules/event/event.store';
import { PlayerStore } from '@/modules/player';
import { AntepostStore } from '@/modules/antepost';
import { MarketStore } from '@/modules/market';

interface EditMarketsParams extends ICellRendererParams {
  eventType: 'DUAL' | 'PLAYER' | 'ANTEPOST';
}

@Component
export default class EditMarkets extends Vue {
  params: EditMarketsParams | undefined;

  get hasData() {
    return !!this.params?.data;
  }

  get eventType() {
    return this.params?.eventType;
  }

  get active() {
    return this.store.active;
  }

  get store() {
    switch (this.eventType) {
      case 'PLAYER':
        return PlayerStore;
      case 'ANTEPOST':
        return AntepostStore;
      default:
        return PlayerStore;
    }
  }

  onClick() {
    if (this.eventType === 'DUAL') {
      EventStore.setActiveEvent(this.params?.data.intKey);
      MarketStore.setActiveEvent(this.params?.data.intKey);
    } else {
      // @ts-ignore-next-line
      this.store.setActiveEvent(this.params?.data.intKey);
    }
  }
}
