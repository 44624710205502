import { SportCode } from '@/modules/sport/sport.contants';
import { SportModel, SportModelDefault, SportModels } from '@/modules/event/event.types';

export const SPORT_MODELS: SportModels = {
  [SportCode.FOOTBALL]: [1, 2, 3, 4],
  [SportCode.BASKETBALL]: [1, 2, 3, 4],
  [SportCode.HOCKEY]: [1, 2, 3, 4, 5],
  [SportCode.HANDBALL]: [1, 2],
};

export const MODEL_VALUES: SportModel = {
  1: 'Model 1',
  2: 'Model 2',
  3: 'Model 3',
  4: 'Model 4',
  5: 'Model 5',
};

export const MODEL_DEFAULT_VALUES: SportModelDefault = {
  [SportCode.FOOTBALL]: 4,
  [SportCode.BASKETBALL]: 1,
  [SportCode.HOCKEY]: 2,
  [SportCode.HANDBALL]: 1,
};
