import { EventRequestSource, EventState, OperatorNote, BaseMarketMap } from '../event';
import { SportCode } from '../sport';

interface PlayerNote {
  created_at: string;
  note: string;
  operator_name: string;
  user_id: string;
}
export interface PlayerCompetitors {
  competitor_id: number;
  is_base: boolean;
  name?: string;
}

export interface NewPlayer {
  name: string;
  competitors: PlayerCompetitors[];
}

export interface EditPlayersPayload extends NewPlayer {
  id: string;
}
export interface InputParams {
  input_parameters: {
    exp_points: number;
    kvota_under: number;
  };
  operator_note: PlayerNote[];
  prematch_max_amount: number;
}
export interface GetPlayerMarketsBySportParams {
  sport_id: number;
}

export interface TemplateData {
  name: string;
}

export interface Player {
  id: string;
  name: string;
  competitor_name: string;
  player_name: string;
  event_name: string;
  note: string;
  operator_notes: string[];
  landbase_code: number | null;
  state: string;
  market_template_id: number | null; //number only in future
  approval_template_id: number;
  market_template_data: TemplateData;
  approval_template_data: TemplateData;
}

interface SingleCompetitionFormData {
  id: number;
  value: string;
  sportId: number;
}

interface CompetitionsFormData {
  alt1?: SingleCompetitionFormData;
  alt2?: SingleCompetitionFormData;
}

interface SinglePlayerFormData {
  name: string;
  competition: CompetitionsFormData;
}

export interface PlayerFormData {
  [key: string]: SinglePlayerFormData;
}

export interface PlayerEvent {
  intKey: number;
  approvalTemplateId: number;
  baseMarkets: BaseMarketMap[]; // This is caclutated from baseMarketsMap
  baseMarketsMap: any;
  competitorName: string;
  competitionName: string;
  competitionId: number;
  eventIntKey: number;
  eventName: number;
  eventPublished: boolean;
  hasSlip: boolean;
  isSuspended: boolean;
  landbaseCode: null | number;
  locationName: string;
  marketTemplateId: number;
  name: string;
  note: string;
  operatorNotesList: OperatorNote[];
  playerName: string;
  prematchMaxAmount: number;
  sportCode: SportCode;
  sportId: number;
  start: string;
  state: EventState;
  inputParameters: any;
  canPublish: boolean;
  canValidate: boolean;
}

export interface OnPlayerMarketWs {
  code: null | string;
  e_i_k: number;
  odds: PlayerOdd[];
  base_odds: PlayerOdd[];
  u: boolean;
}

export interface PlayerOdd {
  i_k: string; //int_key
  i_s: boolean; //is_suspended
  m: number; // marketid
  o: number; // outcomeid
  l: number | null; // limit
  p_v: string | number | null; // prod_value
  r_v: string | number | null; // real value
  m_c: boolean; //manual changed
  m_n: string; // market_name
  o_n: string; // outcome_name
  b?: boolean; // is base market
  p_m_a: number;
}
export interface PlayerEventsMap {
  [key: string]: PlayerEvent;
}

export interface PlayerTemplateMessage {
  approval_template_id: number;
  event_players: number[];
  market_template_id: number;
}

export interface PlayerEventListResponse {
  results: PlayerEvent[];
  count: number;
}

export interface AddPlayerOperatorNote {
  event_player_id: number;
  note: string;
  source: EventRequestSource;
}

export interface EditPlayerOdd {
  outcome_player_id: number;
  odd_value: number;
  limit: number;
}

export type EditPlayerOddsPayload = EditPlayerOdd[];

export interface UpdatePlayerEventTemplate {
  player_market_template_id: number;
  player_approval_template_id: number;
  source: EventRequestSource;
}

export interface BulkChangePlayerTemplates {
  event_players: (number | null)[];
  template: {
    player_market_template_id: number;
    player_approval_template_id: number;
  };
  source: EventRequestSource;
}

export interface GeneratePlayerMarketOdds {
  exp_points: number;
  kvota_under: number;
  market_id: number;
}

export interface GeneratedPlayerOdd {
  outcome_over_id: number;
  outcome_under_id: number;
  odd_value_over: number;
  odd_value_under: number;
}

export interface GeneratedOddsResponse {
  [x: string]: {
    [limit: string]: GeneratedPlayerOdd;
  };
}

export const isPlayer = (object: any): object is PlayerEvent => {
  return 'playerName' in object;
};

export interface LimitOddPayload {
  oddId: number;
  oddInt: number;
  value: number;
  eventId?: number | null;
  limit: number;
}
