import { api } from '@/lib/api';
import { PlatformStatusResponse } from './platform.types';

const getPlatformStatus = () => {
  return api.get('odds/platform/check-platform-off/') as Promise<PlatformStatusResponse>;
};

const toggleSuspendState = (isSuspended: boolean) => {
  const turnOff = () => api.put('/odds/platform/live-off/');
  const turnOn = () => api.put('/odds/platform/live-on/');
  return isSuspended ? turnOff() : turnOn();
};

const togglePrematchOffer = (isSuspended: boolean) => {
  const turnOff = () => api.put('/odds/platform/prematch-off/');
  const turnOn = () => api.put('/odds/platform/prematch-on/');
  return isSuspended ? turnOff() : turnOn();
};

export const platformRepo = {
  getPlatformStatus,
  togglePrematchOffer,
  toggleSuspendState,
};
