import { SportCode } from '@/modules/sport';

const calculateProdRealRatio = (prodValue: number, realValue: number) => {
  return (1 - prodValue / realValue) * 100;
};

const totalOddAlertLvl1 = (prodValue: number, realValue: number) => {
  if (!realValue) return false;
  const ratio = calculateProdRealRatio(prodValue, realValue);
  return ratio < 0 ? ratio : false;
};

const handicapOddAlertLvl1 = (prodValue: number, realValue: number) => {
  if (!realValue) return false;
  const ratio = calculateProdRealRatio(prodValue, realValue);
  return ratio < 0.05 ? ratio : false;
};

const oddAlertLvl2 = (prodValue: number, realValue: number) => {
  if (!realValue) return false;
  const ratio = calculateProdRealRatio(prodValue, realValue);
  return ratio <= -100 ? ratio : false;
};

const inputParamsAlertLvl2 = (operatorValue: number, providerValue: number) => {
  if (!providerValue) return false;
  const ratio = calculateProdRealRatio(operatorValue, providerValue);
  return ratio < -5 ? ratio : false;
};

const isHalfStep = (limit: number) => {
  return Number.isInteger(limit) || limit % 0.5 !== 0;
};

const isPositive = (param: number) => {
  return param < 0;
};

const limitCompareByPeriods = (limit: number, limitToCompare: number) => {
  if (!limit || !limitToCompare) return false;
  return Number(limit.toFixed(1)) > limitToCompare;
};

const handicapLimitCompareByPeriod = (handicap: number, handicapToCompare: number) => {
  if (!handicap || !handicapToCompare) return false;
  return Math.abs(Number(handicap.toFixed(1))) > Math.abs(handicapToCompare);
};

const changeDifference = (operatorValue: number, providerValue: number) => {
  if (!providerValue) return false;
  return Number(operatorValue.toFixed(1)) !== providerValue;
};

const twoStepChangeDifference = (operatorValue: number, providerValue: number) => {
  if (!providerValue) return false;
  return Math.abs(Number(operatorValue.toFixed(1)) - providerValue) >= 2;
};

const threeStepChangeDifference = (operatorValue: number, providerValue: number) => {
  if (!providerValue) return false;
  return Math.abs(Number(operatorValue.toFixed(1)) - providerValue) >= 3;
};

const handicapSignCompare = (handicaps: number[]) => {
  const removedEmptyHandicapFields = handicaps.filter(handicap => !!handicap);
  const signToCompare = Math.sign(removedEmptyHandicapFields[0]);
  return !removedEmptyHandicapFields.every(handicap => Math.sign(handicap) === signToCompare);
};

const INPUT_PARAM_CHECKS_LVL1: { [key: string]: any } = {
  // funcName is added because of compailing code on production env, func names are removed on production build, so we added names
  total_limit_operator: [{ func: changeDifference, funcName: 'changeDifference' }],
  handicap_limit_operator: [{ func: changeDifference, funcName: 'changeDifference' }],
  total_under_operator: [
    { func: isPositive, funcName: 'isPositive' },
    { func: totalOddAlertLvl1, funcName: 'totalOddAlertLvl1' },
  ],
  handicap_favorite_operator: [{ func: handicapOddAlertLvl1, funcName: 'handicapOddAlertLvl1' }],
  draw_operator: [{ func: totalOddAlertLvl1, funcName: 'totalOddAlertLvl1' }],
};

const INPUT_PARAM_CHECKS_LVL1_BASKETBALL: { [key: string]: any } = {
  total_ft_limit_operator: [
    { func: threeStepChangeDifference, funcName: 'threeStepChangeDifference' },
  ],
  total_1q_limit_operator: [{ func: twoStepChangeDifference, funcName: 'twoStepChangeDifference' }],
  total_ht_limit_operator: [{ func: twoStepChangeDifference, funcName: 'twoStepChangeDifference' }],
  handicap_ft_limit_operator: [
    { func: twoStepChangeDifference, funcName: 'twoStepChangeDifference' },
  ],
  handicap_ht_limit_operator: [
    { func: twoStepChangeDifference, funcName: 'twoStepChangeDifference' },
  ],
  handicap_1q_limit_operator: [
    { func: twoStepChangeDifference, funcName: 'twoStepChangeDifference' },
  ],
};

const INPUT_PARAM_CHECKS_LVL1_HANDBALL: { [key: string]: any } = {
  total_ft_limit_operator: [{ func: twoStepChangeDifference, funcName: 'twoStepChangeDifference' }],
  total_ht_limit_operator: [{ func: twoStepChangeDifference, funcName: 'twoStepChangeDifference' }],
  handicap_ft_limit_operator: [
    { func: twoStepChangeDifference, funcName: 'twoStepChangeDifference' },
  ],
  handicap_ht_limit_operator: [
    { func: twoStepChangeDifference, funcName: 'twoStepChangeDifference' },
  ],
};

const INPUT_PARAM_CHECKS_LVL1_HOCKEY: { [key: string]: any } = {
  total_ft_limit_operator: [{ func: changeDifference, funcName: 'changeDifference' }],
  total_1st_period_limit_operator: [{ func: changeDifference, funcName: 'changeDifference' }],
  handicap_ft_limit_operator: [{ func: changeDifference, funcName: 'changeDifference' }],
  handicap_1st_period_limit_operator: [{ func: changeDifference, funcName: 'changeDifference' }],
};

const INPUT_PARAM_CHECKS_TENNIS: { [key: string]: any } = {
  winner_favorite_operator_home: [{ func: totalOddAlertLvl1, funcName: 'totalOddAlertLvl1' }],
  winner_favorite_operator_away: [{ func: totalOddAlertLvl1, funcName: 'totalOddAlertLvl1' }],
  total_games_limit_operator: [
    { func: twoStepChangeDifference, funcName: 'twoStepChangeDifference' },
  ],
  total_games_over_operator: [{ func: totalOddAlertLvl1, funcName: 'totalOddAlertLvl1' }],
};

const INPUT_PARAM_CHECKS_LVL2: { [key: string]: any } = {
  total_under_operator: [{ func: inputParamsAlertLvl2, funcName: 'inputParamsAlertLvl2' }],
  handicap_favorite_operator: [{ func: inputParamsAlertLvl2, funcName: 'inputParamsAlertLvl2' }],
  draw_operator: [{ func: inputParamsAlertLvl2, funcName: 'inputParamsAlertLvl2' }],
};

const INPUT_PARAM_VALIDATORS: { [key: string]: any } = {
  total_limit_operator: [
    { func: isPositive, funcName: 'isPositive' },
    { func: isHalfStep, funcName: 'isHalfStep' },
  ],
  total_under_operator: [{ func: isPositive, funcName: 'isPositive' }],
  handicap_limit_operator: [{ func: isHalfStep, funcName: 'isHalfStep' }],
};

const INPUT_PARAM_VALIDATORS_BASKETBALL: { [key: string]: any } = {
  total_ft_limit_operator: [
    { func: isHalfStep, funcName: 'isHalfStep' },
    { func: isPositive, funcName: 'isPositive' },
    { func: limitCompareByPeriods, funcName: 'limitCompareByPeriods' },
  ],
  total_1q_limit_operator: [
    { func: isHalfStep, funcName: 'isHalfStep' },
    { func: isPositive, funcName: 'isPositive' },
    { func: limitCompareByPeriods, funcName: 'limitCompareByPeriods' },
  ],
  total_ht_limit_operator: [
    { func: isHalfStep, funcName: 'isHalfStep' },
    { func: isPositive, funcName: 'isPositive' },
    { func: limitCompareByPeriods, funcName: 'limitCompareByPeriods' },
  ],
  handicap_ft_limit_operator: [
    { func: isHalfStep, funcName: 'isHalfStep' },
    { func: handicapSignCompare, funcName: 'handicapSignCompare' },
    { func: handicapLimitCompareByPeriod, funcName: 'handicapLimitCompareByPeriod' },
  ],
  handicap_ht_limit_operator: [
    { func: isHalfStep, funcName: 'isHalfStep' },
    { func: handicapSignCompare, funcName: 'handicapSignCompare' },
    { func: handicapLimitCompareByPeriod, funcName: 'handicapLimitCompareByPeriod' },
  ],
  handicap_1q_limit_operator: [
    { func: isHalfStep, funcName: 'isHalfStep' },
    { func: handicapSignCompare, funcName: 'handicapSignCompare' },
    { func: handicapLimitCompareByPeriod, funcName: 'handicapLimitCompareByPeriod' },
  ],
};

const INPUT_PARAM_VALIDATORS_HANDBALL: { [key: string]: any } = {
  total_ft_limit_operator: [
    { func: isPositive, funcName: 'isPositive' },
    { func: limitCompareByPeriods, funcName: 'limitCompareByPeriods' },
  ],
  total_ht_limit_operator: [
    { func: isPositive, funcName: 'isPositive' },
    { func: limitCompareByPeriods, funcName: 'limitCompareByPeriods' },
  ],
  handicap_ft_limit_operator: [
    { func: handicapSignCompare, funcName: 'handicapSignCompare' },
    { func: handicapLimitCompareByPeriod, funcName: 'handicapLimitCompareByPeriod' },
  ],
  handicap_ht_limit_operator: [
    { func: handicapSignCompare, funcName: 'handicapSignCompare' },
    { func: handicapLimitCompareByPeriod, funcName: 'handicapLimitCompareByPeriod' },
  ],
};

const INPUT_PARAM_VALIDATORS_HOCKEY: { [key: string]: any } = {
  total_ft_limit_operator: [
    { func: isPositive, funcName: 'isPositive' },
    { func: limitCompareByPeriods, funcName: 'limitCompareByPeriods' },
  ],
  total_1st_period_limit: [
    { func: isPositive, funcName: 'isPositive' },
    { func: limitCompareByPeriods, funcName: 'limitCompareByPeriods' },
  ],
  handicap_ft_limit_operator: [{ func: handicapSignCompare, funcName: 'handicapSignCompare' }],
  handicap_1st_period_limit: [{ func: handicapSignCompare, funcName: 'handicapSignCompare' }],
};

const INPUT_PARAM_VALIDATORS_TENNIS: { [key: string]: any } = {
  winner_favorite_operator_home: [],
  winner_favorite_operator_away: [],
  total_games_limit_operator: [{ func: isPositive, funcName: 'isPositive' }],
  total_games_over_operator: [{ func: isPositive, funcName: 'isPositive' }],
};

const sportOddAlerts: { [key in SportCode]?: any } = {
  [SportCode.FOOTBALL]: {
    lvl1: totalOddAlertLvl1,
    lvl2: oddAlertLvl2,
  },
};

const sportInputParamAlerts: { [key in SportCode]?: any } = {
  [SportCode.FOOTBALL]: {
    lvl1: INPUT_PARAM_CHECKS_LVL1,
    lvl2: INPUT_PARAM_CHECKS_LVL2,
    validators: INPUT_PARAM_VALIDATORS,
  },
  [SportCode.BASKETBALL]: {
    lvl1: INPUT_PARAM_CHECKS_LVL1_BASKETBALL,
    validators: INPUT_PARAM_VALIDATORS_BASKETBALL,
  },
  [SportCode.HANDBALL]: {
    lvl1: INPUT_PARAM_CHECKS_LVL1_HANDBALL,
    validators: INPUT_PARAM_VALIDATORS_HANDBALL,
  },
  [SportCode.HOCKEY]: {
    lvl1: INPUT_PARAM_CHECKS_LVL1_HOCKEY,
    validators: INPUT_PARAM_VALIDATORS_HOCKEY,
  },
  [SportCode.TENNIS]: {
    lvl1: INPUT_PARAM_CHECKS_TENNIS,
    validators: INPUT_PARAM_VALIDATORS_TENNIS,
  },
};

export const inputParamAlerts = (
  sport: SportCode,
  type: 'lvl1' | 'lvl2' | 'validators',
  paramKey?: string
) => {
  return paramKey
    ? sportInputParamAlerts[sport][type][paramKey]
    : sportInputParamAlerts[sport][type];
};

export const oddAlerts = (sport: SportCode, type: 'lvl1' | 'lvl2') => {
  return sportOddAlerts[sport][type];
};
