
import Vue from 'vue';
import to from 'await-to-js';
import isNull from 'lodash/isNull';
import startCase from 'lodash/startCase';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import isEmpty from 'lodash/isEmpty';

import { Modals } from '@/modules/common';
import { Odd } from '@/ui/components/Odd/';
import { SportStore } from '@/modules/sport';
import { CommonStore } from '@/modules/common';
import { inputParamAlerts } from '@/lib/changeAlert';
import { EventStore } from '@/modules/event/event.store';
import { eventService } from '@/modules/event';
import { ChangeAlert } from '@/ui/components/ChangesAlert';
import { MarketStore } from '@/modules/market/market.store';
import { VALIDATOR_MESSAGES } from '@/modules/market/market.constants';
import {
  MODEL_VALUES,
  SPORT_MODELS,
  MODEL_DEFAULT_VALUES,
} from '@/modules/sport/sportModel.constatnts';

@Component({ components: { Odd, ChangeAlert } })
export default class PageName extends Vue {
  form!: WrappedFormUtils;
  initialValues: { [key: string]: any } = {};
  changeAlerts = inputParamAlerts;
  isValid = true;
  selectedModel = '';
  hasError = '';
  alertLevel = 1;
  alertValue = 0;
  inputParamsChanges = '';
  visibleAlert: { [key: string]: boolean } = {};
  confirmationString = '';

  @Prop({ required: false, default: false })
  isModalView!: boolean;

  get event() {
    return EventStore.active;
  }

  defaultSportModel() {
    if (!this.event) return null;
    if (!EventStore.defaultSportModel) {
      EventStore.setSportModel(MODEL_DEFAULT_VALUES[this.sport?.code]);
    }
    return (
      MODEL_VALUES[EventStore.defaultSportModel] ||
      MODEL_VALUES[MODEL_DEFAULT_VALUES[this.sport?.code]]
    );
  }

  get activeEventId() {
    return EventStore.activeId;
  }

  get sport() {
    if (!this.event) return null;
    return SportStore.getSportById(this.event.sportId);
  }

  get sportModels() {
    if (!this.event) return [null];
    return eventService.getModelsBySport(SPORT_MODELS[this.sport?.code], MODEL_VALUES);
  }

  get validatorMessages() {
    return (sportCode: string, alertName: string) => {
      return VALIDATOR_MESSAGES[sportCode][alertName];
    };
  }

  get inputParams() {
    if (!this.event) return [];
    return eventService.getInputParamsBySport(this.sport?.code);
  }

  get keys() {
    return Object.keys(EventStore.activeEventInputParams ?? {});
  }

  get isGeneratingOdds() {
    return EventStore.isGeneratingOdds;
  }

  get eventInputParams(): any {
    return { ...EventStore.activeEventInputParams, ...EventStore.tempInputParams.input_parameters };
  }

  get isEditDisabled() {
    return EventStore.isEventEditDisabled;
  }

  get activeTech() {
    return EventStore.active?.tech === 'A' ? true : false;
  }

  get isLoading() {
    return MarketStore.isLoading;
  }

  isEmpty(value: any) {
    return isEmpty(value);
  }

  created() {
    this.setAlertFileds();
  }

  setAlertFileds() {
    const visibleAlert = this.inputParams.reduce(
      (
        acc: { acc: { [key: string]: string[] } },
        param: { key: string; isReciprocal: boolean; required: boolean }
      ) => {
        return {
          ...acc,
          [`${param.key}_operator`]: false,
        };
      },
      {}
    );
    this.visibleAlert = visibleAlert;
  }

  getLabel(key: string) {
    return startCase(key);
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  onError(description = this.$t('genericError.description')) {
    this.$notification.warning({
      message: this.$t('common.error') as string,
      description: description as string,
    });
  }

  getOddFormat(probability: any) {
    if (probability) return (1 / probability).toFixed(3);
    return probability;
  }

  checkOtherFieldsValidity() {
    const { input_parameters }: any = this.form.getFieldsError();
    return Object.keys(input_parameters).filter(key => !!input_parameters[key]);
  }

  getParameter(
    paramType: 'provider' | 'operator',
    paramConfig: { key: string; isReciprocal: boolean }
  ) {
    if (this.eventInputParams === undefined) return '';
    const value = this.eventInputParams[`${paramConfig.key}_${paramType}`];
    if (isNull(value)) return paramType === 'provider' ? this.$t('common.notAvailable') : null;
    return paramConfig.isReciprocal ? this.getOddFormat(value) : value;
  }

  setSelectedModel(model: { key: string; value: number }) {
    this.selectedModel = model.key;
    EventStore.setSportModel(model.value);
    this.onSubmit();
  }

  async onSubmit() {
    if (!this.event) return;
    const formData = this.form.getFieldsValue() as any;
    const probabilityInputParams: any = eventService.parseInputParams(
      formData.input_parameters,
      this.sport?.code
    );
    const [err, response] = await to(EventStore.generateOddsByInputParams(probabilityInputParams));
    if (err) return;
    EventStore.setGeneratedOdds(response);
    if (!this.isModalView) {
      CommonStore.toggleModal(Modals.COMPARE_ODDS);
    }
  }

  async validateParams(rule: any, value: any, _callback: any) {
    const valueToCheck: any = Number(value);
    if (!valueToCheck) return;
    this.isValid = true;
    const paramKey = rule.field.slice(17); // get everything after 'input_parameters.' v-decorator prefix
    const feedParamKey = paramKey.substr(0, paramKey.lastIndexOf('_')) + '_provider';
    let feedValue = this.eventInputParams[feedParamKey];
    if (!feedValue && this.eventInputParams[paramKey]) {
      feedValue = this.eventInputParams[paramKey];
    }
    const isReciprocal = this.inputParams.find((param: any) => param.key === paramKey.slice(0, -9))
      .isReciprocal;

    if (isReciprocal && feedValue) {
      feedValue = Number((1 / feedValue).toFixed(3));
    }

    const validators = this.changeAlerts(this.sport?.code, 'validators', paramKey);
    const alerts = this.changeAlerts(this.sport?.code, 'lvl1', paramKey);

    if (validators) {
      validators.forEach((validator: any) => {
        if (validator.funcName === 'handicapLimitCompareByPeriod') {
          const totalHandicapLimit = Number(
            this.form.getFieldValue('input_parameters.handicap_ft_limit_operator')
          );
          const halfTimeHandicapLimit = Number(
            this.form.getFieldValue('input_parameters.handicap_ht_limit_operator')
          );
          const quarterOneHandicapLimit = Number(
            this.form.getFieldValue('input_parameters.handicap_1q_limit_operator')
          );

          const hasError = [
            validator.func(halfTimeHandicapLimit, totalHandicapLimit),
            validator.func(quarterOneHandicapLimit, halfTimeHandicapLimit),
            validator.func(quarterOneHandicapLimit, totalHandicapLimit),
          ];

          if (hasError.includes(true)) {
            this.inputParamsChanges = this.validatorMessages(
              this.sport?.code,
              'handicapLimitCompareByPeriod'
            );
            this.visibleAlert[paramKey] = true;
            this.isValid = false;
            throw new Error('Invalid param value');
          }
          return;
        }
        if (validator.funcName === 'limitCompareByPeriods') {
          const totalLimit = Number(
            this.form.getFieldValue('input_parameters.total_ft_limit_operator')
          );
          const halfTimeLimit = Number(
            this.form.getFieldValue('input_parameters.total_ht_limit_operator')
          );
          const quarterOneLimit = Number(
            this.form.getFieldValue('input_parameters.total_1q_limit_operator')
          );
          const firstPeriodLimit = Number(
            this.form.getFieldValue('input_parameters.total_1st_period_limit')
          );

          const hasError = [
            validator.func(halfTimeLimit, totalLimit),
            validator.func(quarterOneLimit, halfTimeLimit),
            validator.func(quarterOneLimit, totalLimit),
            validator.func(firstPeriodLimit, totalLimit),
          ];

          if (hasError.includes(true)) {
            this.inputParamsChanges = this.validatorMessages(
              this.sport?.code,
              'limitCompareByPeriods'
            );
            this.visibleAlert[paramKey] = true;
            this.isValid = false;
            throw new Error('Invalid param value');
          }
          return;
        }
        // if (validator.name === 'limitCompareByPeriods') return;
        if (validator.funcName === 'handicapSignCompare') {
          const arrayOfValues = [
            Number(this.form.getFieldValue('input_parameters.handicap_ft_limit_operator')),
            Number(this.form.getFieldValue('input_parameters.handicap_ht_limit_operator')),
            Number(this.form.getFieldValue('input_parameters.handicap_1q_limit_operator')),
          ];
          if (validator.func(arrayOfValues, feedValue)) {
            this.isValid = false;
            this.inputParamsChanges = this.validatorMessages(this.sport?.code, validator.funcName);
            this.visibleAlert[paramKey] = true;
            if (!this.isValid) {
              throw new Error('Invalid param value');
            }
          }
          return;
        }
        if (validator.func(valueToCheck, feedValue)) {
          this.isValid = false;
          this.inputParamsChanges = this.validatorMessages(this.sport?.code, validator.funcName);
          this.visibleAlert[paramKey] = true;
          if (!this.isValid) {
            throw new Error('Invalid param value');
          }
        } else {
          this.isValid = true;
        }
      });
      if (!this.isValid) {
        throw new Error('Invalid param value');
      }
    }
    if (alerts) {
      alerts.forEach((alert: any) => {
        if (alert.func(valueToCheck, feedValue)) {
          if (alert.funcName === 'totalOddAlertLvl1' || alert.funcName === 'handicapOddAlertLvl1') {
            const oddRatio = alert.func(valueToCheck, feedValue);
            this.alertValue = oddRatio;
            this.inputParamsChanges = `${this.validatorMessages(
              this.sport?.code,
              alert.funcName
            )} -- ${this.alertValue.toFixed(2)} %`;
            this.visibleAlert[paramKey] = true;
            return;
          }
          this.inputParamsChanges = this.validatorMessages(this.sport?.code, alert.funcName);
          this.visibleAlert[paramKey] = true;
        }
      });
    }
  }

  onOk(paramValue: any) {
    this.visibleAlert[`${paramValue.key}_operator`] = false;
  }

  onCancel(paramValue: any) {
    let prevInputValue = EventStore.activeEventInputParams[`${paramValue.key}_operator`];
    const isReciprocal = this.inputParams.find((param: any) => param.key === paramValue.key)
      .isReciprocal;
    if (isReciprocal) {
      prevInputValue = Number((1 / prevInputValue).toFixed(3));
    }
    this.form.setFieldsValue({ [`input_parameters.${paramValue.key}_operator`]: prevInputValue });
    this.visibleAlert[`${paramValue.key}_operator`] = false;
  }

  checkValidityOnBlur(paramValue: any) {
    this.form.validateFields([`input_parameters.${paramValue.key}_operator`]);

    const inputsWithError = this.checkOtherFieldsValidity();
    if (inputsWithError.length) {
      inputsWithError.forEach((inputFieldValue: string) => {
        this.form.validateFields([`input_parameters.${inputFieldValue}`]);
      });
    }
  }

  onEnter(paramValue: any) {
    this.form.validateFields([`input_parameters.${paramValue.key}_operator`]);
    const inputsWithError = this.checkOtherFieldsValidity();
    if (inputsWithError.length) {
      inputsWithError.forEach((inputFieldValue: string) => {
        this.form.validateFields([`input_parameters.${inputFieldValue}`]);
      });
    }
  }
}
