
import Vue from 'vue';
import { Prop, Component, Watch } from 'vue-property-decorator';

import { EventStore } from '@/modules/event/event.store';
import { EventTime } from '@/ui/components/EventTime';
import { MarketStore } from '@/modules/market/market.store';
import { PlayerStore } from '@/modules/player';
import { SportStore } from '@/modules/sport';
import { isLive } from '@/modules/event';
import * as disablePeriod from '@/lib/preventPastDateAndTime';

@Component({ components: { EventTime } })
export default class EventDetails extends Vue {
  @Prop()
  showSuspend!: boolean;

  @Prop({ default: 'DUAL', type: String })
  eventType!: 'DUAL' | 'PLAYER';

  get store() {
    switch (this.eventType) {
      case 'DUAL':
        return EventStore;
      case 'PLAYER':
        return PlayerStore;
      default:
        return EventStore;
    }
  }

  get sport() {
    if (!this.selectedEvent) return null;
    return SportStore.getSportById(this.selectedEvent.sportId);
  }

  get selectedEvent(): any {
    return this.store.active;
  }

  get isLoading() {
    return MarketStore.isLoading;
  }

  get isGeneratingOdds() {
    return this.store.isGeneratingOdds;
  }

  get competitionName() {
    if (!this.selectedEvent) return '';
    return this.selectedEvent.competition_name || this.selectedEvent.competitionName;
  }

  get sportName() {
    if (!this.selectedEvent) return '';
    return this.sport?.name;
  }

  get locationName() {
    if (!this.selectedEvent) return '';
    return this.selectedEvent.location_name || this.selectedEvent.locationName;
  }

  get eventName() {
    if (!this.selectedEvent) return '';
    return this.selectedEvent?.name;
  }

  get playerName() {
    if (!this.selectedEvent) return '';
    return this.selectedEvent.playerName;
  }

  close() {
    this.store.deselectEvent();
  }

  get isEditDisabled() {
    return this.store.isEventEditDisabled;
  }

  get disablePeriod() {
    return disablePeriod;
  }

  getSingleEventData() {
    if (this.eventType === 'PLAYER') return;
    const eventDataHandler = isLive(this.selectedEvent)
      ? EventStore.getSingleLiveEvent
      : EventStore.getSinglePrematchEvent;
    eventDataHandler();
  }

  mounted() {
    EventStore.setTennisHandicaps(null);
    this.getSingleEventData();
  }

  @Watch('selectedEvent')
  onActiveChange() {
    EventStore.setTennisHandicaps(null);
    this.getSingleEventData();
  }
}
