
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { DisplayOdd } from '@/ui/components/DisplayOdd';

@Component({
  components: { DisplayOdd },
})
export default class MarketsWithOdds extends Vue {
  @Prop({ required: true })
  markets!: any[];

  @Prop({ required: true })
  selectedEventId!: any;

  @Prop({ default: true })
  hasRealAndControlOdd!: boolean;

  @Prop({ default: '' })
  containerClass!: string;

  @Prop({ default: false })
  changedFlag!: boolean;

  @Prop({ default: false })
  suspendedFlag!: boolean;

  get marketWithName() {
    return this.markets.filter(m => m.name);
  }
}
