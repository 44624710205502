
import Component from 'vue-class-component';
import to from 'await-to-js';
import toMoment from '@/lib/dateToMoment';

import { EventStore } from '@/modules/event/event.store';
import Tape from '@/ui/components/Tape';
import { Mixins, Prop } from 'vue-property-decorator';
import { PlayerStore } from '@/modules/player';
import { NetworkStatus, WithNetworkStatus } from '@/ui/mixins/WithNetworkStatus';
import { DualEventApiType } from '@/modules/event';
import { NoteType } from '@/modules/event/event.types';

import { InputConfirm } from '@/ui/components/InputConfirm';

@Component({ components: { Tape, InputConfirm } })
export default class AdditionalInfo extends Mixins(WithNetworkStatus) {
  newGameNote = '';
  newOperatorNote = '';
  noteType!: NoteType;

  @Prop({ default: 'DUAL', type: String })
  eventType!: 'DUAL' | 'PLAYER';

  @Prop()
  isLive!: boolean;

  $refs!: {
    notes: any;
  };

  get store() {
    switch (this.eventType) {
      case 'DUAL':
        return EventStore;
      case 'PLAYER':
        return PlayerStore;
      default:
        return EventStore;
    }
  }

  get selectedEvent(): any {
    return this.store.active;
  }

  get operatorNotes() {
    if (!this.selectedEvent) return [];
    return this.selectedEvent.operatorNotesList || this.selectedEvent.operator_notes;
  }

  get sortedNotes() {
    const notes = this.operatorNotes
      .map((item: any) => ({
        ...item,
        createdAtDate: toMoment(item.created_at || item.createdAt).unix(),
      }))
      .sort((a: any, b: any) => b.createdAtDate - a.createdAtDate);
    return notes;
  }

  get eventNote() {
    if (!this.selectedEvent) return '';
    return this.selectedEvent.note;
  }

  saveGameNote() {
    this.noteType = NoteType.GAME;
    const handler = this.eventType === 'DUAL' ? this.saveIntKeyGameNote : this.savePlayerGameNote;
    handler();
  }

  async savePlayerGameNote() {
    if (!this.selectedEvent) return;
    this.setNetworkStatus(NetworkStatus.InProgress);
    const [err] = await to(
      PlayerStore.updateNote({
        intKey: this.selectedEvent.intKey,
        note: this.newGameNote,
      })
    );
    this.setNetworkStatus(NetworkStatus.Success);
    if (err) return;
    this.onSucces();
  }

  async saveIntKeyGameNote() {
    if (!this.selectedEvent) return;
    this.setNetworkStatus(NetworkStatus.InProgress);
    const [err] = await to(
      EventStore.updateNote({
        intKey: this.selectedEvent.intKey,
        note: this.newGameNote,
        eventType:
          EventStore.requestSource === 'LIVE' ? DualEventApiType.live : DualEventApiType.prematch,
      })
    );
    this.setNetworkStatus(NetworkStatus.Success);
    if (err) return;
    this.onSucces();
  }

  mounted() {
    this.scrollToTheBottom();
  }

  scrollToTheBottom() {
    const el = this.$refs.notes;
    el.scrollTop = el.scrollHeight;
  }

  onSucces() {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.noteType
        ? (this.$t('event.operatorNoteSaved') as string)
        : (this.$t('event.gameNoteSaved') as string),
    });
  }

  async addPlayerOperatorNote() {
    if (!this.newOperatorNote) return;
    this.setNetworkStatus(NetworkStatus.InProgress);
    const [err] = await to(PlayerStore.addOperatorNote(this.newOperatorNote));
    this.setNetworkStatus(NetworkStatus.Success);
    if (err) return;
    this.onSucces();
    this.newOperatorNote = '';
    this.scrollToTheBottom();
  }
  async addDualOperatorNote() {
    if (!this.newOperatorNote) return;
    this.setNetworkStatus(NetworkStatus.InProgress);
    const [err] = await to(
      EventStore.addOperatorNote({
        note: this.newOperatorNote,
        eventType:
          EventStore.requestSource === 'LIVE' ? DualEventApiType.live : DualEventApiType.prematch,
      })
    );
    this.setNetworkStatus(NetworkStatus.Success);
    if (err) return;
    this.onSucces();
    this.newOperatorNote = '';
    this.scrollToTheBottom();
  }
  async addOperatorNote() {
    this.noteType = NoteType.OPERATOR;
    const handler =
      this.eventType === 'DUAL' ? this.addDualOperatorNote : this.addPlayerOperatorNote;
    handler();
  }
}
