var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":_vm.$t('event.bulkTemplateChange'),"visible":_vm.visible,"confirm-loading":_vm.isSaving},on:{"ok":_vm.handleOk,"cancel":_vm.close}},[_c('selected-events',{attrs:{"eventType":_vm.templatesType === 'PLAYER' ? 'PLAYER' : 'DUAL'}}),(_vm.visible)?_c('a-form',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['esc']),expression:"['esc']"}],attrs:{"form":_vm.form}},[_c('a-row',{attrs:{"gutter":24}},_vm._l((_vm.templates),function(template){return _c('a-col',{key:template.key,attrs:{"span":_vm.inputSpan}},[_c('a-form-item',{attrs:{"label":template.label}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              template.key,
              {
                initialValue: _vm.isSingleSelection
                  ? _vm.getInitialValue(template.eventObjectKey)
                  : _vm.getInitialCommonValue(template.eventObjectKey),
                rules: [
                  { required: _vm.isSingleSelection, message: _vm.$t('common.requiredValidationError') } ],
              } ]),expression:"[\n              template.key,\n              {\n                initialValue: isSingleSelection\n                  ? getInitialValue(template.eventObjectKey)\n                  : getInitialCommonValue(template.eventObjectKey),\n                rules: [\n                  { required: isSingleSelection, message: $t('common.requiredValidationError') },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"options":template.options},nativeOn:{"click":function($event){return (function (e) { return e.stopPropagation(); }).apply(null, arguments)}}})],1)],1)}),1)],1):_vm._e(),(!_vm.isSingleSelection)?_c('div',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.$t('event.changeTemplateEvent'))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }