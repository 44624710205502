
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

@Component({ name: 'ChangeAlert' })
export default class ChangeAlert extends Vue {
  @Prop({ required: true })
  isPopoverVisible!: boolean;
  @Prop({ required: true })
  alertLevel!: number;
  @Prop({ required: true })
  confirmationString!: string;
  @Prop({ required: true })
  onPopoverCancel!: (arg: any) => {};
  @Prop({ required: true })
  onOk!: (arg: any) => {};
  @Prop({ required: false, default: '' })
  oddAlertMessage!: string;
  @Prop({ required: false, default: 'Yes' })
  okButtonText!: string;
  @Prop({ required: false, default: true })
  showCancelButton!: boolean;
  @Prop({ required: true })
  popoverTitle!: string;
  @Prop({ required: false, default: 'topRight' })
  placement!: string;

  confirmationInputValue = '';

  get popYesDisabled(): boolean {
    if (this.alertLevel !== 2) return false;
    if (this.confirmationInputValue !== this.confirmationString) return true;
    return false;
  }

  onConfirmationInputChange(event: any) {
    this.confirmationInputValue = event.target.value;
  }

  keyAction({ srcKey }: any) {
    if (srcKey === 'save') {
      if (this.popYesDisabled) return;
      this.onOk(null);
    } else {
      this.onPopoverCancel(null);
    }
  }
}
