
import Vue from 'vue';
import { Prop } from 'vue/types/options';
import Component from 'vue-class-component';

import { SportFilterStore } from '@/modules/sportFilter/sportFilter.store';
import {
  ItemType,
  SportFilterItem as ISportFilterItem,
} from '@/modules/sportFilter/sportFilter.types';
import { CommonStore } from '@/modules/common';

const SportFilterItemProps = Vue.extend({
  props: {
    item: { type: Object as Prop<ISportFilterItem>, required: true },
    isLive: Boolean,
    maxLevel: Number,
    level: Number,
    haveLocation: Boolean,
  },
});

@Component({ name: 'SportFilterItem' })
export default class SportFilterItem extends SportFilterItemProps {
  get isExpanded() {
    return SportFilterStore.isExpanded(this.item.clientId);
  }

  get titleIcon() {
    return this.isExpanded ? 'down' : 'right';
  }

  get showExtandableArrow() {
    return this.maxLevel && this.level < this.maxLevel;
  }

  isType(type: ItemType) {
    return this.item.type === type;
  }

  get isLoading() {
    return SportFilterStore.isLoading;
  }

  get isDisabled() {
    return CommonStore.isFetching;
  }

  onSwitchClick(event: MouseEvent) {
    event.stopPropagation();
    SportFilterStore.toggleSelection({ item: this.item, mode: 'activate' });
  }

  onCheckboxClick(event: MouseEvent) {
    event.stopPropagation();
    SportFilterStore.toggleSelection({ item: this.item, mode: 'select' });
  }

  onComponentClick() {
    if (this.maxLevel === this.level) {
      SportFilterStore.toggleSelection({ item: this.item, mode: 'select' });
    } else {
      SportFilterStore.expandCollapse(this.item.clientId);
    }
  }
}
