
import Vue from 'vue';
import Component from 'vue-class-component';

import { SportFilterStore } from '@/modules/sportFilter/sportFilter.store';

@Component({ name: 'SportFilterToggleAllButtons' })
export default class SportFilterToggleAllButtons extends Vue {
  selectAll() {
    SportFilterStore.selectAll();
  }

  deselectAll() {
    SportFilterStore.deselectAll();
  }
}
