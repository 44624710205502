
import Component from 'vue-class-component';

import BaseEventCell from './BaseEventCell';

@Component
export default class EventCodeCell extends BaseEventCell {
  get landbaseCode() {
    return this.event ? this.event.landbaseCode : this.$t('common.notAvailable');
  }
}
