
import { Component } from 'vue-property-decorator';
import to from 'await-to-js';
import last from 'lodash/last';

import { getSportImage, getLocationImage } from '@/lib/image';
import BaseEventCell from './BaseEventCell';
import { EventTime } from '@/ui/components/EventTime';
import { EventStore } from '@/modules/event/event.store';
import { SuspendAction } from '@/ui/components/SuspendAction';
import { PrematchEvent } from '@/modules/event';
import { AvailableRoutes } from '@/ui/types/route.types';
import { SportStore } from '@/modules/sport';
import { formatOperatorNote } from '@/modules/common/common.service';

type CellType = 'EVENT-MANAGEMENT' | 'PREMATCH';

@Component({ components: { EventTime, SuspendAction } })
export default class FullEventName extends BaseEventCell<PrematchEvent> {
  sportImage = '';
  locationImage = '';

  get hasNote() {
    return this.event?.note;
  }

  get note() {
    if (!this.hasData) return '';
    return this.event?.note || this.$t('event.noNote');
  }

  get lastOperaterNote() {
    if (!this.event || !this.event.operatorNotesList) return '';
    const lastNote = last(this.event.operatorNotesList);
    if (!lastNote) return '';
    return formatOperatorNote(lastNote);
  }

  get isGoingTrough() {
    return this.event?.goesThrough ? true : false;
  }

  get showSuspend() {
    return this.params?.showSuspend;
  }

  get cellType(): CellType {
    return this.params?.cellType;
  }

  get isSuspended() {
    return this.event?.prematchSuspended;
  }

  get hasPlayers() {
    return this.params?.data[this.params.hasPlayers];
  }

  get playerTooltip() {
    return this.hasPlayers
      ? this.$t('player.eventHasPlayers')
      : this.$t('player.eventWithoutPlayers');
  }

  get sport() {
    if (!this.event) return null;
    return SportStore.getSportById(this.event.sportId);
  }

  mounted() {
    this.getImages();
  }

  async getImages() {
    if (!this.event) return;
    this.sportImage = await getSportImage(this.sport?.code);
    this.locationImage = await getLocationImage(this.event?.locationName);
  }

  openNoteModal(e: MouseEvent) {
    e.stopPropagation();
    this.params?.openModal(this.event?.intKey);
    this.$emit('openNoteModal', { id: this.event?.intKey });
  }

  toastEventUpdated() {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('event.updated') as string,
    });
  }

  async suspendEvent() {
    if (!this.event) return;
    const [err] = await to(EventStore.suspendEvent(this.event.intKey));
    if (err) return;
    this.toastEventUpdated();
  }

  openPlayerPage(e: any) {
    e.stopPropagation();
    if (!this.hasPlayers) return;
    const eventId = this.event?.intKey || '';
    const { prematchPlayer, playerEvents } = AvailableRoutes;
    const path = this.cellType === 'PREMATCH' ? prematchPlayer : playerEvents;
    this.$router.push({ path, query: { event_id: eventId as any } });
  }
}
