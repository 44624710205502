
import to from 'await-to-js';
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import isNull from 'lodash/isNull';

import { EventStore } from '@/modules/event/event.store';
import { OddValue } from '@/ui/components/OddValue';
import { MarketStore } from '@/modules/market';
import { Odd as IOdd } from '@/modules/market/market.types';
import { SuspendAction } from '@/ui/components/SuspendAction';
import { MaxBetAction } from '@/ui/components/MaxBetAction';
import { countDecimals } from '@/lib/countDecimals';
import { FeedStore, OddsMap } from '@/modules/feed';
import { oddAlerts } from '@/lib/changeAlert';
import randomizeString from '@/lib/randomString';
import ChangeAlert from '../ChangesAlert/ChangeAlert.vue';
import { SportCode } from '@/modules/sport';
import { LiveMaxBetAction } from '@/ui/components/LiveMaxBetAction';
import oddFilter from '@/ui/filters/odd';

@Component({ components: { OddValue, SuspendAction, MaxBetAction, ChangeAlert, LiveMaxBetAction } })
export default class Odd extends Vue {
  @Prop({ required: true })
  odd!: IOdd;

  @Prop({ required: true })
  eventIntKey!: number;

  @Prop({ required: false, default: true })
  hasCells!: boolean;

  @Prop({ required: false, type: Array })
  actions!: string[];

  @Prop({ required: false, type: Boolean })
  isLive!: boolean;

  @Prop({ required: false, type: Boolean })
  isMarketSuspended!: boolean;

  $refs!: {
    input: any;
  };

  showPopConfirm = false;
  tempValue: string | number = '';
  countDecimals = countDecimals;
  oddAlerts = oddAlerts;
  alertValue = 0;
  alertLevel = 0;
  confirmationString = '';

  get activeTech() {
    return EventStore.active?.tech === 'A' ? true : false;
  }

  get prodOddKey(): keyof typeof FeedStore {
    return this.isLive ? 'liveOdds' : 'odds';
  }

  get oddFromStore() {
    const odd = MarketStore.odds?.[this.odd.int_key];
    return odd || this.odd;
  }

  get oddsFromFeed() {
    return FeedStore[this.prodOddKey] as OddsMap;
  }

  get prodOdd() {
    const oddFromFeed = this.oddsFromFeed[this.oddFromStore.int_key];
    if (oddFromFeed) return oddFromFeed.p_v;
    return this.isLive ? this.oddFromStore.live_prod_odd : this.oddFromStore.prematch_prod_odd;
  }

  get disabledByProvider() {
    const odd = this.oddsFromFeed[this.odd.int_key];
    if (odd) {
      return odd.isdbp;
    }
    return false;
  }

  get scMaxBet() {
    return this.oddFromStore.live_max_amount_sc;
  }

  get webMaxBet() {
    return this.oddFromStore.live_max_amount_web;
  }

  get value() {
    return oddFilter((this.tempValue as string) || (this.prodOdd as string));
  }

  get oddMaxBetValue() {
    return this.oddFromStore.prematch_max_amount;
  }

  get showAction() {
    return (action: string) => {
      if (this.actions) {
        return this.actions.includes(action);
      }
      return false;
    };
  }

  get isManualChanged() {
    return (odd: IOdd) => {
      if (!MarketStore.odds) return false;
      const oddFromMarket = MarketStore.odds[odd.int_key];
      const oddFromFeed = this.oddsFromFeed[odd.int_key];
      if (oddFromFeed && oddFromFeed.m_c) return oddFromFeed.m_c;
      if (!oddFromMarket) return false;
      return oddFromMarket.manual_changed;
    };
  }

  get realOddInitialValue() {
    const oddFromFeed = this.oddsFromFeed[this.oddFromStore.int_key];
    if (oddFromFeed && oddFromFeed.r_v) return oddFromFeed.r_v;
    return this.isLive ? this.oddFromStore.live_real_odd : this.oddFromStore.prematch_real_odd;
  }

  get isEditDisabled() {
    return EventStore.isEventEditDisabled;
  }

  get oddLossMessage() {
    return `${this.$t('odd.oddNotProfitable')} ${this.alertValue.toFixed(2)}`;
  }

  get parsedProdOdd() {
    return isNull(this.prodOdd) ? this.$t('common.notAvailable') : Number(this.prodOdd).toFixed(2);
  }

  onEnter({ target }: any) {
    const value = target.value ? target.value : this.prodOdd;
    if (value == this.prodOdd) return;
    this.$refs.input.blur();
    let oddRatio = this.oddAlerts(SportCode.FOOTBALL, 'lvl2')(value, this.realOddInitialValue);
    if (oddRatio && this.realOddInitialValue) {
      this.alertValue = oddRatio;
      this.alertLevel = 2;
      this.confirmationString = randomizeString(5);
      this.showPopConfirm = true;
      this.tempValue = value;
      return;
    }
    oddRatio = this.oddAlerts(SportCode.FOOTBALL, 'lvl1')(value, this.realOddInitialValue);
    if (oddRatio && this.realOddInitialValue) {
      this.alertValue = oddRatio;
      this.alertLevel = 1;
      this.showPopConfirm = true;
      this.tempValue = value;
      return;
    }
    this.showPopConfirm = true;
    this.tempValue = value;
  }

  errorMessage(description = this.$t('genericError.description')) {
    this.$notification.error({
      message: this.$t('common.error') as string,
      description: description as string,
    });
  }

  successMessage(description = this.$t('odd.oddChangeSuccess')) {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: description as string,
    });
  }

  validateOdd(oddValue: string | number) {
    if (isNaN(Number(oddValue))) return this.errorMessage(this.$t('odd.valueMustBeNumeric'));
    if (this.countDecimals(oddValue) > 2) return this.errorMessage(this.$t('odd.decimalsError'));
    if (Number(oddValue) < 1) return this.errorMessage(this.$t('odd.lessThanOne'));
    return 'valid';
  }

  clearAlertData() {
    this.alertLevel = 0;
    this.confirmationString = '';
    this.alertValue = 0;
  }

  async onOk() {
    this.showPopConfirm = false;
    this.clearAlertData();
    if (this.validateOdd(this.tempValue) !== 'valid') {
      this.tempValue = '';
      return;
    }
    const payload = {
      oddInt: this.odd.int_key,
      eventId: this.eventIntKey,
      value: this.tempValue as number,
      eventIntKey: this.eventIntKey,
    };
    const [err] = await to(EventStore.updateProdOdd(payload));
    if (err) {
      this.tempValue = '';
      return;
    }
    this.successMessage();
    this.$emit('change', { oddId: this.odd.id, value: this.tempValue });
  }

  onCancel() {
    this.tempValue = '';
    this.showPopConfirm = false;
    this.clearAlertData();
  }

  suspendOdd(oddId: number, oldValue: boolean) {
    return async () => {
      const [err] = await to(
        EventStore.suspendOdd({
          oddId,
          eventId: this.eventIntKey,
          nextValue: !oldValue,
          isLive: this.isLive,
        })
      );

      if (err) return;

      MarketStore.updateOdd({ id: this.odd.int_key, params: { is_suspended: !oldValue } });
      this.successMessage(
        oldValue ? this.$t('event.oddUnsuspendSuccess') : this.$t('event.oddSuspendSuccess')
      );
    };
  }

  eventUpdated() {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('event.updated') as string,
    });
  }

  async setMaxAmount(newValue: number) {
    const [err] = await to(
      EventStore.setOddMaxBet({
        oddId: this.odd.int_key,
        eventIntKey: this.eventIntKey,
        value: newValue,
      })
    );
    if (err) return;
    this.eventUpdated();
  }

  async onScMaxBet(value: number | null) {
    const [err] = await to(
      EventStore.setOddLiveMaxAmountSc({
        oddIntKey: this.odd.int_key,
        eventIntKey: this.eventIntKey,
        value,
      })
    );
    if (err) return;
    this.eventUpdated();
  }

  async onWebMaxBet(value: number | null) {
    const [err] = await to(
      EventStore.setOddLiveMaxAmountWeb({
        oddIntKey: this.odd.int_key,
        eventIntKey: this.eventIntKey,
        value,
      })
    );
    if (err) return;
    this.eventUpdated();
  }
}
