import Vue from 'vue';
import get from 'lodash/get';
import Component from 'vue-class-component';

import { EventStore } from '@/modules/event/event.store';
import { SettlementStore } from '@/modules/settlement/settlement.store';
import { eventService, FactoryEvent } from '@/modules/event';

@Component
export default class BaseEventCell<T = FactoryEvent> extends Vue {
  params: any;

  get hasData() {
    return !!this.params?.data?.intKey || !!this.params?.data?.id;
  }

  get rowData(): T {
    return this.params?.data;
  }

  get event(): T | undefined {
    if (!this.hasData) return;
    return (EventStore.events[eventService.formatEventId(this.params?.data?.intKey)] ||
      SettlementStore.forSettlement.find((event: any) => event.id === this.params.data.id)) as any;
  }

  get rowHeight() {
    const height = get(this.params, 'node.rowHeight', 16);
    return `${height}px`;
  }

  get cellWidth(): string {
    const width = get(this.params, 'colDef.width', 100);
    return `${width}px`;
  }
}
