
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

import { EventStore } from '@/modules/event/event.store';
import { FactoryEvent, eventService } from '@/modules/event';
import { isPrematch } from '@/modules/event';
import to from 'await-to-js';
import isEmpty from 'lodash/isEmpty';

@Component
export default class EventActions extends Vue {
  @Prop({ required: true, type: Object })
  event!: FactoryEvent;

  @Prop({ required: false, type: Boolean })
  isLive!: boolean;

  get eventId() {
    return this.event.intKey;
  }

  get oddAutoChange() {
    return this.event.tech === 'A' ? 'true' : 'false';
  }

  get eventPrematchProvider() {
    if (!isPrematch(this.event)) return false;
    return this.event.prematchProvider;
  }

  get eventLiveProvider() {
    if (isEmpty(this.liveProviderOptions)) return '';
    return this.liveProviderOptions.find((option: any) => option.value === this.event.liveProvider)
      ?.value;
  }

  get actions() {
    return [
      {
        key: 'automatic_odds_change',
        label: this.$t('event.tech'),
        options: this.techOptions,
        defaultValue: this.oddAutoChange,
        isDisabled: false,
        onSelect: this.onTypeSelect,
      },
      {
        key: 'prematch',
        label: this.$t('common.prematch'),
        options: this.prematchProviderOptions ? this.prematchProviderOptions : [],
        defaultValue: this.eventPrematchProvider || this.$t('common.notAvailable'),
        onSelect: this.onPrematchProviderSelect,
        isDisabled: true, // Temporary 'till this select has some functionality
        class: this.eventPrematchProvider && 'betradar-select',
      },
      {
        key: 'live',
        label: this.$t('common.live'),
        options: this.liveProviderOptions ? this.liveProviderOptions : [],
        defaultValue: this.eventLiveProvider || this.$t('common.notAvailable'),
        onSelect: this.onLiveProviderSelect,
        isDisabled: true,
        class: this.eventLiveProvider && `${this.eventLiveProvider}-select`,
      },
    ];
  }

  get selectConfig() {
    return {
      size: 'small',
    };
  }

  get sportId() {
    if (this.event) {
      return this.event.sportId;
    }
    return 0;
  }

  get techOptions() {
    return [
      { label: this.$t('event.auto'), value: 'true', key: 1 },
      { label: this.$t('event.manual'), value: 'false', key: 2 },
    ];
  }

  get prematchProviderOptions() {
    return [
      {
        label: this.$t('common.betradar'),
        value: 'betradar',
        key: 1,
        class: 'betradar-select',
      },
    ];
  }

  get liveProviderOptions() {
    return eventService.mapProvidersFromStruct(this.event.liveProviders);
  }

  get isEditDisabled() {
    return EventStore.isEventEditDisabled;
  }

  getDefaultValue(template: any) {
    const key = template.key as keyof FactoryEvent;
    return this.event[key];
  }

  async onTypeSelect(value: any) {
    const nextValue = value === 'true' ? true : false;
    const [err] = await to(EventStore.setAutoOddChange({ eventId: this.eventId, nextValue }));
    if (err) return;
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('event.techChangeSuccess') as string,
    });
  }

  async changeLiveProvider(provider: string) {
    const payload = {
      provider: {
        live_provider: provider,
      },
      intKey: this.event.intKey,
    };
    const [err] = await to(EventStore.changeLiveProvider(payload));
    if (err) return;
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('event.providerChangedSuccess') as string,
    });
  }

  onLiveProviderSelect(provider: string) {
    this.$confirm({
      title: this.$t('common.areYouSure'),
      content: this.$t('event.doYouWantToChangeProvider'),
      onOk: () => {
        this.changeLiveProvider(provider);
      },
    });
  }

  onPrematchProviderSelect(e: any) {
    e.stopPropagation();
  }
}
