
import Vue from 'vue';
import { Watch, Component, Prop } from 'vue-property-decorator';
import { to } from 'await-to-js';

import { MarketStore } from '@/modules/market/market.store';
import { EventStore } from '@/modules/event/event.store';
import { Odd } from '@/ui/components/Odd';
import { InputParams } from '@/ui/components/InputParams';
import { SuspendAction } from '@/ui/components/SuspendAction';
import { MaxBetAction } from '@/ui/components/MaxBetAction';
import { EventMaxBetForm } from '@/ui/components/EventMaxBetForm';
import { IMarket } from '@/modules/market';
import { LiveMaxBetAction } from '@/ui/components/LiveMaxBetAction';
import { TranslateResult } from 'vue-i18n';
import { eventService, isPrematch } from '@/modules/event';
import { SportCode, SportStore } from '@/modules/sport';
import { InputParamsTennis } from '@/ui/components/InputParamsTennis';
import { GoesThroughMarket } from '@/ui/components/GoesThroughMarket';
import { CommonStore } from '@/modules/common';

const PAGE_SIZE = 2; // number of markets with odds

@Component({
  components: {
    Odd,
    InputParams,
    InputParamsTennis,
    SuspendAction,
    MaxBetAction,
    EventMaxBetForm,
    LiveMaxBetAction,
    GoesThroughMarket,
  },
})
export default class PrematchSetup extends Vue {
  searchInput = '';
  page = 1;
  pageSize = PAGE_SIZE;

  @Prop({ required: false, type: Array })
  actions!: string[];

  @Prop({ required: false, type: Boolean })
  isLive!: boolean;

  @Prop({ required: false, default: false })
  hideInputParams!: boolean;

  get selectedEvent() {
    return EventStore.active;
  }

  get activeTech() {
    return EventStore.active?.tech === 'A' ? true : false;
  }

  get selectedEventId() {
    return this.selectedEvent?.intKey;
  }

  get markets() {
    if (this.searchInput) {
      return MarketStore.markets?.filter(this.filterMarketByName) || [];
    }
    return MarketStore.markets || [];
  }

  get noMarkets() {
    if (this.isLoading) return false;
    return MarketStore.hasMarkets === false;
  }

  get isLoading() {
    return MarketStore.isLoading;
  }

  get sport() {
    if (!this.selectedEvent) return null;
    return SportStore.getSportById(this.selectedEvent.sportId);
  }

  get showTennisInputParams() {
    if (!this.selectedEvent) return false;
    return this.sport?.code === SportCode.TENNIS;
  }

  get hasInputParams() {
    if (!this.selectedEvent) return false;
    if (!isPrematch(this.selectedEvent)) return false;
    return eventService.hasInputParams(this.sport?.code);
  }

  get canPerform() {
    return (action: string) => this.actions && this.actions.includes(action);
  }

  get marketMaxBetDefaultValue() {
    return (market: IMarket) => {
      return market.prematch_max_amount;
    };
  }

  get isEditDisabled() {
    return EventStore.isEventEditDisabled;
  }

  get betStop() {
    if (this.selectedEvent && this.isLive) {
      return this.selectedEvent.betStop;
    }
    return false;
  }

  get paginatedMarkets() {
    return this.markets.slice(0, this.page * PAGE_SIZE);
  }

  get hasMapGoesThrough() {
    return MarketStore.hasMapGoesThrough;
  }

  get showGoesThrough() {
    if (
      !this.selectedEvent ||
      this.isLoading ||
      this.hasMapGoesThrough ||
      !MarketStore.isGoesThroughEnabled(
        this.selectedEvent.prematchMarketTemplateId,
        this.selectedEvent.sportId
      )
    ) {
      return false;
    }
    return true;
  }

  @Watch('selectedEvent.prematchMarketTemplateId')
  @Watch('selectedEvent.liveMarketTemplateId')
  onPrematchMarketChange() {
    this.fetchMarkets();
  }

  filterMarketByName(market: any) {
    if (!market.name) return false;
    const name = market.name.toLowerCase();
    return name.includes(this.searchInput.toLowerCase());
  }

  fetchMarkets() {
    if (!this.selectedEvent) return;
    if (this.isLive) return MarketStore.getLiveMarkets(this.selectedEvent.intKey);
    MarketStore.getMarkets(this.selectedEvent.intKey);
  }

  @Watch('selectedEvent')
  onWatch(oldValue: any, newValue: any) {
    if (oldValue?.intKey === newValue?.intKey) return;
    this.fetchMarkets();
  }

  mounted() {
    this.fetchMarkets();
  }

  beforeMount() {
    if (!this.selectedEvent) return;
    MarketStore.setGoesThroughData({
      sportId: this.selectedEvent?.sportId,
    });
  }

  destroyed() {
    MarketStore.resetStore(['templates']);
  }

  onSearch(val: string) {
    this.searchInput = val;
  }

  suspendMarket(marketId: number, currentValue: boolean) {
    return async () => {
      if (!this.selectedEvent) return;
      const [err] = await to(
        EventStore.suspendMarket({
          eventId: this.selectedEvent.intKey,
          marketId,
          nextValue: !currentValue,
          isLive: this.isLive,
        })
      );
      if (err) return;
      MarketStore.updateMarket({
        id: marketId,
        params: { is_suspended: !currentValue },
      });
      this.eventUpdated(
        currentValue
          ? this.$t('event.marketUnsuspendSuccess')
          : this.$t('event.marketSuspendSuccess')
      );
    };
  }

  eventUpdated(description?: TranslateResult) {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: (description as string) || (this.$t('event.updated') as string),
    });
  }

  maxAmountChanged(marketId: number) {
    return async (value: any) => {
      if (!this.selectedEvent) return;
      const [err] = await to(
        EventStore.setMarketMaxBet({
          eventId: this.selectedEvent.intKey,
          marketId,
          value,
        })
      );
      if (err) return;
      this.eventUpdated();
    };
  }

  onScMaxBet(marketId: number) {
    return async (value: number) => {
      if (!this.selectedEvent) return;
      const [err] = await to(
        EventStore.setMarketLiveMaxBetSc({
          eventIntKey: this.selectedEvent.intKey,
          marketId,
          value,
        })
      );
      if (err) return;
      this.eventUpdated();
    };
  }

  onWebMaxBet(marketId: number) {
    return async (value: number) => {
      if (!this.selectedEvent) return;
      const [err] = await to(
        EventStore.setMarketLiveMaxBetWeb({
          eventIntKey: this.selectedEvent.intKey,
          marketId,
          value,
        })
      );
      if (err) return;
      this.eventUpdated();
    };
  }

  infiniteHandler($state: any) {
    this.page = this.page + 1;
    if (this.paginatedMarkets.length === this.markets.length) {
      $state.complete();
    } else {
      $state.loaded();
    }
  }

  onCustomOddSave() {
    this.fetchMarkets();
  }

  get toggleModal() {
    return (modal: any) => CommonStore.toggleModal(modal);
  }
}
