import moment from 'moment';

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const disabledDate = (current: any) => {
  // Can not select days before today
  return current && current < moment().startOf('day');
};

const disabledTime = (current: any) => {
  if (!moment(0, 'HH').diff(current, 'days') == false) return false;
  return {
    // Can not select time before current time
    disabledHours: () => range(0, moment().hour()),
    // disabledMinutes: () => range(0, moment().minutes()),
  };
};

export { disabledDate, disabledTime };
