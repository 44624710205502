import { platformRepo } from './platform.repo';

class PlatformService {
  getPlatformStatus() {
    return platformRepo.getPlatformStatus();
  }

  toggleSuspendState(nextState: boolean) {
    return platformRepo.toggleSuspendState(nextState);
  }

  togglePrematchOffer(nextState: boolean) {
    return platformRepo.togglePrematchOffer(nextState);
  }
}

export const platformService = new PlatformService();
