
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

@Component
export default class SuspendAction extends Vue {
  @Prop({ required: true })
  isSuspended!: boolean;
  @Prop({ required: true })
  suspend!: () => void;

  newValue: null | boolean = null;

  get suspendIcon() {
    return this.value ? 'pause-circle' : 'play-circle';
  }

  get text() {
    return this.value ? this.$t('common.clickToUnsuspend') : this.$t('common.clickToSuspend');
  }

  get value() {
    if (this.newValue !== null) {
      return this.newValue;
    }
    return this.isSuspended;
  }

  onSuspend() {
    if (this.newValue === null) {
      this.newValue = !this.isSuspended;
    } else {
      this.newValue = !this.newValue;
    }
    this.suspend();
  }
}
