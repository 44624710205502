import { OperatorNote } from './common.types';
import { FULL_DATE_TIME } from '@/constants';
import dayjs from 'dayjs';

export const formatOperatorNote = (operatorNote: OperatorNote) => {
  if (!operatorNote.paramsList) return operatorNote.note;
  if (!operatorNote.paramsList?.length) return operatorNote.note;
  const replaceFirstAcc = `${operatorNote.note.replace(
    '{}',
    dayjs(operatorNote.paramsList[0]).format(FULL_DATE_TIME)
  )}`;
  return replaceFirstAcc.replace('{}', dayjs(operatorNote.paramsList[1]).format(FULL_DATE_TIME));
};
