export interface ApprovalTemplatePrematch {
  id: number;
  name: string;
  is_default: boolean;
  sport_id: number;
  template_type: TemplateType.prematch;
  betting_amount_above: {
    rule: {
      prematch: number;
      player: number | null;
      dual: number;
    };
  };
  betting_odd_above: {
    odd: number | null;
  };
  betting_amount_above_group: {
    rule: {
      [id: string]: { value?: number };
    };
  };
}

interface ApprovalTemplateLive {
  id: number;
  name: string;
  sport_id: number;
  is_default: boolean;
  template_type: TemplateType.live;
  betting_amount_above: {
    rule: {
      web: number;
      landbase: number;
    };
  };
}

export enum TemplateType {
  live = 'LIVE',
  prematch = 'PREMATCH',
}

export type ApprovalTemplate = ApprovalTemplateLive | ApprovalTemplatePrematch;
