
import Vue from 'vue';
import Component from 'vue-class-component';

import OutrightSetup from './OutrightSetup.vue';
import { PrematchSetup } from '@/ui/components/PrematchSetup';
import { EventNotes } from '@/ui/components/EventNotes';
import { EventDetails } from '@/ui/components/EventDetails';
import { EventTemplates } from '@/ui/components/EventTemplates';
import { EventActions } from '@/ui/components/EventActions';
import { EventStore } from '@/modules/event';
import EventTemplatesForm from './EventTemplatesForm.vue';

@Component({
  components: {
    OutrightSetup,
    PrematchSetup,
    EventNotes,
    EventDetails,
    EventActions,
    EventTemplates,
    EventTemplatesForm,
  },
})
export default class EventSetup extends Vue {
  get event() {
    return EventStore.active;
  }
}
