import Vue from 'vue';
import Component from 'vue-class-component';

import { FilterConfig } from '@/modules/filter/filter.types';
import { FilterStore } from '@/modules/filter/filter.store';

@Component
export class WithFilters extends Vue {
  filterConfig: FilterConfig = [];

  get activeFilters() {
    return FilterStore.activeFilters;
  }

  mounted() {
    FilterStore.init(this.filterConfig);
  }
}
