var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.container},[_c('a-form',{class:_vm.$style.form,attrs:{"form":_vm.form}},[_c('a-row',{staticClass:"pr-10",class:_vm.$style.inputParamsTennis},[_vm._l((_vm.inputParamConfig()),function(input){return _c('a-col',{key:input.uiKey || input.key,attrs:{"span":6}},[_c('div',{class:_vm.$style.inputParam},[_c('div',[_c('div',{class:_vm.$style.label},[_vm._v(_vm._s(input.label))]),_c('a-form-item',{attrs:{"help":false}},[_c('change-alert',{attrs:{"isPopoverVisible":_vm.visibleAlert[("" + (input.key))],"alertLevel":_vm.alertLevel,"onPopoverCancel":function () { return _vm.onCancel(input); },"onOk":function () { return _vm.onOk(input); },"oddAlertMessage":_vm.inputParamsChanges,"popoverTitle":'',"confirmationString":_vm.confirmationString,"okButtonText":_vm.$t('common.ok'),"showCancelButton":true,"placement":'bottomLeft'}},[_c('template',{slot:"trigger"},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(input.vDecorator),expression:"input.vDecorator"}],attrs:{"precision":3,"size":"small","disabled":_vm.isEditDisabled},on:{"change":input.change,"pressEnter":function($event){return _vm.onEnter(input)},"blur":function($event){return _vm.checkValidityOnBlur(input)}}})],1)],2)],1)],1),_c('div',{class:_vm.$style.operatorValue},[_vm._v(" "+_vm._s(_vm.getParameter('provider', { key: input.key, isReciprocal: input.isReciprocal, }))+" ")])])])}),_c('a-col',{attrs:{"span":6}},[_c('div',{class:_vm.$style.inputParam},[_c('div',[_c('div',{class:_vm.$style.label},[_vm._v("Margin")]),_c('a-form-item',{attrs:{"help":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'margin',
                  {
                    initialValue: _vm.eventInputParams.margin,
                    rules: [{ required: true }],
                  } ]),expression:"[\n                  'margin',\n                  {\n                    initialValue: eventInputParams.margin,\n                    rules: [{ required: true }],\n                  },\n                ]"}],attrs:{"size":"small"}},[_c('a-select-option',{key:"5%",attrs:{"value":"5%"}},[_vm._v("5%")]),_c('a-select-option',{key:"7.5%",attrs:{"value":"7.5%"}},[_vm._v("7.5%")])],1)],1)],1),_c('div',{class:_vm.$style.operatorValue},[_vm._v(" "+_vm._s(_vm.eventInputParams.margin || _vm.$t('common.notAvailable'))+" ")])])]),_c('a-col',{attrs:{"span":6}},[_c('div',{class:_vm.$style.inputParam},[_c('div',[_c('div',{class:_vm.$style.label},[_vm._v("Parameters")]),_c('a-form-item',{attrs:{"help":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'parameter',
                  {
                    rules: [{ required: !!_vm.tennisHandicaps }],
                  } ]),expression:"[\n                  'parameter',\n                  {\n                    rules: [{ required: !!tennisHandicaps }],\n                  },\n                ]"}],attrs:{"size":"small","disabled":!_vm.tennisHandicaps}},_vm._l((_vm.tennisHandicaps),function(param){return _c('a-select-option',{key:param.parametar_id,attrs:{"value":param.parametar_id}},[_vm._v(" "+_vm._s(((param['1S+_ou']) + " " + (param['1S+']) + " | " + (param['H1_hd']) + " " + (param['H1'])))+" ")])}),1)],1)],1),_c('div',{class:_vm.$style.operatorValue},[_vm._v(" "+_vm._s(this.$t('common.notAvailable'))+" ")])])]),_c('a-col',{class:_vm.$style.modalParamsButton,attrs:{"span":6}},[_c('a-button',{class:_vm.$style.submitButton,attrs:{"loading":_vm.isGeneratingOdds,"disabled":_vm.isEditDisabled},on:{"click":_vm.onGetTennisHandicaps}},[_vm._v(" "+_vm._s(_vm.$t('inputParams.generateHandicaps'))+" ")])],1),_c('a-col',{class:_vm.$style.modalParamsButton,attrs:{"span":6}},[_c('a-button',{class:_vm.$style.submitButton,attrs:{"loading":_vm.isGeneratingOdds,"disabled":_vm.isEditDisabled || !_vm.isValid},on:{"click":_vm.generateTennisOdds}},[_vm._v(" "+_vm._s(_vm.$t('inputParams.generateOdds'))+" ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }