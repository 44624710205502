
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import to from 'await-to-js';
import dayjs from 'dayjs';
import toMoment from '@/lib/dateToMoment';

import { FULL_DATE_TIME, SHORT_DATE_TIME } from '@/constants';
import { EventStore, eventService, FactoryEvent } from '@/modules/event/';
import { FeedStore } from '@/modules/feed';
import { SettlementStore } from '@/modules/settlement/settlement.store';
import { isLive, isStatscoreEvent } from '@/modules/event';

@Component
export default class EventTime extends Vue {
  @Prop({ required: true, type: Object })
  event!: FactoryEvent;

  @Prop({ required: false, type: Boolean })
  isReadOnly!: boolean;

  @Prop({ default: false })
  isSettlementTime!: boolean;

  @Prop()
  disabledDate!: Function;

  @Prop()
  disabledTime!: Function;

  get shortDateFormat() {
    return SHORT_DATE_TIME;
  }

  get fullDateTime() {
    return FULL_DATE_TIME;
  }

  get hasTime() {
    return !!FeedStore.results[this.event.intKey]?.time;
  }

  get time() {
    if (!this.event) return '';
    const eventTime = dayjs(this.event.start).format(SHORT_DATE_TIME);
    const eventResult = FeedStore.results[this.getAccesor(this.event.intKey)];
    return eventService.eventTime({
      currentPhase:
        isLive(this.event) || isStatscoreEvent(this.event)
          ? this.event.currentPhaseJson
          : undefined,
      startTime: eventTime,
      result: eventResult,
      liveStatus: isLive(this.event) ? this.event?.liveStatus : '',
    });
  }

  get initialTime() {
    return toMoment(this.event.start);
  }

  getAccesor = (intKey: number | string) => eventService.formatEventId(intKey);

  eventUpdated() {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('event.updated') as string,
    });
  }

  async updateTime(date: string) {
    // @ts-ignore
    const stage = this.event.stage ?? 'prematch';
    const settle = false;
    const [err] = await to(
      EventStore.updateStart({ intKey: this.event.intKey, date, stage, settle })
    );
    if (err) return;
    if (this.isSettlementTime) {
      SettlementStore.editEvent({ eventId: this.event.intKey, key: 'start', newValue: date });
    }
    this.eventUpdated();
  }
}
