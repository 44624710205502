
import uniq from 'lodash/uniq';
import Vue from 'vue';
import Component from 'vue-class-component';

import SportFilterItem from './SportFilterItem.vue';
import { SportFilterStore } from '@/modules/sportFilter/sportFilter.store';
import { Prop } from 'vue-property-decorator';
import { SportSource } from '@/modules/sportFilter/sportFilter.types';
import { CommonStore } from '@/modules/common';
import { EventStore } from '@/modules/event';
import { sportFilterService } from '@/modules/sportFilter/sportFilter.service';
import SportFilterToggleAllButtons from './SportFilterToggleAllButtons.vue';

@Component({ name: 'SportFilter', components: { SportFilterItem, SportFilterToggleAllButtons } })
export default class SportFilter extends Vue {
  @Prop({ default: false })
  isLive!: boolean;

  @Prop({
    default: SportSource.factory,
    validator: (v: keyof typeof SportSource) => SportSource[v] !== undefined,
  })
  source!: SportSource;

  @Prop({ default: () => [] })
  sportsWithPlayers!: number[];

  @Prop({
    default: { level: 2 },
  })
  expandable!: { level: number };

  mounted() {
    SportFilterStore.setSportsWithPlayers(this.sportsWithPlayers);
    SportFilterStore.loadData(this.source);
  }

  destroyed() {
    SportFilterStore.resetStore();
  }

  get isExpanded() {
    return (clientId: string) => SportFilterStore.isExpanded(clientId);
  }

  get isLoading() {
    return SportFilterStore.isLoading;
  }

  get sports() {
    if (
      SportFilterStore.sports &&
      this.source === 'factory' &&
      EventStore.availableCompetitions.length
    ) {
      return sportFilterService.filterSports(
        SportFilterStore.sports,
        EventStore.availableCompetitions
      );
    }
    return SportFilterStore.sports;
  }

  get showNoClick() {
    return CommonStore.isFetching;
  }

  get haveLocation() {
    return (sport: any) => {
      const list = uniq(sport.locations.map((item: any) => item.id));
      return list.length === 1 && !list[0] ? false : true;
    };
  }
}
