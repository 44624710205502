import { EventStatesClassMap, EVENT_STATES, StateActionMap } from '../event';
import { antepostRepo } from './antepost.repo';
import { sportProtoApi } from '../grpc/sport.api';
import {
  AntepostMarketEditPayload,
  NewOperatorNotePayload,
  NewAntepostBetPaylad,
  Antepost,
  AntepostBet,
  AntepostBetState,
  AntepostCreatePayload,
  AntepostEditPayload,
  AntepostState,
  MoveToEventMngPayload,
  AntepostMarket,
} from './antepost.types';
import dayjs from 'dayjs';

class AntepostService {
  getMarkets() {
    return sportProtoApi.fetchAntepostMarkets();
  }

  saveMarket(payload: AntepostMarketEditPayload) {
    return antepostRepo.saveMarket(payload);
  }

  deleteMarket(marketId: number) {
    return antepostRepo.deleteMarket(marketId);
  }

  updateMarket(marketId: number, payload: Partial<AntepostMarketEditPayload>) {
    return antepostRepo.updateMarket(marketId, payload);
  }

  getAnteposts(marketId: number) {
    return antepostRepo.getAntepostsForMarket(marketId);
  }

  getPrematchAnteposts() {
    return sportProtoApi.fetchPrematchAnteposts();
  }

  getAntepostOffer() {
    return sportProtoApi.fetchAntepostOffer();
  }

  createAntepost(payload: AntepostCreatePayload) {
    const utcStart = dayjs(payload.start).utc();
    const utcEnd = dayjs(payload.end).utc();
    return antepostRepo.createAntepost({ ...payload, start: utcStart, end: utcEnd });
  }

  updateAntepost(id: number, payload: Partial<AntepostEditPayload>) {
    return antepostRepo.updateAntepost(id, payload);
  }

  deleteAntepost(id: number) {
    return antepostRepo.deleteAntepost(id);
  }

  getAntepostEventMng(id: number) {
    return antepostRepo.antepostEventMng(id);
  }

  addOperatorNote(payload: NewOperatorNotePayload) {
    return antepostRepo.addOperatorNote(payload);
  }

  createAntepostBet(payload: NewAntepostBetPaylad) {
    return antepostRepo.createAntepostBet(payload);
  }

  getAntepostBets(payload: number[]) {
    return antepostRepo.getAntepostBets(payload);
  }

  updateAntepostBet(id: number, payload: Partial<AntepostBet>) {
    let betOddMultipliedBy100 = {};
    if (payload.odd_value) {
      betOddMultipliedBy100 = {
        ...payload,
        // @ts-ignore-next-line
        odd_value: parseInt(payload.odd_value * 100),
      };
    } else {
      betOddMultipliedBy100 = payload;
    }
    return antepostRepo.updateAntepostBet(id, betOddMultipliedBy100);
  }

  deleteBet(id: number) {
    return antepostRepo.deleteBet(id);
  }

  moveAntepostToEventMng(payload: MoveToEventMngPayload) {
    return antepostRepo.moveAntepostToEventMng(payload);
  }

  getState(state: AntepostState | AntepostBetState) {
    return EVENT_STATES.find((s: any) => s.value === state);
  }

  addToArray(state: AntepostState | AntepostBetState) {
    const option = this.getState(state);
    return option ? [option] : [];
  }

  filterMarketsByCompetition(markets: AntepostMarket[], selectedCompetitions: number[]) {
    const filteredMarkets: AntepostMarket[] = [];
    selectedCompetitions.forEach(competitionId => {
      markets.forEach(market => {
        if (market.competition === competitionId) {
          filteredMarkets.push(market);
        }
      });
    });
    return filteredMarkets;
  }

  stateActonsMap: any = {
    SELECTED: {
      next: [...this.addToArray('VALIDATED')],
      prev: null,
    },
    VALIDATED: {
      next: [...this.addToArray('PUBLISHED')],
      prev: [...this.addToArray('SELECTED')],
    },
    PUBLISHED: {
      next: [...this.addToArray('HIDDEN')],
      prev: [...this.addToArray('VALIDATED')],
    },
    HIDDEN: {
      next: null,
      prev: [...this.addToArray('PUBLISHED')],
    },
  };

  betActionsMap: any = {
    SELECTED: {
      next: [...this.addToArray('VALIDATED')],
      prev: null,
    },
    VALIDATED: {
      next: [...this.addToArray('PUBLISHED')],
      prev: [...this.addToArray('SELECTED')],
    },
    PUBLISHED: {
      next: [...this.addToArray('HIDDEN')],
      prev: [...this.addToArray('SELECTED')],
    },
    HIDDEN: {
      next: null,
      prev: [...this.addToArray('PUBLISHED')],
    },
  };

  betActionsMapPrematch: any = {
    SELECTED: {
      next: [...this.addToArray('VALIDATED')],
      prev: null,
    },
    VALIDATED: {
      next: [...this.addToArray('PUBLISHED')],
      prev: [...this.addToArray('SELECTED')],
    },
    PUBLISHED: {
      next: [...this.addToArray('HIDDEN')],
      prev: null,
    },
    HIDDEN: {
      next: null,
      prev: [...this.addToArray('PUBLISHED')],
    },
  };

  getStateTransition(state: keyof StateActionMap) {
    return this.stateActonsMap[state];
  }

  getBetStateTransition(state: string) {
    return this.betActionsMap[state];
  }

  getBetStateTransitionPrematch(state: string) {
    return this.betActionsMapPrematch[state];
  }

  getAntepostEventClass(event: Antepost) {
    if (!event) return '';
    const map: EventStatesClassMap = EVENT_STATES.reduce((agg, state) => {
      return {
        ...agg,
        [state.value]: state.class,
      };
    }, {});
    const isSuspended = event.isSuspended;
    const suspendedClass = isSuspended ? ['suspended-event-row'] : [];
    const stateClass = map[event.state] || '';
    return [...[stateClass], ...suspendedClass].join(' ');
  }

  bulkStateTansition<T extends { state: AntepostState }>(events: T[]) {
    const inTheSameState = events.every((e: any) => e.state === events[0].state);
    if (!inTheSameState || !events[0]) return null;
    return this.getStateTransition(events[0].state);
  }

  canBulkSuspend(selectedAnteposts: Antepost[]) {
    let canSuspend = true;
    selectedAnteposts.forEach((event, i: number) => {
      if (i !== 0) {
        const key = 'is_suspended' as keyof Antepost;
        if (event[key] !== selectedAnteposts[i - 1][key]) {
          canSuspend = false;
        }
      }
    });
    return canSuspend;
  }

  suspendAntepost(ids: number[], nextValue: boolean) {
    return antepostRepo.suspendAnteposts(ids, nextValue);
  }

  changeState(events: string[], nextState: AntepostState) {
    return antepostRepo.changeState(events, nextState);
  }

  suspendBet(payload: { id: number; value: boolean }) {
    return antepostRepo.suspendAntepostBets(payload.id, payload.value);
  }

  changeBetState(bets: number[], nextState: AntepostBetState) {
    return antepostRepo.changeBetState(bets, nextState);
  }

  removeAntepostFromOffer(antepostId: number) {
    return antepostRepo.removeAntepostFromOffer(antepostId);
  }

  suspendAntepostMarket(antepostId: number, isSuspended: boolean) {
    return antepostRepo.suspendAntepostMarket(antepostId, isSuspended);
  }

  setAntepostMaxBet(payload: { newValue: number; intKey: number }) {
    return antepostRepo.setAntepostMaxBet(payload);
  }

  sortAntepostBetsByCode(bets: AntepostBet[]) {
    if (!bets.length) return [];
    return bets.sort((a, b) => Number(a.code) - Number(b.code));
  }
}

export const antepostService = new AntepostService();
