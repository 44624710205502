
import Component from 'vue-class-component';

import BaseEventCell from './BaseEventCell';
import { ApprovalStore } from '@/modules/approval';
import { OutcomeStore } from '@/modules/outcome';
import { MarketStore } from '@/modules/market/market.store';
import { mapForSelect } from '@/lib';
import { FactoryEvent } from '@/modules/event/event.types';

@Component
export default class EventTemplates extends BaseEventCell {
  get templates() {
    return [
      {
        key: 'prematch_outcome_template',
        dataKey: 'prematchOutcomeTemplateId',
        label: this.$t('outcome.template'),
        options: this.outcomesTemplates,
      },
      {
        key: 'prematch_market_template',
        dataKey: 'prematchMarketTemplateId',
        label: this.$t('market.template'),
        options: this.marketTemplates,
      },
      {
        key: 'prematch_approval_template_id',
        dataKey: 'prematchApprovalTemplateId',
        label: this.$t('approval.template'),
        options: this.approvalTemplates,
      },
    ];
  }

  get selectConfig() {
    return {
      size: 'small',
    };
  }

  get sportId() {
    if (this.rowData) {
      return this.rowData.sportId;
    }
    return 0;
  }

  get approvalTemplates() {
    if (!this.hasData) return [];
    return mapForSelect(ApprovalStore.sportPrematchTemplates(this.sportId));
  }

  get outcomesTemplates() {
    if (!this.hasData) return [];
    return mapForSelect(OutcomeStore.sportPrematchTemplates(this.sportId));
  }

  get marketTemplates() {
    if (!this.hasData) return [];
    return mapForSelect(MarketStore.sportTemplates(this.sportId, 'PREMATCH'));
  }

  getDefaultValue(template: any) {
    if (!this.event) return;
    const key = template.dataKey as keyof FactoryEvent;
    const templateId = this.event[key];
    const eventTemplate = template.options.find((t: any) => t.value === templateId);
    return eventTemplate?.label || this.$t('common.notAvailable');
  }
}
