import { i18n } from '@/ui/locales/setupLocale';
import { SportCode } from '../sport';

type MARKET_2TYPES = {
  [key: string]: string[];
};

export const PLAYER_MARKETS_2TYPES: MARKET_2TYPES = {
  fud: ['Fantasy Poeni'],
  kos: ['Ukupno Poena', 'Ukupno Skokova', 'Ukupno Asistencija'],
  ruk: ['Ukupno Golova'],
  vat: ['Ukupno Golova'],
};

export const GROUP_TYPE = [
  ['+', '-'],
  ['-1+', '-1-'],
  ['+1-', '+1+'],
];

export const VALIDATOR_MESSAGES: { [key: string]: any } = {
  [SportCode.FOOTBALL]: {
    isPositive: i18n.t('inputParams.isPositive'),
    isHalfStep: i18n.t('inputParams.isHalfStep'),
    changeDifference: i18n.t('inputParams.changeDifference'),
    totalOddAlertLvl1: i18n.t('inputParams.totalOddAlertLvl1'),
    handicapOddAlertLvl1: i18n.t('inputParams.handicapOddAlertLvl1'),
  },
  [SportCode.BASKETBALL]: {
    isPositive: i18n.t('inputParams.isPositive'),
    isHalfStep: i18n.t('inputParams.isHalfStep'),
    twoStepChangeDifference: i18n.t('inputParams.changeDifference'),
    threeStepChangeDifference: i18n.t('inputParams.changeDifference'),
    totalOddAlertLvl1: i18n.t('inputParams.totalOddAlertLvl1'),
    handicapOddAlertLvl1: i18n.t('inputParams.handicapOddAlertLvl1'),
    limitCompareByPeriods: i18n.t('inputParams.limitCompareByPeriods'),
    handicapSignCompare: i18n.t('inputParams.handicapSignCompare'),
    handicapLimitCompareByPeriod: i18n.t('inputParams.handicapLimitCompareByPeriod'),
  },
  [SportCode.HANDBALL]: {
    isPositive: i18n.t('inputParams.isPositive'),
    isHalfStep: i18n.t('inputParams.isHalfStep'),
    changeDifference: i18n.t('inputParams.changeDifference'),
    twoStepChangeDifference: i18n.t('inputParams.changeDifference'),
    limitCompareByPeriods: i18n.t('inputParams.limitCompareByPeriods'),
    handicapSignCompare: i18n.t('inputParams.handicapSignCompare'),
    handicapLimitCompareByPeriod: i18n.t('inputParams.handicapLimitCompareByPeriod'),
  },
  [SportCode.HOCKEY]: {
    isPositive: i18n.t('inputParams.isPositive'),
    isHalfStep: i18n.t('inputParams.isHalfStep'),
    changeDifference: i18n.t('inputParams.changeDifference'),
    limitCompareByPeriods: i18n.t('inputParams.limitCompareByPeriods'),
    handicapSignCompare: i18n.t('inputParams.handicapSignCompare'),
  },

  [SportCode.TENNIS]: {
    isPositive: i18n.t('inputParams.isPositive'),
    isHalfStep: i18n.t('inputParams.isHalfStep'),
    twoStepChangeDifference: i18n.t('inputParams.changeDifference'),
    totalOddAlertLvl1: i18n.t('inputParams.totalOddAlertLvl1'),
  },
};
