
import Vue from 'vue';
import { Prop, Component, Watch } from 'vue-property-decorator';

import { FeedStore, OddsMap } from '@/modules/feed';

@Component
export default class OddValue extends Vue {
  @Prop({ required: true })
  initialValue!: string;

  @Prop({ required: true })
  oddId!: number;

  // In the feed store we have three types of map for odds, we need to pass to this component what type of odd we want to listen;
  @Prop({ required: false, type: String, default: 'odds' })
  oddKey!: keyof typeof FeedStore;

  @Prop({ required: false, type: String, default: 'prodOdd' })
  oddType!: 'prodOdd' | 'realOdd';

  @Prop({ required: false, type: Boolean, default: false })
  isDisabledByProvider!: boolean;

  icon = '';

  get oddValueAccessor() {
    return this.oddType === 'prodOdd' ? 'p_v' : 'r_v';
  }

  get feedOdds() {
    return FeedStore[this.oddKey] as OddsMap;
  }

  get latestValue() {
    if (this.isDisabledByProvider) {
      return this.$t('common.disabledByProviderShort');
    }
    const feedOdd = this.feedOdds[this.oddId];
    if (!feedOdd || !feedOdd[this.oddValueAccessor])
      return this.initialValue || this.$t('common.notAvailable');
    return feedOdd[this.oddValueAccessor];
  }

  @Watch('latestValue')
  onRealOddChange(oldValue: any, newValue: any) {
    this.icon = oldValue > newValue ? 'up' : 'down';
  }
}
