
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class TableContainer extends Vue {
  @Prop({ required: false })
  sideWidth!: string;

  @Prop({ required: false })
  containerHeight!: string | number;

  @Prop({ required: false })
  containerWidth!: string | number;

  get width() {
    return this.hasSideContent ? this.sideWidth : null;
  }

  get hasSideContent() {
    return this.$slots['side-content'];
  }
}
