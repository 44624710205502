
import Component from 'vue-class-component';

import { EventStore } from '@/modules/event/event.store';
import BaseEventCell from './BaseEventCell';
import { PrematchEvent } from '@/modules/event';

@Component
export default class ViewButtonWithOdds extends BaseEventCell<PrematchEvent> {
  declare params: any | undefined;

  get numOfOdds() {
    if (!this.event) return 0;
    return this.event.templateOddsNum;
  }

  get numOfProdOdd() {
    const isEventMng = this.params.colDef?.cellRendererParams?.cellType === 'EVENT-MANAGEMENT';
    return isEventMng ? this.event?.numOfProdOddMng : this.event?.numOfProdOddPrematch;
  }

  onClick() {
    if (!this.event) return;
    EventStore.setActiveEvent(this.event?.intKey);
  }
}
