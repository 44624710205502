
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import to from 'await-to-js';

import { EventStore, EventState } from '@/modules/event';
import SelectedEvents from './SelectedEvents.vue';
import { PlayerStore } from '@/modules/player';
import { AntepostStore } from '@/modules/antepost';

@Component({ components: { SelectedEvents } })
export default class ChangeStateSelect extends Vue {
  @Prop({ required: false, type: Array })
  excludeStates!: EventState[];

  @Prop({ default: 'DUAL', type: String })
  eventType!: 'DUAL' | 'PLAYER' | 'ANTEPOST';

  @Prop({ default: 'other' })
  requestSource!: string;

  isSaving = false;
  showPopConfirm = false;
  tempValue = '';

  get store() {
    switch (this.eventType) {
      case 'DUAL':
        return EventStore;
      case 'PLAYER':
        return PlayerStore;
      case 'ANTEPOST':
        return AntepostStore;
      default:
        return EventStore;
    }
  }

  get availableStates() {
    const excluded = this.excludeStates || [];
    return this.store.availableStates.filter((state: any) => !excluded.includes(state.value));
  }

  async handleOk() {
    this.isSaving = true;
    this.showPopConfirm = false;
    const [err] = await to(this.store.changeBulkState(this.tempValue as any));
    this.isSaving = false;
    if (err) return;
    this.$emit('change', this.tempValue);
    this.tempValue = '';
  }

  handleButtonClick(value: any) {
    this.tempValue = value;
    this.showPopConfirm = true;
  }

  isHidden(state: any) {
    return this.requestSource === 'EVENT-MNG' && state.value === 'HIDDEN';
  }

  isPrematch() {
    return this.requestSource === 'PREMATCH';
  }

  onCancel() {
    this.showPopConfirm = false;
    this.tempValue = '';
  }

  get rollbackKey() {
    return this.eventType === 'ANTEPOST' ? 'SELECTED' : 'CREATED';
  }

  // For some states we want to have custom labels
  getLabel(state: EventState, defaultLabel: string) {
    const customLabel: Partial<Record<EventState, string>> = {
      [this.rollbackKey]: this.$t('common.rollback') as string,
      PUBLISHED: this.isPrematch()
        ? (this.$t('common.unhide') as string)
        : (this.$t('common.publish') as string),
      VALIDATED: this.$t('common.validate') as string,
      HIDDEN: this.$t('common.hide') as string,
    };

    return customLabel[state] || defaultLabel;
  }
}
