import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import omit from 'lodash/omit';
import Vue from 'vue';

import store from '@/store';
import { feedService } from './feed.service';
import { broadcastService } from './broadcast.service';
import {
  EventHandlers,
  OddMessageData,
  OddsMap,
  ResultsMap,
  ResultMessageData,
  EventSuspendedMessage,
  WarningOddsMap,
  LiveOddsWarningMessage,
  ProdOddMessage,
} from './feed.types';
import { eventService, EventStore, LiveEvent } from '@/modules/event';
import { PlatformStore } from '@/modules/platform';
import { SettlementStore } from '../settlement/settlement.store';
import { PlayerStore } from '../player';
import { OnPlayerMarketWs } from '@/modules/player/player.types';
import { DualEventSuspendedMessage, FeedLiveOdd, FeedOdd } from '.';
import { AntepostStore } from '../antepost';
import { marketService, MarketStore } from '../market';
import { WithdrawValidationStore } from '../withdrawValidation/withdrawValidation.store';
import { EventPublished } from '@/modules/event/eventPublished.entity';
import { SettlementEventWs } from '@/modules/settlement/addToSettlement.entity';
import { SlipStore } from '@/modules/slip/slip.store';
import { CommonSportPeriods } from '../settlement/settlement.types';
import { SettlementStopwatchStore } from '../settlementStopwatch/settlementStopwatch.store';
import { EventPublishedSettlement } from '../settlement/eventPublishedToSettlement.entity';

const getAccesor = (intKey: number | string) => eventService.formatEventId(intKey);

@Module({ dynamic: true, store, name: 'feed', namespaced: true })
class Feed extends VuexModule {
  odds: OddsMap = {};
  liveOdds: OddsMap = {};
  liveWarningOdds: WarningOddsMap = {};
  results: ResultsMap = {};

  playerOdds: OddsMap = {};

  @Mutation
  private onOddMessage(data: OddMessageData<FeedOdd>) {
    const event = EventStore.events[eventService.formatEventId(data.e_i_k)] as LiveEvent;
    data.odds.sort((a, b) => a.o - b.o);
    data.base_odds.sort((a, b) => a.o - b.o);
    const isLive = !!event?.stage;
    data.odds.forEach(odd => {
      if (odd.isdbt) {
        if (this.odds[odd.i_k]) return delete this.odds[odd.i_k];
        return;
      }
      // divided odd by 100 because odd value is now an integer
      Vue.set(this.odds, odd.i_k, { ...odd, p_v: (odd.p_v as number) / 100 });
      Vue.set(this.liveOdds, odd.i_k, { ...odd, p_v: (odd.p_v as number) / 100 });
      MarketStore.updateMarketOdds({ odd: marketService.mapOdd(odd), eventId: data.e_i_k });
    });
    if (data.base_odds.length) {
      if (isLive) {
        EventStore.onLiveBaseMarketChange(data);
        return;
      }
      EventStore.onBaseMarketChange(data);
    }
  }

  @Mutation
  private onProdOdd(data: ProdOddMessage) {
    const { o_i_k, ...rest } = data;
    // divided odd by 100 because odd value is now an integer
    rest.p_v /= 100;
    Vue.set(this.odds, o_i_k, { ...this.odds[o_i_k], ...rest });
  }

  @Mutation
  private onLiveOdds(data: OddMessageData<FeedLiveOdd>) {
    if (data.base_odds.length) {
      // we use this function for live odds because structure of data is the same
      EventStore.checkBaseMarketsAndUpdate(data);
    }
    data.odds.forEach(odd => {
      // divided odd by 100 because odd value is now an integer
      if (odd.isdbt) {
        if (this.odds[odd.i_k]) return delete this.odds[odd.i_k];
        return;
      }
      Vue.set(this.liveOdds, odd.i_k, { ...odd, p_v: odd.p_v / 100 });
      MarketStore.updateMarketOdds({ odd: marketService.mapLiveOdd(odd), eventId: data.e_i_k });
    });
  }

  @Mutation
  private onPlayerOdds(data: OnPlayerMarketWs) {
    data.odds.forEach(odd => {
      Vue.set(this.playerOdds, odd.i_k, { ...odd, p_v: (odd.p_v as number) / 100 });
    });
    if (data.base_odds.length) {
      PlayerStore.replaceBaseMarketsOnWs(data);
    }
    if (data.odds[0]?.b) {
      PlayerStore.createNewBaseMarket(data);
    }
  }

  @Mutation
  private onLiveWarningOdds(data: LiveOddsWarningMessage) {
    Vue.set(this.liveWarningOdds, data.i_k, data);
  }

  @Mutation
  private removeWarningOdd(intKey: number) {
    Vue.delete(this.liveWarningOdds, intKey);
  }

  @Mutation
  removeOddsFromStore(odds: string[]) {
    this.odds = omit(this.odds, odds) as OddsMap;
  }

  @Mutation
  removeResultFeedEvent(eventIntKey: number | string) {
    if (this.results[getAccesor(eventIntKey)]) {
      Vue.delete(this.results, getAccesor(eventIntKey));
    }
  }

  get eventCurrentResult() {
    return (eventIntKey: number) => {
      if (this.results[getAccesor(eventIntKey)]) {
        return this.results[getAccesor(eventIntKey)].r?.c_r;
      }
    };
  }

  get eventPeriodResult() {
    return (eventIntKey: number) => {
      if (this.results[getAccesor(eventIntKey)]) {
        return this.results[getAccesor(eventIntKey)]?.r?.d_r;
      }
    };
  }

  get eventPeriodKey() {
    return (eventIntKey: number) => {
      if (this.results[getAccesor(eventIntKey)]) {
        return Object.keys(this.results[getAccesor(eventIntKey)]?.r?.d_r)[0];
      }
    };
  }

  get eventFromFeed() {
    return (eventIntKey: number) => {
      return this.results[getAccesor(eventIntKey)];
    };
  }

  @Mutation
  private onResultsMessage(data: ResultMessageData) {
    const accessor = getAccesor(data.e_id);
    const currentResult = this.results[accessor];
    if (data.time === CommonSportPeriods.FULL_TIME) {
      SettlementStopwatchStore.resetResultMapEvent(data.e_id);
      SettlementStopwatchStore.removeResultFromLocalStorage(data.e_id);
      SettlementStore.updateEventWithoutRender({
        eventId: data.e_id,
        key: 'currentResultJson',
        newValue: data.r.c_r,
      });
    }
    if (!currentResult) {
      Vue.set(this.results, accessor, data);
    } else {
      Vue.set(this.results, accessor, {
        ...data,
        ...(data.time ? { time: data.time } : {}),
      });
    }
  }

  @Action
  init() {
    const handlers: EventHandlers = {
      odds: this.onOddMessage,
      live_odds: this.onLiveOdds,
      result: this.onResultsMessage,
      event_suspended_prematch: this.onEventSuspendPrematch,
      event_suspended_live: this.onEventSuspendLive,
      event_unsuspended_prematch: this.onEventUnSuspendPrematch,
      event_unsuspended_live: this.onEventUnSuspendLive,
      event_hidden: this.onEventHide,
      event_end: EventStore.onEventEnd,
      event_published: this.onEventPublish,
      live_on: PlatformStore.onLiveOn,
      live_off: PlatformStore.onLiveOff,
      prematch_on: PlatformStore.onPrematchOn,
      prematch_off: PlatformStore.onPrematchOff,
      prematch_removed: EventStore.removePrematchEvent,
      code_changed: EventStore.onEventCodeChange,
      prod_odd: this.onProdOdd,
      num_of_prod_odds_changed: EventStore.onNumOfProdOddChange,
      num_of_template_odds_changed: EventStore.onTemplateOddsChange, // CANT GET MESSAGE
      // market_template_changed: EventStore.onMarketTemplateChange, // removed, since num of template odds changed is now active
      update_egal_odds: EventStore.onEgalOddsUpdate,
      odds_warning: this.onLiveWarningOdds,
      settlement_not_started: SettlementStore.onEventNotStarted,
      event_player_hidden: this.onPlayerEventHide,
      event_player_code_changed: PlayerStore.onEventCodeChange,
      player_odds: this.onPlayerOdds,
      antepost_bet_update: AntepostStore.onAntepostBetUpdate,
      antepost_removed: AntepostStore.onAntepostRemoved,
      antepost_suspended: AntepostStore.onAntepostSuspended,
      antepost_unsuspended: AntepostStore.onAntepostUnsuspended,
      antepost_update: AntepostStore.onAntepostUpdate,
      antepost_market_update: AntepostStore.onAntepostMarketUpdate,
      // event_player_removed: check with kristina what to do,
      events_validated: EventStore.onEventsValidated,
      player_events_validated: PlayerStore.onEventsValidated,
      events_published: EventStore.onEventsPublished,
      market_event_unsuspended: MarketStore.onMarketUnsuspend,
      market_event_suspended: MarketStore.onMarketSuspend,
      event_start_date_updated: EventStore.onEventTimeChange,
      antepost_market_suspended: AntepostStore.onSuspendAntepostMarket,
      event_bet_stop_live: this.eventBetStopLive,
      event_bet_start_live: this.eventBetStartLive,
      antepost_market_unsuspended: AntepostStore.onUnsuspendAntepostMarket,
      event_player_tempates_changed: PlayerStore.playerTemplatesChanged,
      D_operator_note: EventStore.updateOperatorNote,
      D_note: EventStore.updateGameNote,
      D_settlement_note: SettlementStore.onUpdateSettlementNote,
      P_operator_note: PlayerStore.onUpdateOperatorNote,
      P_note: PlayerStore.onUpdateNote,
      A_operator_note: AntepostStore.onUpdateOperatorNote,
      million_tickets_count: SettlementStore.millionTicketsActive,
      sent_to_settlement: this.handleSentToSettlement,
      assigned: SettlementStore.assignedEvent,
      withdrawal_reservation: WithdrawValidationStore.onNewWithdrawValidation,
      withdrawal_reservation_updated: WithdrawValidationStore.updateWithdrawlReservation,
      checkbox_m1: SettlementStore.enableCheckboxM1,
      checkbox_1530: SettlementStore.enableCheckbox1530,
      new_slip: SlipStore.newSlipFromFeed,
      slip_status_update: SlipStore.slipStatusUpdate,
      event_tech_updated: EventStore.updateEventTech,
      has_goes_through: EventStore.hasGoesThrough,
      events_competitors_updated: EventStore.updateCompetitorWs,
      events_competition_updated: EventStore.updateCompetitionWs,
      events_settlement_updated: SettlementStore.updateSettlementEvent,
      event_live_providers_updated: this.onLiveProviderChange,
    };

    feedService.init(handlers);
    broadcastService.init(handlers);
  }

  @Action
  handleSentToSettlement(wsData: any) {
    EventStore.handleRemoveEventFromLive(wsData);
    const event = new SettlementEventWs(wsData);
    SettlementStore.updateOrAddEvent(event);
  }

  @Action
  onEventPublish(data: any) {
    if (data.s && data.s === 'settlement') {
      const event = new EventPublished(data);
      SettlementStore.updateOrAddEvent(event);
      return;
    }
    const settlementEvent = new EventPublishedSettlement(data);
    SettlementStore.addEventWhenPublished(settlementEvent);
    if (data.stg.toLowerCase() === 'prematch') {
      EventStore.onPrematchEventPublished(data);
    }
    if (data.stg.toLowerCase() === 'live') {
      EventStore.onLiveEventPublished(data);
    }
  }

  @Action
  eventBetStopLive(intKey: number) {
    EventStore.updateEvent({ intKey: intKey, params: { betStop: true } });
  }

  @Action
  eventBetStartLive(intKey: number) {
    EventStore.updateEvent({ intKey: intKey, params: { betStop: false } });
  }

  @Action
  onEventSuspendPrematch(data: DualEventSuspendedMessage) {
    EventStore.updateEvent({ intKey: data.e_i_k, params: { prematchSuspended: true } });
  }

  @Action
  onEventUnSuspendPrematch(data: DualEventSuspendedMessage) {
    EventStore.updateEvent({ intKey: data.e_i_k, params: { prematchSuspended: false } });
  }

  @Action
  onEventSuspendLive(data: DualEventSuspendedMessage) {
    EventStore.updateEvent({ intKey: data.e_i_k, params: { liveSuspended: true } });
  }

  @Action
  onEventUnSuspendLive(data: DualEventSuspendedMessage) {
    EventStore.updateEvent({ intKey: data.e_i_k, params: { liveSuspended: false } });
  }

  @Action
  onEventHide(data: DualEventSuspendedMessage) {
    if (data.s && data.s === 'settlement') {
      SettlementStore.editEvent({ eventId: data.e_i_k, key: 'state', newValue: 'HIDDEN' });
      return;
    }
    if (data.e_l_s) {
      EventStore.updateEvent({
        intKey: data.e_i_k,
        params: { state: 'HIDDEN', liveStatus: data.e_l_s as any },
      });
      EventStore.toggleFilterTriggerer();
      return;
    }
    EventStore.updateEvent({ intKey: data.e_i_k, params: { state: 'HIDDEN' } });
    EventStore.toggleFilterTriggerer();
  }

  @Action
  onPlayerEventHide(data: EventSuspendedMessage) {
    PlayerStore.updateEvent({ intKey: data.event_id, params: { state: 'HIDDEN' } });
  }

  @Action
  onWarningOdd(data: LiveOddsWarningMessage) {
    if (this.liveWarningOdds[data.i_k]) return;
    this.onLiveWarningOdds(data);
    setTimeout(() => this.removeWarningOdd(data.i_k), 60000 * 5);
  }

  @Action
  disconect() {
    feedService.disconect();
    broadcastService.disconect();
  }

  @Action
  onLiveProviderChange(wsData: any) {
    EventStore.updateEvent({
      intKey: wsData.i_k,
      params: {
        liveProvider: wsData.lp,
        liveProviders: wsData.lps,
      },
    });
    SettlementStore.updateEventWithoutRender({
      eventId: wsData.i_k,
      key: 'liveProviders',
      newValue: wsData.lps,
    });
    SettlementStore.editEvent({ eventId: wsData.i_k, key: 'liveProvider', newValue: wsData.lp });
  }
}

export const FeedStore = getModule(Feed);
