
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import Tape from '@/ui/components/Tape';
import { EventStore } from '@/modules/event/event.store';
import { PlayerStore } from '@/modules/player';

@Component({ components: { Tape } })
export default class SelectedEvents extends Vue {
  @Prop({ default: 'DUAL', type: String })
  eventType!: string;

  get store() {
    switch (this.eventType) {
      case 'DUAL':
        return EventStore;
      case 'PLAYER':
        return PlayerStore;
      default:
        return EventStore;
    }
  }

  get selectedEvents() {
    return this.store.selectedEvents;
  }
}
