
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import to from 'await-to-js';

import { EventStore, FactoryEvent, isPrematch } from '@/modules/event/';
import { isLive } from '@/modules/event';
import { isPlayer, PlayerEvent, PlayerStore } from '@/modules/player';

@Component({})
export default class PrematchSetup extends Vue {
  form!: WrappedFormUtils;
  liveForm!: WrappedFormUtils;
  isSaving = false;

  @Prop({ required: true })
  event!: FactoryEvent | PlayerEvent;

  @Prop({ required: false, default: 'PREMATCH' })
  eventType!: 'LIVE' | 'PREMATCH' | 'PLAYER';

  get label() {
    switch (this.eventType) {
      case 'LIVE':
        return this.$t('event.liveMaxBet');
      case 'PREMATCH':
        return this.$t('event.prematchMaxBet');
      case 'PLAYER':
        return this.$t('player.playerMaxBet');
      default:
        return this.$t('event.prematchMaxBet');
    }
  }

  get store() {
    switch (this.eventType) {
      case 'LIVE':
        return EventStore;
      case 'PREMATCH':
        return EventStore;
      case 'PLAYER':
        return PlayerStore;
      default:
        return EventStore;
    }
  }

  get prematchMaxBet() {
    if (isLive(this.event)) return null;
    return this.store.activeEventMaxBet;
  }

  get scMaxBet() {
    if (!isLive(this.event)) return null;
    return EventStore.activeLiveMaxAmountSc;
  }

  get webMaxBet() {
    if (!isLive(this.event)) return null;
    return EventStore.activeLiveMaxAmountWeb;
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
    this.liveForm = this.$form.createForm(this);
  }

  eventUpdated() {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('event.updated') as string,
    });
  }

  async savePrematchMaxAmount(reset: boolean) {
    let newValue = this.form.getFieldValue('newValue');
    if (reset) {
      newValue = null;
    }
    this.isSaving = true;
    if (!isPrematch(this.event)) {
      throw new Error('PREMATCH AMOUNT FOR PLAYERS IS NOT IMPLEMENTED');
    }
    const [err] = await to(
      EventStore.setPrematchMaxAmount({ newValue, eventId: this.event.intKey })
    );
    this.isSaving = false;
    if (err) return;
    this.eventUpdated();
    this.form = this.$form.createForm(this);
  }

  async savePlayerMaxAmount(reset: boolean) {
    if (!isPlayer(this.event)) return;
    let newValue = this.form.getFieldValue('newValue');
    if (reset) {
      newValue = null;
    }
    this.isSaving = true;
    const [err] = await to(
      PlayerStore.setPrematchMaxAmount({ newValue, intKey: this.event.intKey })
    );
    this.isSaving = false;
    if (err) return;
    this.eventUpdated();
    this.form = this.$form.createForm(this);
  }

  async saveScMaxAmount(reset: boolean) {
    if (!isLive(this.event)) return;
    let value = this.liveForm.getFieldValue('live_max_amount_sc');
    if (reset) {
      value = null;
    }
    this.isSaving = true;
    const [err] = await to(
      EventStore.setLiveMaxAmountSc({ value, eventIntKey: this.event.intKey })
    );
    this.isSaving = false;
    if (err) return;
    this.eventUpdated();
    this.liveForm = this.$form.createForm(this);
  }

  async saveWebMaxAmount(reset: boolean) {
    if (!isLive(this.event)) return;
    let value = this.liveForm.getFieldValue('live_max_amount_web');
    if (reset) {
      value = null;
    }
    this.isSaving = true;
    const [err] = await to(
      EventStore.setLiveMaxAmountWeb({ value, eventIntKey: this.event.intKey })
    );
    this.isSaving = false;
    if (err) return;
    this.eventUpdated();
    this.liveForm = this.$form.createForm(this);
  }

  onSubmit(reset = false) {
    if (this.eventType === 'LIVE') return;
    const req = this.eventType === 'PLAYER' ? this.savePlayerMaxAmount : this.savePrematchMaxAmount;
    req(reset);
  }
}
