
import Vue from 'vue';
import to from 'await-to-js';
import isNull from 'lodash/isNull';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';

import { Odd } from '@/ui/components/Odd/';
import { SportStore } from '@/modules/sport';
import { inputParamAlerts } from '@/lib/changeAlert';
import { EventStore } from '@/modules/event/event.store';
import { eventService } from '@/modules/event';
import { CommonStore, Modals } from '@/modules/common';
import { VALIDATOR_MESSAGES } from '@/modules/market/market.constants';
import { ChangeAlert } from '@/ui/components/ChangesAlert';

@Component({ components: { Odd, ChangeAlert } })
export default class InputParamsTennis extends Vue {
  form!: WrappedFormUtils;
  changeAlerts = inputParamAlerts;
  isValid = false;
  visibleAlert: { [key: string]: boolean } = {
    winner_favorite_operator_away: false,
    winner_favorite_operator_home: false,
    total_games_limit_operator: false,
    total_games_over_operator: false,
  };
  inputParamsChanges = '';
  confirmationString = '';
  alertValue = 0;
  alertLevel = 1;

  @Prop({ required: false, default: false })
  isModalView!: boolean;

  get event() {
    return EventStore.active;
  }

  get activeEventId() {
    return EventStore.activeId;
  }

  get sport() {
    if (!this.event) return null;
    return SportStore.getSportById(this.event.sportId);
  }

  get isGeneratingOdds() {
    return EventStore.isGeneratingOdds;
  }

  get eventInputParams(): any {
    return { ...EventStore.activeEventInputParams, ...EventStore.tempInputParams };
  }

  get isEditDisabled() {
    return EventStore.isEventEditDisabled;
  }

  get tennisHandicaps() {
    return EventStore.tennisHandicaps;
  }

  get validatorMessages() {
    return (sportCode: string, alertName: string) => {
      return VALIDATOR_MESSAGES[sportCode][alertName];
    };
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  getOddFormat(probability: any) {
    if (probability) return (1 / probability).toFixed(3);
    return probability;
  }

  getParameter(
    paramType: 'provider' | 'operator',
    paramConfig: { key: string; isReciprocal: boolean }
  ) {
    if (!this.eventInputParams) return '';
    const value = this.eventInputParams[`${paramConfig.key}_${paramType}`];
    if (isNull(value)) return paramType === 'provider' ? this.$t('common.notAvailable') : null;
    return paramConfig.isReciprocal ? this.getOddFormat(value) : value;
  }

  async onGetTennisHandicaps() {
    const formData = this.form.getFieldsValue();
    const payload = eventService.parseTennisHandicapsPayload(formData);
    const [err] = await to(EventStore.getTennisHandicaps(payload));
    if (err) return;
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('inputParams.successGenerateHandicaps') as string,
    });
  }

  async generateTennisOdds() {
    const formData = this.form.getFieldsValue();
    EventStore.setInputParams(eventService.parseTennisTempInputParams(formData));
    const payload = eventService.parseTennisGenerateOddsPayload(formData);
    const [err, res] = await to(EventStore.generateTennisOdds(payload));
    if (err) return;
    EventStore.setGeneratedOdds(res);
    if (!this.isModalView) {
      CommonStore.toggleModal(Modals.COMPARE_ODDS);
    }
  }

  async validateParams(
    rule: any,
    value: any,
    _callback: any,
    isReciprocal: { [key: string]: boolean }
  ) {
    this.isValid = true;
    const paramKey = rule.field;
    const validators = this.changeAlerts(this.sport?.code, 'validators', paramKey);
    const alerts = this.changeAlerts(this.sport?.code, 'lvl1', paramKey);
    let feedValue: any = this.eventInputParams[paramKey];
    if (this.eventInputParams.favorite && paramKey === 'winner_favorite_operator_home') {
      feedValue = this.eventInputParams.winner_favorite_operator;
    }
    if (!this.eventInputParams.favorite && paramKey === 'winner_favorite_operator_away') {
      feedValue = this.eventInputParams.winner_favorite_operator;
    }
    if (isReciprocal) {
      feedValue = Number((1 / feedValue).toFixed(3));
    }
    if (validators) {
      validators.forEach((validator: any) => {
        if (validator.func(value)) {
          this.isValid = false;
          this.inputParamsChanges = this.validatorMessages(this.sport?.code, validator.funcName);
          this.visibleAlert[paramKey] = true;
        }
      });
    }
    if (!this.isValid) {
      throw new Error('Invalid param value');
    }
    if (alerts) {
      alerts.forEach((alert: any) => {
        if (alert.func(value, feedValue)) {
          if (alert.funcName === 'totalOddAlertLvl1') {
            const oddRatio = alert.func(value, feedValue);
            this.alertValue = oddRatio;
            this.inputParamsChanges = `${this.validatorMessages(
              this.sport?.code,
              alert.funcName
            )} -- ${this.alertValue.toFixed(2)} %`;
            this.visibleAlert[paramKey] = true;
            return;
          }
          this.inputParamsChanges = this.validatorMessages(this.sport?.code, alert.funcName);
          this.visibleAlert[paramKey] = true;
        }
      });
    }
  }

  handleWinnerFavoriteAway() {
    this.isValid = false;
    this.form.setFieldsValue({ winner_favorite_operator_home: undefined });
  }

  handleWinnerFavoriteHome() {
    this.isValid = false;
    this.form.setFieldsValue({ winner_favorite_operator_away: undefined });
  }

  inputParamConfig = () => [
    {
      label: '1',
      key: 'winner_favorite_operator_home',
      uiKey: 'winner_favorite_home',
      size: 'small',
      disabled: this.isEditDisabled,
      change: this.handleWinnerFavoriteHome,
      isReciprocal: false,
      providerValue: this.eventInputParams?.favorite
        ? this.eventInputParams.winner_favorite_operator
        : this.$t('common.notAvailable'),
      vDecorator: [
        'winner_favorite_operator_home',
        {
          initialValue: this.eventInputParams?.favorite
            ? this.eventInputParams.winner_favorite_operator
            : this.$t('common.notAvailable'),
          rules: [
            { required: false },
            {
              validator: (rule: any, value: any, callback: any) =>
                this.validateParams(rule, value, callback, { isReciprocal: true }),
            },
          ],
          validateTrigger: '',
        },
      ],
    },
    {
      label: '2',
      key: 'winner_favorite_operator_away',
      uiKey: 'winner_favorite_operator_away',
      size: 'small',
      disabled: this.isEditDisabled,
      change: this.handleWinnerFavoriteAway,
      isReciprocal: false,
      providerValue: this.eventInputParams?.favorite
        ? this.$t('common.notAvailable')
        : this.eventInputParams.winner_favorite_operator,
      vDecorator: [
        'winner_favorite_operator_away',
        {
          initialValue: this.eventInputParams?.favorite
            ? this.$t('common.notAvailable')
            : this.eventInputParams.winner_favorite_operator,
          rules: [
            { required: false },
            {
              validator: (rule: any, value: any, callback: any) =>
                this.validateParams(rule, value, callback, { isReciprocal: true }),
            },
          ],
          validateTrigger: '',
        },
      ],
    },
    {
      label: 'GR',
      key: 'total_games_limit_operator',
      size: 'small',
      disabled: this.isEditDisabled,
      change: this.disableFormSubmission,
      isReciprocal: false,
      vDecorator: [
        'total_games_limit_operator',
        {
          initialValue: (() =>
            this.eventInputParams.total_games_limit_operator || this.$t('common.notAvailable'))(),
          rules: [
            { required: false },
            {
              validator: (rule: any, value: any, callback: any) =>
                this.validateParams(rule, value, callback, { isReciprocal: false }),
            },
          ],
          validateTrigger: '',
        },
      ],
    },
    {
      label: '+',
      key: 'total_games_over_operator',
      size: 'small',
      disabled: this.isEditDisabled,
      change: this.disableFormSubmission,
      isReciprocal: true,

      vDecorator: [
        'total_games_over_operator',
        {
          initialValue: this.getParameter('operator', {
            key: 'total_games_over',
            isReciprocal: true,
          }),
          rules: [
            { required: false },
            {
              validator: (rule: any, value: any, callback: any) =>
                this.validateParams(rule, value, callback, { isReciprocal: true }),
            },
          ],
          validateTrigger: '',
        },
      ],
    },
  ];

  onCancel(inputValue: any) {
    let prevInputValue = this.eventInputParams[inputValue.key];
    if (this.eventInputParams.favorite && inputValue.key === 'winner_favorite_operator_home') {
      prevInputValue = this.eventInputParams.winner_favorite_operator;
    }
    if (!this.eventInputParams.favorite && inputValue.key === 'winner_favorite_operator_away') {
      prevInputValue = this.eventInputParams.winner_favorite_operator;
    }
    const isReciprocal = inputValue.isReciprocal;
    if (isReciprocal) {
      prevInputValue = Number((1 / prevInputValue).toFixed(3));
    }
    this.form.setFieldsValue({ [`${inputValue.key}`]: prevInputValue });
    this.visibleAlert[`${inputValue.key}`] = false;
  }

  onOk(inputValue: any) {
    this.visibleAlert[`${inputValue.key}`] = false;
  }

  onEnter(inputValue: any) {
    this.form.validateFields([`${inputValue.key}`]);
    const inputsWithError = this.checkOtherFieldsValidity();
    if (inputsWithError.length) {
      inputsWithError.forEach((inputFieldValue: string) => {
        this.form.validateFields([`${inputFieldValue}`]);
      });
    }
  }

  disableFormSubmission() {
    this.isValid = false;
  }

  checkValidityOnBlur(inputValue: any) {
    this.form.validateFields([`${inputValue.key}`]);

    const inputsWithError = this.checkOtherFieldsValidity();
    if (inputsWithError.length) {
      inputsWithError.forEach((inputFieldValue: string) => {
        this.form.validateFields([`${inputFieldValue}`]);
      });
    }
  }

  checkOtherFieldsValidity() {
    const inputParameters: any = this.form.getFieldsError();
    return Object.keys(inputParameters).filter(key => !!inputParameters[key]);
  }
}
