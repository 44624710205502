import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import to from 'await-to-js';

import store from '@/store';
import { ApprovalTemplate, TemplateType } from './approval.types';
import { approvalService } from './approval.service';
import { CommonStore } from '../common';

@Module({ dynamic: true, store, name: 'approval', namespaced: true })
class Approval extends VuexModule {
  templates: ApprovalTemplate[] = [];

  get sportPrematchTemplates() {
    return (sportId: number) =>
      this.templates.filter(
        (t: ApprovalTemplate) => t.sport_id === sportId && t.template_type === 'PREMATCH'
      );
  }

  get sportLiveTemplates() {
    return (sportId: number) =>
      this.templates.filter(
        (t: ApprovalTemplate) => t.sport_id === sportId && t.template_type === 'LIVE'
      );
  }

  @Mutation
  setTemplates(templates?: ApprovalTemplate[]) {
    if (!templates) return;
    this.templates = templates;
  }

  @Action
  async getTemplates(selectedSports?: number[]) {
    CommonStore.setIsFetching(true);
    const [err, res] = await to(approvalService.getApprovalTemplates(selectedSports));
    CommonStore.setIsFetching(false);
    if (!err) {
      this.setTemplates(res);
    }
  }

  @Action
  async saveTemplate(template: ApprovalTemplate) {
    if (template.template_type === TemplateType.prematch) {
      const validPrematchTemplate = approvalService.buildPrematchTemplatePayload(template);
      return approvalService.saveTemplate(validPrematchTemplate);
    }
    return approvalService.saveTemplate(template);
  }

  @Action
  async deleteTemplate(templateId: number) {
    return approvalService.deleteTemplate(templateId);
  }
}

export const ApprovalStore = getModule(Approval);
