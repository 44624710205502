
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

import { OddValue } from '@/ui/components/OddValue';
import { Odd as IOdd } from '@/modules/market/market.types';
import { MarketStore } from '@/modules/market';
import { FeedStore } from '@/modules/feed';

@Component({
  name: 'DisplayOdd',
  components: { OddValue },
})
export default class DisplayOdd extends Vue {
  @Prop({ required: true })
  odd!: IOdd;

  @Prop({ required: true })
  eventId!: string;

  @Prop({ required: false, default: true })
  hasCells!: boolean;

  @Prop({ default: false })
  changedFlag!: boolean;

  @Prop({ default: false })
  isEditable!: boolean;

  @Prop({ default: false })
  isDisabled!: boolean;

  @Prop({ default: false })
  suspendedFlag!: boolean;

  tempValue = null;

  get oddFromStore() {
    return MarketStore.odds?.[this.odd.int_key];
  }

  get oddsFromFeed() {
    return FeedStore.odds;
  }

  get isManualChanged() {
    return (odd: IOdd) => {
      if (!MarketStore.odds) return false;
      const oddFromMarket = MarketStore.odds[odd.int_key];
      const oddFromFeed = this.oddsFromFeed[odd.int_key];
      if (oddFromFeed && oddFromFeed.m_c) return oddFromFeed.m_c;
      if (!oddFromMarket) return false;
      return oddFromMarket.manual_changed;
    };
  }

  get value() {
    if (this.tempValue !== null) {
      return this.tempValue;
    }

    if (this.oddFromStore) {
      return this.oddFromStore.prematch_prod_odd;
    }
    return (this.odd as any).odd_value || this.odd.prematch_prod_odd || (this.odd as any).odd; // Odds generated according to input params
  }

  onOddChange(value: any) {
    this.tempValue = value;
    MarketStore.setTempOddValue({ id: this.odd.int_key, value });
  }
}
