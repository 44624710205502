
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { marketService, MarketStore } from '@/modules/market';
import { objToArr } from '@/lib/objToArr';
import { DisplayOdd } from '../DisplayOdd';
import { isNull } from 'lodash';

import to from 'await-to-js';
import { outcomeRepo } from '@/modules/outcome/outcome.repo';
import { EventStore } from '@/modules/event';

@Component({
  components: { DisplayOdd },
})
export default class GoesThroughMarket extends Vue {
  @Prop({ required: true })
  selectedEventId!: any;

  isLoading = false;

  get goesThroughMarket() {
    return MarketStore.goesThroughMarket;
  }

  get tempValues() {
    return MarketStore.tempOddValues;
  }

  get eventMarkets() {
    return objToArr(this.goesThroughMarket);
  }

  get activeTech() {
    return EventStore.active?.tech === 'A' ? true : false;
  }

  get canSave() {
    const hasLength = Object.keys(this.tempValues).length === 2;
    const isNotNull = !Object.values(this.tempValues).some(isNull);

    return hasLength && isNotNull && !this.activeTech;
  }

  async onSave() {
    this.isLoading = true;
    await to(
      outcomeRepo.createOdds(
        this.selectedEventId,
        marketService.formatGoesThroughOdds(this.tempValues)
      )
    );
    this.isLoading = false;
    this.$emit('save');
    MarketStore.clearTempValues();
  }
}
