import snakeCase from 'lodash/snakeCase';

const getImage = (name: string, prefix: 'sports' | 'locations') => {
  let image;
  try {
    const snakeName = snakeCase(name);
    image = require(`@/assets/${prefix}/${snakeName}.svg`);
  } catch (e) {
    image = require(`@/assets/${prefix}/default.svg`);
  }
  return image;
};

const getSportImage = (sportName: string) => {
  return getImage(sportName, 'sports');
};

const getLocationImage = (locationName: string) => {
  return getImage(locationName, 'locations');
};

export { getSportImage, getLocationImage };
