import { api } from '@/lib/api';
import {
  AntepostMarket,
  AntepostMarketEditPayload,
  AntepostMarketsParams,
  AntepostCreatePayload,
  NewAntepostBetPaylad,
  AntepostBet,
  AntepostEditPayload,
  NewOperatorNotePayload,
  AntepostBetState,
  AntepostState,
  MoveToEventMngPayload,
} from './antepost.types';

const getAntepostMarkets = (payload?: AntepostMarketsParams) => {
  // Happy Cri-Cri day to our lovely and only Cri-Cri !!!
  return api.post('odds/antepost-markets/list/', payload) as Promise<AntepostMarket[]>;
};

const saveMarket = (payload: AntepostMarketEditPayload) => {
  return api.post('odds/antepost-markets/', payload);
};

const updateMarket = (id: number, payload: Partial<AntepostMarketEditPayload>) => {
  return api.put(`/odds/antepost-markets/${id}/`, payload);
};

const deleteMarket = (marketId: number) => {
  return api.delete(`/odds/antepost-markets/${marketId}/`);
};

const getAntepostsForMarket = (marketId: number) => {
  return api.post('/odds/anteposts/list/', { markets: marketId });
};

const createAntepost = (payload: AntepostCreatePayload) => {
  return api.post('/odds/anteposts/', payload);
};

const updateAntepost = (id: number, payload: Partial<AntepostEditPayload>) => {
  return api.put(`/odds/anteposts/${id}/`, payload);
};

const deleteAntepost = (id: number) => {
  return api.delete(`/odds/anteposts/${id}/`);
};

const addOperatorNote = (payload: NewOperatorNotePayload) => {
  return api.post('odds/anteposts/operator-note/', payload);
};

const createAntepostBet = (payload: NewAntepostBetPaylad) => {
  return api.post('odds/antepost-bets/', payload);
};

const antepostEventMng = (id: number) => {
  return api.put(`/odds/anteposts/${id}/event-mng/`);
};

const moveAntepostToEventMng = (payload: MoveToEventMngPayload) => {
  return api.put(`/odds/anteposts/${payload.id}/event-mng-confirm/`, {
    location_name: payload.location_name,
    sport_name: payload.sport_name,
  });
};

const getBetNextCode = (antepostId: number) => {
  return api.get(`odds/anteposts/${antepostId}/bets-next-code/`, {
    cache: {
      maxAge: 0,
    },
  });
};

const getAntepostBets = (anteposts: number[]) => {
  return api.post('odds/antepost-bets/list/', { anteposts });
};

const updateAntepostBet = (id: number, payload: Partial<AntepostBet>) => {
  return api.put(`odds/antepost-bets/${id}/`, payload);
};

const deleteBet = (id: number) => {
  return api.delete(`/odds/antepost-bets/${id}/`);
};

const suspendAnteposts = (ids: number[], nextValue: boolean) => {
  return api.put('/odds/anteposts/suspend/', { events: ids, is_suspended: nextValue });
};

const changeState = (anteposts: string[], nextState: AntepostState) => {
  return api.put('/odds/anteposts/change-state/', {
    antepost_ids: anteposts,
    state: nextState,
  });
};

const changeBetState = (bets: number[], nextState: AntepostBetState) => {
  return api.put('/odds/antepost-bets/change-state/', {
    antepost_bet_ids: bets,
    state: nextState,
  });
};

const suspendAntepostBets = (id: number, value: boolean) => {
  return api.put('odds/antepost-bets/suspend/', {
    events: [id],
    is_suspended: value,
  });
};

const removeAntepostFromOffer = (id: number) => {
  return api.put('odds/anteposts/permanently-remove/', {
    antepost_ids: [id],
  });
};

const suspendAntepostMarket = (id: number, value: boolean) => {
  return api.put(`odds/antepost-markets/${id}/suspend/`, {
    is_suspended: value,
  });
};

const setAntepostMaxBet = ({ newValue, intKey }: { newValue: number; intKey: number }) => {
  return api.put(`/odds/anteposts/${intKey}/max-amount/`, {
    max_amount: newValue || null,
  });
};

export const antepostRepo = {
  getAntepostMarkets,
  saveMarket,
  deleteMarket,
  updateMarket,
  getAntepostsForMarket,
  createAntepost,
  updateAntepost,
  deleteAntepost,
  addOperatorNote,
  createAntepostBet,
  getBetNextCode,
  getAntepostBets,
  updateAntepostBet,
  deleteBet,
  antepostEventMng,
  moveAntepostToEventMng,
  suspendAnteposts,
  changeState,
  suspendAntepostBets,
  changeBetState,
  removeAntepostFromOffer,
  suspendAntepostMarket,
  setAntepostMaxBet,
};
