
import Vue from 'vue';
import to from 'await-to-js';
import isNull from 'lodash/isNull';
import startCase from 'lodash/startCase';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';

import { Modals } from '@/modules/common';
import { Odd } from '@/ui/components/Odd/';
import { CommonStore } from '@/modules/common';

import { PlayerStore } from '@/modules/player';
import { BASKETBALL_INPUT_PARAMS } from '@/modules/player/player.constants';

@Component({ components: { Odd } })
export default class InputParamsMarket extends Vue {
  form!: WrappedFormUtils;
  inputParams = BASKETBALL_INPUT_PARAMS;

  @Prop({ required: false, default: false })
  isModalView!: boolean;

  get event() {
    return PlayerStore.active;
  }

  get activeEventId() {
    return PlayerStore.activeId;
  }

  get isGeneratingOdds() {
    return PlayerStore.isGeneratingOdds;
  }

  get eventInputParams(): any {
    return { ...this.event?.inputParameters, ...PlayerStore.tempInputParams };
  }

  get isEditDisabled() {
    return PlayerStore.isEventEditDisabled;
  }

  getLabel(key: string) {
    return startCase(key);
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  onError(description = this.$t('genericError.description')) {
    this.$notification.warning({
      message: this.$t('common.error') as string,
      description: description as string,
    });
  }

  getOddFormat(probability: any) {
    if (probability) return (1 / probability).toFixed(3);
    return probability;
  }

  getParameter(paramConfig: { key: string; isReciprocal: boolean }) {
    if (this.eventInputParams === undefined) return '';
    const value = this.eventInputParams[paramConfig.key];
    if (value === undefined) return '';
    if (isNull(value)) return this.$t('common.notAvailable');
    return paramConfig.isReciprocal ? this.getOddFormat(value) : Number(value);
  }

  async onSubmit() {
    const [formError] = await to(this.form.validateFields() as any);
    if (formError) return this.onError(this.$t('common.allFieldsRequired'));
    const formData = this.form.getFieldsValue() as any;
    const [err, response] = await to(PlayerStore.generateOddsByInputParams(formData));
    if (err) return;
    PlayerStore.setGeneratedOdds(response);
    if (!this.isModalView) {
      CommonStore.toggleModal(Modals.COMPARE_ODDS);
    }
  }
}
